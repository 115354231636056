<template>
  <div class="guideImage">
    <img :src="image" alt="" />
  </div>
</template>
<script>
import * as api from "../lib/guideImage.js";

export default {
  name: "guideImage",
  components: {},
  data() {
    return {
      image: "",
    };
  },
  mounted() {
    this.getPaySuccessPosterUrl();
  },
  methods: {
    getPaySuccessPosterUrl() {
      let data = {
        // productId: window.location.href.split("?")[1].split("=")[1],
        orderId: window.location.href.split("?")[1].split("=")[1],
      };
      api.paySuccessPosterUrl(data).then((res) => {
        if (res.result === 200) {
          this.image = res.data.paySuccessPosterUrl+'?x-oss-process=image/resize,w_700/format,jpg/quality,q_85';
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.guideImage {
  text-align: left;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>