<template>
  <div class="useInfo">
    <van-cell-group>
      <van-field label="用户编号" :value="userNo" readonly />
      <van-field label="绑定码" :value="identitySign" readonly>
        <template #button>
          <van-button size="small" type="default" v-copy="identitySign"
            >复制</van-button
          >
        </template>
      </van-field>
    </van-cell-group>
  </div>
</template>

<script>
import * as api from "../lib/useInfo.js";
import { Notify } from "vant";

export default {
  name: "useInfo",
  components: {},
  data() {
    return {
      userNo: "",
      identitySign: "",
    };
  },
  mounted() {
    document.title = "个人信息";
    this.docReady();
    this.getUserInfo();
  },
  methods: {
    docReady() {
      console.log("111");
      var ua = navigator.userAgent.toLowerCase();
      var isWXWork = ua.match(/wxwork/i) == "wxwork";
      var isWeixin =
        !isWXWork && ua.match(/micromessenger/i) == "micromessenger";
      var isMobile = false;
      var isDesktop = false;
      if (
        navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|IEMobile)/i
        )
      ) {
        isMobile = true;
      } else {
        isDesktop = true;
      }

      if (isWeixin) {
        console.log("222");
        wx.config({
          debug: true, // 调试时可开启
          appId: "wx810042ecbca2c6e8", // <!-- replace -->
          timestamp: 0, // 必填，填任意数字即可
          nonceStr: "nonceStr", // 必填，填任意非空字符串即可
          signature: "signature", // 必填，填任意非空字符串即可
          jsApiList: ["chooseImage"], // 必填，随意一个接口即可
          openTagList: ["wx-open-launch-weapp"], // 填入打开小程序的开放标签名
        });
      } else if (isDesktop) {
        // 在 pc 上则给提示引导到手机端打开
      } else {
        console.log("333");
        var c = new cloud.Cloud({
          // 必填，表示是未登录模式
          identityless: true,
          // 资源方 AppID
          resourceAppid: "wx810042ecbca2c6e8", // <!-- replace -->
          // 资源方环境 ID
          resourceEnv: "a-9spu8", // <!-- replace -->
        });
        // await c.init();
        window.c = c;

        // try {
        //   await openWeapp(() => {});
        // } catch (e) {
        //   throw e;
        // }
      }
    },
    getUserInfo() {
      api
        .getUserInfo()
        .then((res) => {
          if (res.result === 200) {
            this.userNo = res.data.userNo;
            this.identitySign = res.data.identitySign;
          }
          // else {
          //   Notify(res.message);
          // }
        })
        .catch((res) => {
          Notify(res.message);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
</style>