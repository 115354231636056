<template>
  <div class="adEntrance">
    <div class="head">
      <div class="headNum"><span>¥</span> {{ balance }}</div>
      <div class="vTag">
        <van-tag type="danger" plain round @click="goAlimony">去提现></van-tag>
      </div>
      <div class="btn">
        <van-button type="danger" round block @click="videoClick"
          >领现金</van-button
        >
      </div>
      <div class="strategy">
        <van-tag color="#4F4F4F" size="medium" plain round @click="strategy"
          >赚钱攻略</van-tag
        >
      </div>
    </div>
    <div class="content-list-cc">
      <van-cell title="观看进度" :value="watch" />
    </div>
    <van-dialog v-model="show" title="赚钱攻略">
      <div>1、每日签到奖励：用户每日进行签到操作可随机获得现金奖励。</div>
      <div>
        2、现金奖励提取：现金发放至用户的生活费账户，可至生活费账户提现至微信零钱。
        https://a-9spu8-1303814407.tcloudbaseapp.com/index.html
      </div>
    </van-dialog>
  </div>
</template>

<script>
import * as api from "../lib/alimony.js";
import md5 from "js-md5";
import { Notify } from "vant";

export default {
  name: "adEntrance",
  data() {
    return {
      show: false,
      balance: "0.00",
      btnDisabled: false,
      money: "",
      content: "",
      content1: "",
      conClick: "",
      userObj: {},
      ymd: "",
      watch: "",
    };
  },
  mounted() {
    document.title = "领现金";
    var date = new Date();
    let Y = date.getFullYear();
    let M =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1;
    let D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();

    this.ymd = Y + "" + M + D;
    // window.SjmJSAdSdk = this.SjmJSAdSdk;
    window.sjmJSAdSdkCallBack = this.sjmJSAdSdkCallBack;
    this.userObj = JSON.parse(SjmJSSdk.getUser());
    this.query();
  },
  methods: {
    query() {
      let data = {
        userNo: this.userObj.userID,
        timestamp: Date.parse(new Date()),
        sign: md5(
          this.userObj.userID +
            Date.parse(new Date()) +
            this.ymd +
            "HUI_YU_LOGIN_IDENTIFICATION"
        ),
      };
      api
        .userLogin(data)
        .then((res) => {
          if (res.result === 200) {
            this.balance = res.data.amount;
            this.getUserInfo();
          }
        })
        .catch((res) => {
          // if (res.result !== 200) {
          Notify(res.message);
          // }
        });
    },
    getUserInfo() {
      api
        .getUserInfo()
        .then((res) => {
          if (res.result === 200) {
            this.watch = res.data.watchCount + "/" + res.data.taskCount;
          }
        })
        .catch((res) => {
          Notify(res.message);
        });
    },
    goAlimony() {
      this.$router.push("/alimony");
    },
    strategy() {
      this.show = true;
    },
    videoClick() {
      //   this.conClick = "加载激励视频";
      SjmJSAdSdk.loadAd("sjmad_test001", "rewardVideo");
    },
    sjmJSAdSdkCallBack(type, msg) {
      let data = {
        type: type,
        msg: msg,
      };
      api
        .postData(data)
        .then((res) => {
          if (res.reslut === 200) {
          }
        })
        .catch((res) => {
          // if (res.result !== 200) {
          Notify(res.message);
          // }
        });
      this.content1 = "回调" + msg;
      switch (type) {
        case "onSjmAdLoaded":
          this.content = "onSjmAdLoaded";
          SjmJSAdSdk.showAd();
          break;
        case "onSjmAdShow":
          this.content = "onSjmAdShow";
          break;
        case "onSjmAdClick":
          this.content = "onSjmAdClick";
          break;
        case "onSjmAdClose":
          this.content = "onSjmAdClose";
          this.query();
          break;
        case "onSjmAdError":
          this.content = "onSjmAdError";
          break;
        case "onSjmAdVideoCached":
          this.content = "onSjmAdVideoCached";
          SjmJSAdSdk.showAd();
          break;
        case "onSjmAdShowError":
          this.content = "onSjmAdShowError";
          break;
        case "onSjmAdVideoComplete":
          this.content = "onSjmAdVideoComplete";
          break;
        case "onSjmAdExpose":
          this.content = "onSjmAdExpose";
          break;
        case "onSjmAdReward":
          this.content = "onSjmAdReward";
          break;
      }
    },
  },
};
</script>

<style lang='scss' scoped >
.adEntrance {
  position: relative;
  width: 100%;
  background-color: #f7f8fa;
  height: 100%;
  box-sizing: border-box;
  .head {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    height: 14rem;
    background-color: #fff;
    padding-left: 1rem;
    .headNum {
      position: absolute;
      top: 4rem;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 2.5rem;
      font-weight: 500;
      span {
        font-size: 1.5rem;
      }
    }
    .vTag {
      position: absolute;
      top: 3.5rem;
      left: 17rem;
    }
    .btn {
      position: absolute;
      padding: 0 1rem;
      width: 83%;
      top: 7rem;
    }
    .strategy {
      position: absolute;
      top: 12rem;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  /deep/.van-dialog__content {
    padding: 0 1rem !important;
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-align: left;
  }
  .content-list-cc {
    margin: 0.8rem 0;
    text-align: left;
  }
}
</style>



