import http from "./axios";

// 获取拼团相关信息
export const simpleGroupView = ({ skuId }) => {
  return http.get("/group/simpleGroupView", {
    skuId: skuId,
  });
};

// 获取指定拼团相关信息
export const specifiedGroupOrderView = ({ skuId, groupOrderId }) => {
  return http.get("/group/getSpecifiedGroupOrderView", {
    skuId: skuId,
    groupOrderId: groupOrderId,
  });
};

// 参团成功后获取参团信息
export const joinGroupSuccessInfo = ({ orderId }) => {
  return http.get("/group/joinGroupSuccessInfo", {
    orderId: orderId,
  });
};

// 获取拼团分享海报
export const getSharePosterInfo = ({ orderId }) => {
  return http.get("/group/getSharePosterInfo", {
    orderId: orderId,
  });
};
