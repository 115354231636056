<template>
  <div class="addressList">
    <van-address-list
      v-model="chosenAddressId"
      :list="list"
      default-tag-text="默认"
      @add="onAdd"
      @edit="onEdit"
      @select="onSelect"
    />
    <div class="address_btn" v-if="chosenAddressId && !modify">
      <div class="btn">
        <van-button type="primary" round block @click="useAddress"
          >确认地址</van-button
        >
        <!-- <van-button type="primary" round block @click="con">1</van-button> -->
      </div>
    </div>
  </div>
</template>
<script>
import * as api from "../lib/addressList.js";
import { queryWxPayResult } from "../lib/cartPage.js";
import { Notify } from "vant";
export default {
  name: "addressList",
  data() {
    return {
      backStatus: false,
      chosenAddressId: "",
      list: [],
      orderId: "",
      oldCartInfo: null,
      modify: false,
    };
  },
  mounted() {
    document.title = "地址列表";
    this.orderId = this.$route.query.orderId;
    this.modify = this.$route.query.modify;
    this.getAddressList();
    let oldCartInfo = this.$common.getCookie("info_data");
    if (oldCartInfo) {
      oldCartInfo = JSON.parse(oldCartInfo);
      this.oldCartInfo = this.$common.deepClone(oldCartInfo);
    }
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener("popstate", this.goBack, false);
    }
  },
  methods: {
    // 查询地址
    getAddressList() {
      api.addressList().then((res) => {
        if (res.result === 200) {
          let list = res.data;
          list.forEach((item) => {
            item.county = item.area;
            item.isDefault = item.defaultAddress;
            item.name = item.receiverName;
            item.tel = item.receiverPhone;
            if (item.defaultAddress) {
              this.chosenAddressId = item.id;
            }
            if (this.oldCartInfo) {
              this.chosenAddressId = this.oldCartInfo.id;
            }
          });
          this.list = res.data;
        }
      });
    },
    // 回退拦截跳转
    goBack() {
      this.useAddress();
    },
    // 选择地址
    onSelect(item, index) {
      this.chosenAddressId = item.id;
    },
    // 确认使用地址
    useAddress() {
      let data = {
        orderId: this.orderId,
        receiverAddressId: this.chosenAddressId,
      };
      api.updateAddress(data).then((res) => {
        if (res.result === 200) {
          this.backStatus = true;
          if (this.oldCartInfo) {
            this.list.forEach((item) => {
              if (item.id === this.chosenAddressId) {
                let infoData = {
                  addressDetail: item.addressDetail,
                  area: item.area,
                  city: item.city,
                  province: item.province,
                  receiverName: item.receiverName,
                  receiverPhone: item.receiverPhone,
                  id: item.id,
                  tel: this.oldCartInfo.tel,
                  extraInfoData: this.oldCartInfo.extraInfoData,
                  orderId: this.oldCartInfo.orderId,
                  productId: this.oldCartInfo.productId,
                  skuId: this.oldCartInfo.skuId,
                  entranceId: this.oldCartInfo.entranceId,
                };
                this.$common.setCookie("info_data", JSON.stringify(infoData));
                this.$router.push({
                  path: "/cartPage",
                  query: {
                    productId: infoData.productId,
                    skuId: infoData.skuId,
                    entranceId: infoData.entranceId,
                  },
                });
              }
            });
          }
          // queryWxPayResult({ orderId: this.orderId }).then(res => {
          //   if (res.result === 200) {
          //     if (res.data) {
          //       if (res.data.type === "redirect") {
          //         window.location = res.data.url;
          //       }
          //     }
          //   }
          // });
        }
      });
    },
    // 新增地址
    onAdd() {
      this.$router.push({ path: "/addressEdit" });
    },
    // 编辑地址
    onEdit(item, index) {
      this.$router.push({
        path: "/addressEdit",
        query: { list: this.list[index] },
      });
    },
  },
  destroyed() {
    window.removeEventListener("popstate", this.goBack, false);
  },
};
</script>
<style lang="scss" scoped>
.addressList {
  .address_btn {
    position: fixed;
    bottom: 3rem;
    width: 100%;
    height: 4.8rem;
    background: #fff;
    display: flex;
    align-items: center;
    .btn {
      padding: 0 1rem;
      width: 100%;
    }
  }
  .van-address-list {
    padding: 0.8rem 0.8rem 8.3rem;
    .van-address-item:not(:last-child) {
      margin-bottom: 0.8rem;
    }
    .van-address-item {
      padding: 0.8rem;
      border-radius: 0.5rem;
      .van-cell {
        line-height: 1.5rem;
        .van-address-item__value {
          padding-right: 2.75rem;
          /deep/.van-radio__label {
            margin-left: 0.8rem;
            line-height: 1.25rem;
            .van-address-item__name {
              margin-bottom: 0.5rem;
              font-size: 1rem;
              line-height: 1.4rem;
              .van-address-item__tag {
                margin-left: 0.5rem;
                line-height: 0.7rem;
              }
              .van-tag {
                font-size: 0.6rem;
                padding: 0.1rem 0.3rem;
                border-radius: 62rem;
              }
            }
            .van-address-item__address {
              font-size: 0.8rem;
              line-height: 1.2rem;
            }
          }
        }
        .van-address-item__edit {
          font-size: 1.25rem;
        }
      }
    }
  }
  .van-address-list__bottom {
    padding: 0 1rem;
    .van-address-list__add {
      height: 2.5rem;
      line-height: 2.5rem;
      margin: 0.3rem 0;
      .van-button__text {
        font-size: 0.95rem;
      }
    }
  }
}
</style>

