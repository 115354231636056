import Vue from 'vue'
import VueRouter from 'vue-router'
// import login from '../views/login.vue'
import home from '../views/home.vue'
import homePage from '../components/homePage.vue'
import activityIntroduce from '../components/activityIntroduce.vue'
import cartPage from '../components/cartPage.vue'
import addressList from '../components/addressList.vue'
import addressEdit from '../components/addressEdit.vue'
import paymentSuccess from '../components/paymentSuccess.vue'
import paymentError from '../components/paymentError.vue'
import policyPage from '../components/policyPage.vue'
import writingClass from '../components/writingClass.vue'
import xiongMaoYinYuWx from '../components/xiongMaoYinYuWx.vue'
import courseCollection from '../components/courseCollection.vue'
import successReceive from '../components/successReceive.vue'
import orderList from '../components/orderList.vue'
import orderDetails from '../components/orderDetails.vue'
import personalCenter from '../components/personalCenter.vue'
import introduceVideo from '../components/introduceVideo.vue'
import activitiesVideo from '../components/activitiesVideo.vue'
import activitiesVideo1 from '../components/activitiesVideo1.vue'
import activitiesVideo2 from '../components/activitiesVideo2.vue'
import activitiesVideo3 from '../components/activitiesVideo3.vue'
import sharingFriend from '../components/sharingFriend.vue'
import clockIn from '../components/clockIn.vue'
import groupClockIn from '../components/groupClockIn.vue'
import myClockIn from '../components/myClockIn.vue'
import myRed from '../components/myRed.vue'
import withDraw from '../components/withDraw.vue'
import info from '../components/info.vue'
import guideImage from '../components/guideImage.vue'
import groupWork from '../components/groupWork.vue'
import coupon from '../components/coupon.vue'
import qrCode from '../components/qrCode.vue'
import gamePage from '../components/gamePage.vue'
import alimony from '../components/alimony.vue'
import adEntrance from '../components/adEntrance.vue'
import useInfo from '../components/useInfo.vue'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/login',
  //   name: 'login',
  //   components: {
  //     first: login
  //   },
  // },
  {
    path: '/',
    name: 'home',
    redirect: '/homePage',
    components: {
      first: home
    },
    children: [
      {
        path: '/homePage',
        name: 'homePage',
        components: {
          second: homePage
        }
      },
      {
        path: '/activityIntroduce',
        name: 'activityIntroduce',
        components: {
          second: activityIntroduce
        }
      },
      {
        path: '/cartPage',
        name: 'cartPage',
        components: {
          second: cartPage
        }
      },
      {
        path: '/addressList',
        name: 'addressList',
        components: {
          second: addressList
        }
      },
      {
        path: '/addressEdit',
        name: 'addressEdit',
        components: {
          second: addressEdit
        }
      },
      {
        path: '/successReceive',
        name: 'successReceive',
        components: {
          second: successReceive
        }
      },
      {
        path: '/paymentSuccess',
        name: 'paymentSuccess',
        components: {
          second: paymentSuccess
        }
      },
      {
        path: '/paymentError',
        name: 'paymentError',
        components: {
          second: paymentError
        }
      },
      {
        path: '/policyPage',
        name: 'policyPage',
        components: {
          second: policyPage
        }
      },
      {
        path: '/writingClass',
        name: 'writingClass',
        components: {
          second: writingClass
        }
      },
      {
        path: '/xiongMaoYinYuWx',
        name: 'xiongMaoYinYuWx',
        components: {
          second: xiongMaoYinYuWx
        }
      },
      {
        path: '/courseCollection',
        name: 'courseCollection',
        components: {
          second: courseCollection
        }
      },
      {
        path: '/orderList',
        name: 'orderList',
        components: {
          second: orderList
        }
      },
      {
        path: '/orderDetails',
        name: 'orderDetails',
        components: {
          second: orderDetails
        }
      },
      {
        path: '/personalCenter',
        name: 'personalCenter',
        components: {
          second: personalCenter
        }
      },
      {
        path: '/introduceVideo',
        name: 'introduceVideo',
        components: {
          second: introduceVideo
        }
      },
      {
        path: '/activitiesVideo',
        name: 'activitiesVideo',
        components: {
          second: activitiesVideo
        }
      },
      {
        path: '/activitiesVideo1',
        name: 'activitiesVideo1',
        components: {
          second: activitiesVideo1
        }
      },
      {
        path: '/activitiesVideo2',
        name: 'activitiesVideo2',
        components: {
          second: activitiesVideo2
        }
      },
      {
        path: '/activitiesVideo3',
        name: 'activitiesVideo3',
        components: {
          second: activitiesVideo3
        }
      },
      {
        path: '/clockIn',
        name: 'clockIn',
        components: {
          second: clockIn
        }
      },
      {
        path: '/groupClockIn',
        name: 'groupClockIn',
        components: {
          second: groupClockIn
        }
      },
      {
        path: '/myClockIn',
        name: 'myClockIn',
        components: {
          second: myClockIn
        }
      },
      {
        path: '/myRed',
        name: 'myRed',
        components: {
          second: myRed
        }
      },
      {
        path: '/withDraw',
        name: 'withDraw',
        components: {
          second: withDraw
        }
      },
      {
        path: '/info',
        name: 'info',
        components: {
          second: info
        }
      },
      {
        path: '/useInfo',
        name: 'useInfo',
        components: {
          second: useInfo
        }
      },
      {
        path: '/guideImage',
        name: 'guideImage',
        components: {
          second: guideImage
        }
      },
      {
        path: '/sharingFriend',
        name: 'sharingFriend',
        components: {
          second: sharingFriend
        }
      },
      {
        path: '/groupWork',
        name: 'groupWork',
        components: {
          second: groupWork
        }
      },
      {
        path: '/coupon',
        name: 'coupon',
        components: {
          second: coupon
        }
      },
      {
        path: '/qrCode',
        name: 'qrCode',
        components: {
          second: qrCode
        }
      },
      {
        path: '/gamePage',
        name: 'gamePage',
        components: {
          second: gamePage
        }
      },
      {
        path: '/alimony',
        name: 'alimony',
        components: {
          second: alimony
        }
      },
      {
        path: '/adEntrance',
        name: 'adEntrance',
        components: {
          second: adEntrance
        }
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
