<template>
  <div class="bg">
    <img src="../assets/add_teacher_bg.png" alt="" class="bg_img" />
    <div>
      <div class="follow-person">
        <div class="content-wrapper">
          <img :src="portrait" alt="" class="avater" />
          <p class="title">{{name}}</p>
          <p class="teacher-desc">{{desc}}</p>
          <img :src="qrCodeUrl" alt="" class="qrcode" />
          <p class="teacher-wx-code">老师微信号：{{wxPhone}}</p>
          <van-button
            round
            type="danger"
            class="copy-wx-btn"
            id="wxPhone"
            :data-clipboard-text="wxPhone"
            @click.native="copyClick('#wxPhone', '老师微信号')"
          >
            复制老师微信号</van-button
          >
        </div>
        <footer>
          <div class="left icon-wrapper">
            <img src="../assets/foot_icon2.png" alt="" />
            <p class="title">专属课程辅导</p>
            <p class="desc">全程专业辅导督学</p>
          </div>
          <div class="right icon-wrapper">
            <img src="../assets/foot_icon.png" alt="" />
            <p class="title">社群学员互动</p>
            <p class="desc">互动讨论快乐学习</p>
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>
<script>
import * as api from "../lib/xiongMaoYinYuWx"
import Clipboard from "clipboard";
export default {
  name: "xiongMaoYinYuWx",
  data() {
    return {
      qrCodeUrl: "",
      portrait: "",
      wxPhone: "",
      name: "",
      desc: "",
    };
  },
  mounted() {
    this.getPandaTeacherInfo();
  },
  methods: {
    // 获取熊猫英语跟进老师信息
    getPandaTeacherInfo() {
      let href = window.location.href;
      href = href.split("?");
      let params = href[1];
      params = params.split("&");
      let obj = {};
      params.forEach(item => {
        item = item.split("=");
        obj[item[0]] = item[1]
      })
      // console.log(obj)
      api.pandaTeacherInfo({phone: obj.phone}).then(res => {
        if (res.result === 200) {
          this.qrCodeUrl = res.data.qrCodeUrl;
          this.portrait = res.data.portrait;
          this.wxPhone = res.data.wxPhone;
          this.name = res.data.name;
          this.desc = res.data.name;
        }
      })
    },
    // 点击复制
    copyClick(id, title) {
      let clipboard = new Clipboard(id);
      let _this = this;
      this.$nextTick(() => {
        clipboard.on("success", function (e) {
          _this.$toast({
            message: "复制成功",
          });
          clipboard.destroy();
        });
        clipboard.on("error", function (e) {
          console.log("不支持复制功能哦！");
          clipboard.destroy();
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
p {
  margin: 0;
  padding: 0;
}
.bg {
  font-weight: bold;
  position: relative;
  background: #ff9520;
  overflow: auto;
  height: 100vh;
  width: 100%;
  .bg_img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 0;
  }
  .follow-person {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-bottom: 1.375rem;
    z-index: 1;
    .content-wrapper {
      text-align: center;
      padding-bottom: 1.375rem;
      width: 21.5rem;
      background-color: #fff;
      margin: 0 auto;
      margin-top: 4.42rem;
      border-radius: 1.4rem;
      .avater {
        width: 5.25rem;
        min-height: 5.25rem;
        height: auto;
        border-radius: 50%;
        border: 0.375rem solid #ffdfbb;
        display: block;
        margin: 0 auto;
        margin-top: -3rem;
      }
      .title {
        font-size: 1.125rem;
        line-height: 1.125rem;
        color: #252629;
        margin: 0.9rem 0 1rem 0;
      }
      .teacher-desc {
        font-size: 1rem;
        font-weight: normal;
        color: #85878d;
        line-height: 1.375rem;
        width: 18.43rem;
        text-align: left;
        margin: 0 auto;
      }
      .qrcode {
        width: 7.875rem;
        height: auto;
        display: block;
        margin: 1.5rem auto 1rem auto;
      }
      .teacher-wx-code {
        font-size: 0.95rem;
        line-height: 1rem;
        color: #616265;
        font-weight: normal;
      }
      .copy-wx-btn {
        width: 15rem;
        height: 3rem;
        background: #ff9520;
        border-radius: 2rem;
        border: none;
        font-size: 1rem;
        color: #fff;
        font-weight: normal;
        display: block;
        margin: 0 auto;
        margin-top: 1.5rem;
        outline: none;
      }
    }
    footer {
      margin: 0 auto;
      margin-top: 1.25rem;
      height: 10.25rem;
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -moz-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      width: 21.43rem;
      background-color: #fff;
      border-radius: 1.25rem;
      .icon-wrapper {
        width: 50%;
        text-align: center;
        img {
          width: 3.5rem;
          height: auto;
        }
        .title {
          line-height: 1rem;
          font-size: 1rem;
          margin-top: 0.8rem;
        }
        .desc {
          margin-top: 0.8rem;
          font-size: 0.8rem;
          color: #85878d;
        }
      }
    }
  }
}
</style>