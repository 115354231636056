import http from "./axios";

// 获取课程显示视图----
export const couponView = ({ couponId, appId }) => {
  return http.get("/user/coupon/getCouponViewInfo", {
    couponId: couponId,
    appId: appId,
  });
};

// 解锁视频
export const distribute = ({ couponId }) => {
  return http.json_post("/user/coupon/distribute", {
    couponId: couponId,
  });
};
