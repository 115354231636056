<template>
  <div class="policyPage_style">
    <div v-for="(item,i) in policyContentList" :key="i">
      {{item}}
    </div>
  </div>
</template>
<script>
import * as api from "../lib/cartPage.js";
export default {
  name: "policyPage",
  data() {
    return {
      policyContentList: "",
      productId: "",
    }
  },
  mounted() {
    this.productId = this.$route.query.productId;
    this.getservicePolicy({ productId: this.productId });
  },
  methods: {
    // 获取条款信息
    getservicePolicy(data) {
      api.servicePolicy(data).then(res => {
        if (res.result === 200) {
          if (res.data.contentType === "TEXT") {
            let policyContentList = res.data.content.split("\n");
            this.policyContentList = policyContentList;
          }
        }
      });
    },
  }
}
</script>
<style lang="scss" scoped>
.policyPage_style {
  text-align: left;
  padding: 0.6rem;
  div:first-child {
    text-align: center;
    margin-bottom: 0.3rem;
  }
}
</style>

