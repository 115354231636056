import http from "./axios";

// 查询产品跳转链接
export const queryProductLinks = ({ userId, productId, navigationId }) => {
  return http.get("/manager/h5/productBindInfo/queryProductLinks", {
    userId: userId,
    productId: productId,
    navigationId: navigationId,
  });
};

// 根据导航查询商品
export const navigateProductRelationList = ({ navigateId, userId }) => {
  return http.get("/manager/h5/navigateProductRelation/list", {
    navigateId: navigateId,
    userId: userId,
  });
};

// 导航图片查询
export const naviagateUrlList = ({ navigateId }) => {
  return http.get("/manager/h5/naviagate/urlList", {
    navigateId: navigateId,
  });
};
