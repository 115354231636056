<template>
  <div class="cart_page_style">
    <!-- 拼团提示 -->
    <van-notice-bar
      v-if="supportGroup"
      left-icon="warning-o"
      wrapable
      :scrollable="false"
      style="text-align: left"
      :text="activitiesTitle"
    />
    <!-- 手机验证 -->
    <div class="cart_page_verification">
      <div v-if="telEncryptionShow">
        <van-field label="手机号" :value="telEncryptionValue" readonly>
          <template #button v-if="telEncryptionShow">
            <van-button
              size="small"
              type="primary"
              round
              @click="exchangeClick"
            >
              <div class="exchange_ccc">
                <van-icon name="exchange" />
                <div>切换</div>
              </div>
            </van-button>
          </template>
        </van-field>
      </div>
      <div v-if="!telEncryptionShow">
        <van-field
          v-model="tel"
          type="digit"
          label="手机号"
          maxlength="11"
          placeholder="请输入您的手机号"
        >
          <template #button v-if="!telEncryptionShow">
            <img
              :src="require('../assets/yijiami.png')"
              alt=""
              class="yi_img"
            />
          </template>
        </van-field>
        <!-- 使用本机号码 -->
        <div
          class="local_number"
          @click="useLocalNumber(telEncryptionValue_local)"
          v-if="localNumberShow"
        >
          <div class="custom-title">
            <span>{{ telEncryptionValue_local }}</span>
            <span class="help">(使用本机号码)</span>
          </div>
        </div>
        <div v-if="verificationCodeShow && phoneTitel" class="wb_text">
          <span>{{ phoneTitel }}</span>
        </div>
        <van-field
          v-model="sms"
          center
          clearable
          label="验证码"
          maxlength="4"
          placeholder="请输入短信验证码"
          v-if="!verificationCodeShow"
        >
          <template #button>
            <van-button
              size="small"
              type="primary"
              round
              :disabled="verificationDisabled"
              @click="getVerification"
            >
              <span>获取验证码</span>
              <span v-if="verificationLoading"
                >({{ verificationCountDown }}s)</span
              >
            </van-button>
          </template>
        </van-field>
      </div>
      <!-- 地址填写 -->
      <van-cell
        is-link
        class="address_m_c"
        @click="addressClick"
        v-if="telEncryptionShow && needDelivery"
      >
        <template #title>
          <div v-if="addressId">
            <div class="title-area">{{ province }}{{ city }}{{ area }}</div>
            <div class="title-address">{{ addressDetail }}</div>
            <div class="title-name">{{ receiverNameInfo }}</div>
          </div>
          <div v-else>
            <span>请填写实物礼包收货地址</span>
          </div>
        </template>
      </van-cell>
    </div>
    <!-- 附加信息 -->
    <div class="cart_page_verification">
      <div v-for="(item, index) in extraInfoData" :key="index">
        <van-field
          v-if="item.fieldType === 'INPUT'"
          v-model="item.value"
          :label="item.field"
          :placeholder="'请输入' + item.field"
          @blur="inputChange"
        />
        <van-field
          v-if="item.fieldType === 'OPTION'"
          readonly
          clickable
          name="picker"
          :value="item.value"
          :label="item.field"
          :placeholder="'请选择' + item.field"
          @click="item.showOption = true"
          class="select_item"
        />
        <van-popup v-model="item.showOption" position="bottom" round>
          <van-picker
            show-toolbar
            :columns="item.optionalList"
            @confirm="onOptionConfirm($event, index)"
            @cancel="item.showOption = false"
          />
        </van-popup>
      </div>
    </div>
    <!-- 支付详情 -->
    <div
      class="cart_page_verification"
      v-for="(item, index) in payDetailList"
      :key="index"
    >
      <img :src="item.imageUrl" alt="" style="width: 100%; display: block" />
    </div>
    <!-- 课程详情 -->
    <div class="cart_page_conent">
      <div class="cart_page_content_title">
        <p class="c_p_c_d">订单详情</p>
      </div>
      <div
        class="cart_page_content_c"
        :class="{ cart_page_content_c_f: isFold }"
      >
        <div v-for="(item, index) in kc_list" :key="index" class="c_K_c">
          <img :src="item.imageUrl" alt="" />
          <div class="text-disc">
            <p>{{ item.name }}</p>
          </div>
        </div>
      </div>
      <div v-if="kc_list.length > 2" class="more_btn" @click="foldClick">
        {{ foldBtn }}
      </div>
      <div class="order_price_content">
        <div class="price_text_area">
          <!-- <div class="price_text">
            <div>原价</div>
            <div style="text-decoration: line-through; color: #999999">
              ¥{{ showPrice }}
            </div>
          </div> -->
          <div class="price_text">
            <div>价格</div>
            <div style="color: #ff5e2c">¥{{ price }}</div>
          </div>
        </div>
        <!-- 优惠券单元格 -->
        <van-coupon-cell
          :coupons="coupons"
          :chosen-coupon="chosenCoupon"
          @click="showList = true"
          style="text-align: left; padding: 0.8rem 0"
        />
        <!-- <div class="price_tips_wrap">
          <div class="tips_left">
            <van-icon name="warning-o" class="small_text"/>支付完成后记得填写收货地址
          </div>
          <div class="tips_price">
            <span>￥9</span>
          </div>
        </div> -->
      </div>
    </div>
    <!-- 拼团队列 -->
    <div class="group_work" v-if="groupData.allowJoinGroup">
      <div class="item" style="width: 37%">
        <div class="item_cc">
          <!-- <img 
            src="" 
            alt="加载失败" 
            onerror="this.src='https://image.huiyu.org.cn/20210119113140VLQJ3dpk0S0M.jpg';this.οnerrοr=null"
            class="img_m"
          > -->
          <van-icon name="manager" class="img_m b_img" />
          <span class="text_cc">{{ groupData.starterName }}</span>
        </div>
      </div>
      <div class="item">
        <div>
          差拼<span style="color: red">{{ groupData.remainJoinCount }}人</span
          >团成功
        </div>
        <div>剩余{{ activityValidMinutes }}</div>
      </div>
      <van-button type="danger" class="btn" @click="payGroupClick('old')"
        >去拼团¥ {{ groupData.groupOrderSkuPrice / 100 }}</van-button
      >
    </div>
    <!-- 支付选择 -->
    <div class="order_modules_content">
      <!-- <header class="order_header">请选择支付方式</header> -->
      <van-radio-group v-model="radioPay">
        <van-cell-group>
          <van-cell clickable @click="radioPay = 'WX_PAY'">
            <template #title>
              <img src="../assets/wxzf.jpg" alt="" />
              <span class="c_title">微信支付</span>
            </template>
            <template #right-icon>
              <van-radio name="WX_PAY" />
            </template>
          </van-cell>
          <!-- <van-cell clickable @click="radio = '2'">
            <template #title>
              <img src="../assets/zfbzf.jpg" alt="">
              <span class="c_title">支付宝支付</span>
            </template>
            <template #right-icon>
              <van-radio name="2"/>
            </template>
          </van-cell>-->
        </van-cell-group>
      </van-radio-group>
    </div>
    <!-- 条款 -->
    <div class="policy_style" v-if="policyShow">
      <van-checkbox v-model="policyCheck">
        <span style="color: #848a90">本人同意并接受</span>
      </van-checkbox>
      <div>
        <a class="a_z_c" v-if="policyUrl" :href="policyUrl"
          >《{{ policyTitle }}》</a
        >
        <a class="a_z_c" v-if="policyContent" @click="policyContentClick"
          >《{{ policyTitle }}》</a
        >
      </div>
    </div>
    <!-- 确认支付 -->
    <div class="order_bottom_wrap" v-if="!supportGroup">
      <!-- 剩余时间 -->
      <div class="btn-count-content">
        <div>仅剩余<span>1</span>个名额，请在</div>
        <div class="btn-count-down">
          <van-count-down
            ref="countDown"
            millisecond
            :time="time"
            @finish="finish"
          >
            <template v-slot="timeData">
              <span class="block">{{ timeData.minutes }}</span>
              <span class="colon">:</span>
              <span class="block">{{ timeData.seconds }}</span>
              <span class="colon">:</span>
              <span class="block">{{
                parseInt(timeData.milliseconds / 100)
              }}</span>
            </template>
          </van-count-down>
        </div>
        <div>内支付</div>
      </div>
      <!-- 确定支付按钮 -->
      <div class="order_bottom_content">
        <div class="bottom_price">
          <span>￥{{ pricePayable }}</span>
          <span
            v-if="chosenCoupon !== -1"
            style="color: grey; text-decoration: line-through"
          >
            (原价:¥{{ price }})
          </span>
        </div>
        <!-- <van-button @click="test1">test1</van-button>
        <van-button @click="test2">test2</van-button> -->
        <van-button
          round
          type="danger"
          class="orderbottom_btn"
          @click="paymentClick(undefined, 'INDIVIDUAL_PAYMENT')"
          >确定支付</van-button
        >
      </div>
    </div>
    <!-- 拼团支付 -->
    <div class="order_bottom_wrap_group" v-if="supportGroup">
      <div class="order_group">
        <van-button
          type="default"
          class="btn"
          @click="paymentClick(undefined, 'INDIVIDUAL_PAYMENT')"
        >
          <span class="rp">￥{{ pricePayable }}</span>
          <span>单独购买</span>
        </van-button>
        <van-button
          type="danger"
          class="btn"
          @click="payGroupClick('new')"
          :disabled="!groupData.allowStartGroup"
        >
          <span class="rp">￥{{ groupData.activitySkuPrice / 100 }}</span>
          <span>发起拼团</span>
        </van-button>
      </div>
    </div>
    <!-- 挽回弹框 -->
    <van-dialog
      v-model="tipshow"
      title="确认要放弃支付吗"
      show-cancel-button
      confirmButtonText="继续支付"
      cancelButtonText="残忍拒绝"
      @confirm="tipColse"
      @cancel="tipBacks"
    >
      <div class="tip_div">
        <div class="tip_title">
          <span class="title_color">99%</span>
          以上的父母选择了继续支付
        </div>
        <div class="tip_calss">
          <div class="tip_calss_item">课程超划算(4387)</div>
          <div class="tip_calss_item">教学质量高(4173)</div>
          <div class="tip_calss_item">家长评价高(5894)</div>
          <div class="tip_calss_item">孩子很喜欢(3549)</div>
        </div>
      </div>
    </van-dialog>
    <!-- 报名提示弹窗 -->
    <overlay
      :url="overlayImageUrl"
      :visibleImg="imgShow"
      :visibleOverlay="overlayShow"
      @close="overlayClose"
      @imgClick="imgClick"
    ></overlay>
    <!-- 优惠券列表 -->
    <van-popup v-model="showList" round position="bottom" class="popup-cc">
      <van-coupon-list
        :show-exchange-bar="false"
        :coupons="coupons"
        :chosen-coupon="chosenCoupon"
        :disabled-coupons="disabledCoupons"
        @change="onChange"
      />
    </van-popup>
  </div>
</template>
<script>
import * as api from "../lib/cartPage.js";
import { addressList, updateAddress } from "../lib/addressList.js";
import { simpleGroupView, specifiedGroupOrderView } from "../lib/groupWork.js";
import { productSubView } from "../lib/homePage.js";
import overlay from "../pages/overlay.vue";
export default {
  name: "cartPage",
  props: ["v_WeiXin"],
  components: {
    overlay,
  },
  data() {
    return {
      appId: "",
      // -------
      validMinutes: 0,
      groupData: {
        groupOrderExpiredTime: "",
        groupOrderId: "",
        activitySkuPrice: "",
        groupOrderSkuPrice: "",
        starterName: "",
        groupOrderValidMinute: 0,
        allowStartGroup: false,
        allowJoinGroup: false,
        activityExpireTime: "",
        remainJoinCount: 0,
      },
      supportGroup: false,
      addressId: "",
      receiverName: "",
      area: "",
      city: "",
      province: "",
      addressDetail: "",
      receiverNameInfo: "",
      memberCouponId: null,
      usingCoupon: false,
      showList: false,
      chosenCoupon: -1,
      coupons: [],
      disabledCoupons: [],
      phoneTitel: "",
      payDetailList: [],
      overlayImageUrl: "",
      localNumberShow: false,
      telEncryptionValue: "",
      telEncryptionValue_local: "",
      telEncryptionShow: false,
      overlayShow: false,
      imgShow: false,
      productId: "",
      policyShow: false,
      verificationLoading: false,
      verificationCountDown: 0,
      extraInfoData: [],
      policyTitle: "",
      policyUrl: "",
      policyContent: "",
      policyCheck: true,
      time: 15 * 60 * 1000,
      tel: "",
      sms: "",
      verificationDisabled: true,
      verificationCodeShow: true,
      kc_list: [],
      price: 0,
      discountPrice: 0,
      showPrice: 0,
      foldBtn: "收起",
      isFold: false,
      radioPay: "WX_PAY",
      orderId: "",
      skuId: "",
      entranceId: "",
      lastRunTime: null,
      productName: "",
      stock: undefined,
      needDelivery: false,
      tipshow: false,
      paySuccess: false,
      code: "",
      unLogin: false,
      groupOrderId_loaction: "",
      activityValidSecond: 0,
      activityExpireTime: 0,
    };
  },
  computed: {
    pricePayable() {
      let price = Math.round(this.price * 100) - this.discountPrice;
      if (price) {
        price = price / 100;
      }
      return price;
    },
    activityValidMinutes() {
      let m = this.validMinutes;
      let res = this.$common.secondCompute(m);
      return res;
    },
    activitiesTitle() {
      let m = this.activityExpireTime;
      let t = this.activityValidSecond;
      let res_m = this.$common.secondCompute(m);
      let res_t = this.$common.secondComputeH(t);
      let title =
        "开团" +
        res_t +
        "小时内拼团成功，下单成功；若人数不足，系统自动退款关单。";
      if (m > 1) {
        title = title + "距离活动结束" + res_m;
      } else {
        title = title + "活动已结束";
      }
      return title;
    },
  },
  watch: {
    tel(val) {
      if (val.length === 11) {
        this.verificationDisabled = false;
        this.verificationCodeShow = false;
        this.telEncryptionValue = this.$common.telEncryption(this.tel);
      } else {
        this.verificationDisabled = true;
        this.verificationCodeShow = true;
      }
    },
    sms(val) {
      if (val.length === 4) {
        this.phoneLogin();
      }
    },
  },
  mounted() {
    document.title = "确认订单";
    this.groupOrderId_loaction = this.$common.getCookie(
      "groupOrderId_loaction"
    );
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener("popstate", this.goBack, false);
    }
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.skuId = this.$route.query.skuId;
    this.productId = this.$route.query.productId;
    this.entranceId = this.$route.query.entranceId;
    this.code = this.$route.query.code;
    this.appId = this.$route.query.appId;
    if (this.code) {
      this.weChatLogin();
    } else {
      this.userCheckLogin();
    }
    // this.getExtraInfo({ skuId: this.skuId });
    this.getservicePolicy({ productId: this.productId });
    this.getProductSubView({ productId: this.productId, type: "PAY_DETAIL" });
    this.getProductSubView({ productId: this.productId, type: "PAY_POPOUT" });
    this.getProductTextInfo();
  },
  methods: {
    // 获取拼团信息
    getSimpleGroupView() {
      simpleGroupView({ skuId: this.skuId }).then((res) => {
        if (res.result === 200) {
          this.groupData = res.data;
          this.supportGroup = false;
          if (this.groupData.allowStartGroup || this.groupData.allowJoinGroup) {
            this.supportGroup = true;
          }
          if (this.supportGroup) {
            this.activityValidSecond = res.data.groupOrderValidMinute * 60;
            let time =
              (res.data.activityExpireTime - new Date().getTime()) / 1000;
            this.activityExpireTime = Math.floor(time);
            let timer_s = setInterval(() => {
              let m = this.activityExpireTime;
              if (m < 1) {
                clearInterval(timer_s);
                this.activityExpireTime = m;
                return;
              }
              this.activityExpireTime = m - 1;
            }, 1000);
            let time_g =
              (this.groupData.groupOrderExpiredTime - new Date().getTime()) /
              1000;
            this.validMinutes = Math.floor(time_g);
            let timer = setInterval(() => {
              let m = this.validMinutes;
              if (m < 1) {
                clearInterval(timer);
                this.validMinutes = m;
                return;
              }
              this.validMinutes = m - 1;
            }, 1000);
          }
        }
      });
    },
    // 获取指定拼团信息
    getSpecifiedGroupOrderView() {
      specifiedGroupOrderView({
        skuId: this.skuId,
        groupOrderId: this.groupOrderId_loaction,
      }).then((res) => {
        if (res.result === 200) {
          this.groupData = res.data;
          this.supportGroup = false;
          if (this.groupData.allowStartGroup || this.groupData.allowJoinGroup) {
            this.supportGroup = true;
          }
          if (this.supportGroup) {
            this.activityValidSecond = res.data.groupOrderValidMinute * 60;
            let time =
              (res.data.activityExpireTime - new Date().getTime()) / 1000;
            this.activityExpireTime = Math.floor(time);
            let timer_s = setInterval(() => {
              let m = this.activityExpireTime;
              if (m < 1) {
                clearInterval(timer_s);
                this.activityExpireTime = m;
                return;
              }
              this.activityExpireTime = m - 1;
            }, 1000);
            let time_g =
              (this.groupData.groupOrderExpiredTime - new Date().getTime()) /
              1000;
            this.validMinutes = Math.floor(time_g);
            let timer = setInterval(() => {
              let m = this.validMinutes;
              if (m < 1) {
                clearInterval(timer);
                this.validMinutes = m;
                return;
              }
              this.validMinutes = m - 1;
            }, 1000);
          }
        }
      });
    },
    // 点击去拼团
    payGroupClick(key) {
      if (key === "old") {
        this.paymentClick(this.groupData.groupOrderId, "JOIN_GROUP");
      }
      if (key === "new") {
        this.paymentClick(undefined, "START_GROUP");
      }
    },
    // 监听附加信息
    inputChange(val) {
      let infoData = {
        addressDetail: this.addressDetail,
        area: this.area,
        city: this.city,
        id: this.addressId,
        province: this.province,
        receiverName: this.receiverName,
        receiverPhone: this.receiverPhone,
        tel: this.tel,
        extraInfoData: this.extraInfoData,
        orderId: this.orderId,
        productId: this.productId,
        skuId: this.skuId,
        entranceId: this.entranceId,
      };
      console.log("监听附加信息", infoData, this.tel);
      this.$common.setCookie("info_data", JSON.stringify(infoData));
    },
    // 查询地址
    getAddressList() {
      addressList().then((res) => {
        if (res.result === 200) {
          let list = res.data;
          list.forEach((item) => {
            if (item.defaultAddress) {
              this.area = item.area;
              this.city = item.city;
              this.province = item.province;
              this.addressDetail = item.addressDetail;
              this.receiverPhone = item.receiverPhone;
              this.receiverName = item.receiverName;
              this.addressId = item.id;
              let tel = this.$common.telEncryption(item.receiverPhone);
              this.receiverNameInfo = item.receiverName + tel;
            }
          });
          this.getExtraInfo({ skuId: this.skuId });
        }
      });
    },
    // 点击地址栏
    addressClick() {
      this.$router.push({
        path: "/addressList",
        query: { orderId: this.orderId },
      });
    },
    // 查询优惠券列表
    getUserCouponList() {
      api.autoDistribute({ productId: this.productId }).then((res) => {
        if (res.result === 200) {
          let data = {
            skuId: this.skuId,
          };
          api.userCouponList(data).then((res) => {
            if (res.result === 200) {
              let list = res.data.userCouponInfoList;

              // -----------------------------------------------
              this.coupons = [];
              this.disabledCoupons = [];
              list.forEach((item) => {
                let full = item.fullValue / 100;
                let reduction = item.reductionValue / 100;
                let title = item.title;
                if (item.subTitle) {
                  title += "(" + item.subTitle + ")";
                }
                let coupon = {
                  id: item.memberCouponId,
                  condition: "满" + full + "减" + reduction,
                  reason: item.remark,
                  description: item.remark,
                  value: item.reductionValue,
                  name: title,
                  startAt: item.effectDate / 1000,
                  endAt: item.expireDate / 1000,
                  valueDesc: item.reductionValue / 100,
                  unitDesc: "元",
                };
                if (item.usable) {
                  this.coupons.push(coupon);
                } else {
                  this.disabledCoupons.push(coupon);
                }
              });
              let defaultCouponId = res.data.defaultMemberCouponId;
              for (let i = 0; i < this.coupons.length; i++) {
                if (defaultCouponId === this.coupons[i].id) {
                  this.onChange(i);
                }
              }
            }
          });
        }
      });
    },
    // 选择优惠券
    onChange(index) {
      this.showList = false;
      this.chosenCoupon = index;
      this.discountPrice = 0;
      this.memberCouponId = null;
      this.usingCoupon = false;
      this.coupons.forEach((item, i) => {
        if (index === i) {
          this.discountPrice = item.value;
          this.memberCouponId = item.id;
          this.usingCoupon = true;
        }
      });
    },
    // 查询手机号文案
    getProductTextInfo() {
      let data = {
        productId: this.productId,
        type: "MOBILE_FILL_HINT",
      };
      api.getProductTextInfo(data).then((res) => {
        if (res.result === 200) {
          if (res.data) {
            this.phoneTitel = res.data.content;
          }
        }
      });
    },
    // 查看商品图片
    getProductSubView(data) {
      productSubView(data).then((res) => {
        if (res.result === 200) {
          let list = res.data.viewList;
          list.map((item) => {
            item.imageUrl = item.imageUrl.replace("https", "https");
          });
          if (data.type === "PAY_POPOUT") {
            if (list && list.length > 0) {
              this.overlayImageUrl = list[0].imageUrl;
              // 3分钟后弹出报名提示弹窗
              setTimeout(() => {
                this.overlayShow = true;
                this.imgShow = true;
              }, 3 * 60 * 1000);
            }
          }
          if (data.type === "PAY_DETAIL") {
            if (list && list.length > 0) {
              this.payDetailList = list;
            }
          }
        }
      });
    },
    // 点击使用本机号码
    useLocalNumber(phone, orderId) {
      this.tel = phone;
      this.telEncryptionValue = this.$common.telEncryption(this.tel);
      this.telEncryptionShow = true;
      this.verificationCodeShow = false;
      this.verificationLoading = false;
      this.verificationCountDown = 60;
      if (orderId) {
        return;
      }
      this.orderCreate();
    },
    // 点击切换号码
    exchangeClick() {
      this.localNumberShow = false;
      this.telEncryptionShow = false;
      this.telEncryptionValue = "";
      this.tel = "";
      this.sms = "";
      this.verificationCodeShow = true;
      this.verificationLoading = false;
      this.verificationCountDown = 60;
    },
    // 手机号登录
    phoneLogin() {
      let data = {
        phone: this.tel,
        checkCode: this.sms,
        productId: this.productId,
      };
      api.phoneLogin(data).then((res) => {
        if (res.result === 200) {
          this.telEncryptionShow = true;
          this.orderCreate();
          this.getAddressList();
          // 查询优惠券列表
          this.getUserCouponList();
        }
      });
    },
    // 创建订单
    orderCreate() {
      let data_create = {
        skuId: this.skuId,
        productId: this.productId,
        entranceId: this.entranceId,
      };
      let ua = window.navigator.userAgent.toLowerCase();
      this.$http.json_post("/user/trace", { content: ua }).then((res) => {});
      api.orderCreate(data_create).then((res) => {
        if (res.result === 200) {
          this.orderId = res.data;
          let infoData = {
            addressDetail: this.addressDetail,
            area: this.area,
            city: this.city,
            id: this.addressId,
            province: this.province,
            receiverName: this.receiverName,
            receiverPhone: this.receiverPhone,
            tel: this.tel,
            extraInfoData: this.extraInfoData,
            orderId: this.orderId,
            productId: this.productId,
            skuId: this.skuId,
            entranceId: this.entranceId,
          };
          console.log("创建订单", infoData, this.tel);
          this.$common.setCookie("info_data", JSON.stringify(infoData));
        }
      });
    },
    // test1() {
    //   let data = {
    //     redirectUri:
    //       "http://mallnew.huiyu.org.cn/cartPage?productId=16&skuId=13&entranceId=1661&appId=wx8223a6613109092f",
    //     appId: "wx8223a6613109092f",
    //   };
    //   api.weChantAuth(data).then((res) => {
    //     if (res.result === 200) {
    //       window.location.href = res.data;
    //     }
    //   });
    // },
    // test2() {
    //   api
    //     .weChatLogin({
    //       code: this.code,
    //       appId: "wx8223a6613109092f",
    //     })
    //     .then((res) => {
    //       if (res.result === 200) {
    //         this.userCheckLogin();
    //         // this.getAddressList();
    //         // // 查询优惠券列表
    //         // this.getUserCouponList();
    //       }
    //     });
    // },
    // 微信授权
    weChantAuth() {
      let data = {
        redirectUri: window.location.href,
        appId: this.appId,
      };
      api.weChantAuth(data).then((res) => {
        if (res.result === 200) {
          window.location.href = res.data;
        }
      });
    },
    // 微信用户登陆
    weChatLogin() {
      api
        .weChatLogin({
          code: this.code,
          appId: this.appId,
        })
        .then((res) => {
          if (res.result === 200) {
            this.userCheckLogin();
            this.getAddressList();
            // 查询优惠券列表
            this.getUserCouponList();
          }
        });
    },
    // 检查用户是否登陆
    userCheckLogin() {
      api
        .userCheckLogin({
          appId: this.appId,
        })
        .then((res) => {
          if (res.result === 200) {
            // this.unLogin = res.data.userSessionInfo ? false : true;
            // 没有登录
            if (res.data.userSessionInfo === null) {
              if (this.v_WeiXin) {
                // 微信授权
                this.weChantAuth();
              }
            } else {
              if (res.data.userSessionInfo.phone) {
                this.localNumberShow = !this.unLogin;
                this.telEncryptionValue_local = res.data.userSessionInfo.phone;
              }
              this.getAddressList();
              // 查询优惠券列表
              this.getUserCouponList();
            }
          }
        });
    },
    // 报名提示弹窗
    overlayClose() {
      this.overlayShow = false;
      this.imgShow = false;
    },
    // 报名提示弹窗图片
    imgClick() {
      this.paymentClick(undefined, "INDIVIDUAL_PAYMENT");
    },
    // 额外信息选项选择
    onOptionConfirm(val, index) {
      this.$set(this.extraInfoData[index], "value", val);
      this.$set(this.extraInfoData[index], "showOption", false);
      let infoData = {
        addressDetail: this.addressDetail,
        area: this.area,
        city: this.city,
        id: this.addressId,
        province: this.province,
        receiverName: this.receiverName,
        receiverPhone: this.receiverPhone,
        tel: this.tel,
        extraInfoData: this.extraInfoData,
        orderId: this.orderId,
        productId: this.productId,
        skuId: this.skuId,
        entranceId: this.entranceId,
      };
      console.log("额外信息选项选择", infoData, this.tel);
      this.$common.setCookie("info_data", JSON.stringify(infoData));
    },
    // 继续支付
    tipColse() {
      this.tipshow = !this.tipshow;
    },
    // 残忍拒绝
    tipBacks() {
      this.$router.push({
        path: "/homePage",
        query: {
          productId: this.productId,
          entranceId: this.entranceId,
        },
      });
    },
    // 回退拦截跳转
    goBack() {
      if (this.paySuccess) {
        return;
      }
      this.tipshow = true;
    },
    // 获取订单信息
    getExtraInfo(data) {
      api.extraInfo(data).then((res) => {
        if (res.result === 200) {
          let list = res.data.skuInfoList;
          this.kc_list = list;
          this.stock = res.data.stock;
          this.price = res.data.price / 100;
          this.showPrice = res.data.showPrice / 100;
          this.productName = res.data.productName;
          this.needDelivery = res.data.needDelivery;
          this.paySuccess =
            res.data.orderStatus === "PAY_SUCCESS" ||
            res.data.orderStatus === "PAYING"
              ? true
              : false;
          if (this.$route.query.afterPay && this.paySuccess) {
            this.$dialog
              .confirm({
                title: "确认支付",
                message: "确认微信支付是否已完成",
                confirmButtonText: "支付完成",
                cancelButtonText: "重新支付",
              })
              .then(() => {
                api.queryWxPayResult({ orderId: this.orderId }).then((res) => {
                  if (res.result === 200) {
                    if (res.data) {
                      if (res.data.type === "redirect") {
                        window.location = res.data.url;
                      }
                    }
                  }
                });
              })
              .catch(() => {
                // on cancel
              });
          }
          this.extraInfoData = [];
          let oldCartInfo = this.$common.getCookie("info_data");
          console.log("123", oldCartInfo);
          if (oldCartInfo) {
            oldCartInfo = JSON.parse(oldCartInfo);
            console.log("oldCartInfo", oldCartInfo);
            this.useLocalNumber(oldCartInfo.tel, oldCartInfo.orderId);
            this.area = oldCartInfo.area;
            this.city = oldCartInfo.city;
            this.province = oldCartInfo.province;
            this.addressDetail = oldCartInfo.addressDetail;
            this.receiverPhone = oldCartInfo.receiverPhone;
            this.receiverName = oldCartInfo.receiverName;
            this.addressId = oldCartInfo.id;
            let tel = "";
            if (oldCartInfo.receiverPhone) {
              tel = this.$common.telEncryption(oldCartInfo.receiverPhone);
            } else {
              tel = "";
            }
            this.receiverNameInfo = oldCartInfo.receiverName + tel;
            this.orderId = oldCartInfo.orderId;
            this.extraInfoData = oldCartInfo.extraInfoData;
            this.$forceUpdate();
          } else {
            this.tel = "";
            this.sms = "";
            console.log("进入了else", this.tel);
            let extraInfoList = res.data.defaultExtraInfoList;
            if (extraInfoList) {
              extraInfoList.forEach((item) => {
                this.extraInfoData.push({
                  field: item.field,
                  fieldType: item.fieldType,
                  optionalList: item.optionalList,
                  value: "",
                  showOption: false,
                });
              });
            }
          }
          if (this.groupOrderId_loaction) {
            this.getSpecifiedGroupOrderView();
          } else {
            this.getSimpleGroupView();
          }
          this.$forceUpdate();
        }
      });
    },
    // 点击服务条款
    policyContentClick() {
      this.$router.push({
        path: "/policyPage",
        query: { productId: this.productId },
      });
    },
    // 获取条款信息
    getservicePolicy(data) {
      api.servicePolicy(data).then((res) => {
        if (res.result === 200) {
          if (res.data) {
            this.policyShow = true;
            this.policyTitle = res.data.policyTitle;
            if (res.data.contentType === "TEXT") {
              this.policyContent = res.data.content;
            }
            if (res.data.contentType === "URL") {
              this.policyUrl = res.data.content;
            }
          } else {
            this.policyShow = false;
          }
        }
      });
    },
    // 点击获取验证码
    getVerification() {
      if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.tel)) {
        this.$toast("手机号码有误，请重填");
        return false;
      }
      let data = {
        phone: this.tel,
      };
      this.verificationLoading = true;
      this.verificationDisabled = true;
      this.verificationCountDown = 60;
      this.timer = setInterval(() => {
        this.verificationCountDown--;
        if (this.verificationCountDown < 0) {
          clearInterval(this.timer);
          this.verificationLoading = false;
          this.verificationDisabled = false;
        }
      }, 1000);
      api.getAuthCode(data).then((res) => {
        if (res.result === 200) {
        }
      });
    },
    // 点击确认支付
    paymentClick(groupOrderId, orderType) {
      updateAddress({
        orderId: this.orderId,
        receiverAddressId: this.addressId,
      }).then(async (res) => {
        if (res.result === 200) {
          let currentTime = Date.now(),
            protectTime = 1000;
          if (this.lastRunTime) {
            if (currentTime - this.lastRunTime < protectTime) {
              this.lastRunTime = currentTime;
              return; //两次执行太过频繁，直接退出
            }
          }
          this.lastRunTime = currentTime;
          console.log("协议", this.policyCheck, "手机", this.tel);
          if (!this.policyCheck) {
            this.$toast("请同意并接受服务条款");
            return;
          }
          if (!this.tel) {
            this.$toast("请填写手机号");
            return;
          }
          if (!this.sms && this.unLogin) {
            this.$toast("请填写验证码");
            return;
          }
          let extraInfo = [];
          for (let i in this.extraInfoData) {
            if (this.extraInfoData[i].fieldType === "INPUT") {
              if (!this.extraInfoData[i].value) {
                this.$toast("请输入" + this.extraInfoData[i].field);
                return;
              }
            }
            if (this.extraInfoData[i].fieldType === "OPTION") {
              if (!this.extraInfoData[i].value) {
                this.$toast("请选择" + this.extraInfoData[i].field);
                return;
              }
            }
            let obj = {
              fieldName: this.extraInfoData[i].field,
              value: this.extraInfoData[i].value,
            };
            extraInfo.push(obj);
          }
          if (this.needDelivery) {
            if (!this.addressId) {
              this.$toast("请填写收获地址");
              return;
            }
          }
          let data = {
            orderId: this.orderId,
            extraInfoList: extraInfo,
            memberCouponId: this.memberCouponId,
            usingCoupon: this.usingCoupon,
            orderType: orderType,
            groupOrderId: groupOrderId,
          };
          if (this.radioPay === "WX_PAY") {
            if (this.v_WeiXin) {
              data.payType = "WX_PAY_JS_API";
            } else {
              data.payType = "WX_PAY_H5";
            }
          }
          let wxInfo = await api.orderPay(data);
          if (this.pricePayable === 0) {
            api.queryWxPayResult({ orderId: this.orderId }).then((res) => {
              if (res.result === 200) {
                if (res.data) {
                  if (res.data.type === "redirect") {
                    window.location = res.data.url;
                  }
                }
              }
            });
            return;
          }
          // 判断是否微信登录
          if (this.v_WeiXin) {
            if (typeof WeixinJSBridge == "undefined") {
              if (document.addEventListener) {
                document.addEventListener(
                  "WeixinJSBridgeReady",
                  this.onBridgeReady,
                  false
                );
              } else if (document.attachEvent) {
                document.attachEvent("WeixinJSBridgeReady", this.onBridgeReady);
                document.attachEvent(
                  "onWeixinJSBridgeReady",
                  this.onBridgeReady
                );
              }
            } else {
              this.onBridgeReady(wxInfo.data.param);
            }
          } else {
            window.location.href = wxInfo.data.param;
          }
        }
      });
    },
    // 微信支付
    onBridgeReady(data) {
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId: data.appId, //公众号名称，由商户传入
          timeStamp: data.timestamp, //时间戳，自1970年以来的秒数
          nonceStr: data.nonceStr, //随机串
          package: "prepay_id=" + data.prepayId,
          signType: data.signType, //微信签名方式：
          paySign: data.paySign, //微信签名
        },
        (res) => {
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            api.queryWxPayResult({ orderId: this.orderId }).then((res) => {
              if (res.result === 200) {
                if (res.data) {
                  // if (this.needDelivery) {
                  //   this.$router.push({
                  //     path: "/addressList",
                  //     query: { orderId: this.orderId }
                  //   });
                  // } else {
                  //   }
                  if (res.data.type === "redirect") {
                    window.location = res.data.url;
                  }
                }
              }
            });
          } else {
            // 支付失败返回上一页
            api.payFailed({ orderId: this.orderId }).then((res) => {
              if (res.result === 200) {
                this.$router.push({
                  path: "/homePage",
                  query: {
                    productId: this.productId,
                    entranceId: this.entranceId,
                  },
                });
              }
            });
          }
        }
      );
    },
    // 点击展开or收起
    foldClick() {
      this.isFold = !this.isFold;
      if (this.isFold) {
        let length = this.kc_list.length;
        this.foldBtn = "展开全部( " + length + " )";
      } else {
        this.foldBtn = "收起";
      }
    },
    // 付款倒计时结束重新计时
    finish() {
      this.$refs.countDown.reset();
    },
  },
  destroyed() {
    window.removeEventListener("popstate", this.goBack, false);
  },
};
</script>
<style lang="scss" scoped>
.cart_page_style {
  padding: 0rem 0rem 6.6rem;

  .cart_page_head {
    border-radius: 0.4rem;
    background: #fff;
    display: flex;
    align-items: center;
    height: 5rem;
    margin: 0 auto;
    width: 22rem;
    .cart_page_quota {
      flex: 1;
      .cart_page_quota_num {
        color: #ff5e2c;
        font-size: 1.5rem;
        height: 1.5rem;
        line-height: 1.5rem;
      }
      .cart_page_quota_text {
        margin-top: 0.15rem;
        color: #999999;
        font-size: 0.7rem;
      }
    }
    .cart_page_x {
      height: 2.5rem;
      width: 1px;
      background: #f2f2f2;
    }
    .cart_page_time {
      flex: 1;
      .cart_page_time_text {
        margin-top: 0.15rem;
        color: #999999;
        font-size: 0.7rem;
      }
      .colon {
        display: inline-block;
        margin: 0 0.2rem;
        color: #ff2e21;
      }
      .block {
        display: inline-block;
        width: 1.35rem;
        color: #fff;
        // font-size: 0.8rem;
        text-align: center;
        background: linear-gradient(173deg, #ff7f72 0%, #f5493f 100%);
      }
    }
  }

  .cart_page_verification {
    background: #fff;
    border-radius: 0.4rem;
    margin: 1rem auto;
    width: 22rem;

    .exchange_ccc {
      display: flex;
      align-items: center;
    }

    .local_number {
      // border-bottom: 1px solid #f8f8f8;
      cursor: pointer;
      position: relative;
      .custom-title {
        width: 12rem;
        margin: auto;
        margin-bottom: 1rem;
        overflow: hidden;
        padding: 0;
        font-size: 0.8rem;
        line-height: 1.6rem;
        text-align: center;
        color: #333;
        background-color: #f8f8f8;
        .help {
          color: #999;
          margin-left: 0.3rem;
        }
      }
    }

    .van-cell {
      border-radius: 0.4rem;
    }
    .select_item {
      &::after {
        border: 0;
      }
    }
    .yi_img {
      width: 4rem;
    }
    .wb_text {
      color: #ff3d33;
      padding: 0.6rem 1rem;
      font-size: 0.78rem;
      text-align: left;
      border-bottom: 1px solid #f8f8f8;
      span {
        line-height: 1rem;
      }
    }
  }

  .cart_page_conent {
    background: #fff;
    margin: 1rem auto;
    border-radius: 0.4rem;
    width: 22rem;

    .cart_page_content_title {
      text-align: left;
      padding: 1rem 1rem 0;
      padding-bottom: 1rem;
      .c_p_c_d {
        line-height: 1.5rem;
        font-size: 1rem;
        color: #36404a;
        font-weight: 700;
        margin-top: 0;
      }
      .c_p_c_t {
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.5rem;
        color: #333333;
        text-indent: -0.4rem;
      }
      .c_P_C_m {
        font-size: 0.24rem;
        line-height: 0.33rem;
        color: #999999;
        margin-top: 0.12rem;
      }
    }

    .cart_page_content_c {
      text-align: left;
      padding: 0rem 1rem 0;
      .cart_page_content_c_t {
        border-top: 1px solid #f2f2f2;
        color: #333333;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1rem;
        margin: 0 0 1rem;
        padding-top: 1rem;
      }
      .c_K_c {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin-bottom: 1.3rem;
        img {
          width: 4rem;
          height: 4rem;
          box-sizing: border-box;
          display: block;
          border-radius: 0.5rem;
          flex-grow: 0;
          flex-shrink: 0;
          border: 1px solid #f8f8f8;
        }
        .text-disc {
          margin-left: 0.3rem;
          width: calc(100% - 1.2rem);
          p {
            font-size: 0.95rem;
            color: #333333;
            line-height: 1.2rem;
            margin: 0;
            text-indent: 0.4rem;
          }
        }
        .c_tag {
          margin-top: 0.3rem;
          color: #ff7f58;
          padding: 0.1rem 0.3rem;
        }
      }
    }

    .more_btn {
      margin: 0 auto 1rem;
      width: 6.8rem;
      height: 2rem;
      background: #f4f4f4;
      border-radius: 1rem;
      color: #333;
      font-size: 0.95rem;
      line-height: 2rem;
      text-align: center;
    }

    .cart_page_content_c_f {
      height: 10.3rem;
      overflow: hidden;
    }

    .order_price_content {
      padding: 0rem 1rem 0;
      .price_text_area {
        padding-top: 1rem;
        border-top: 1px solid #f2f2f2;
        border-bottom: 1px solid #f2f2f2;
        .price_text {
          font-size: 0.95rem;
          color: #333;
          display: flex;
          justify-content: space-between;
          &:last-child {
            margin-bottom: 1rem;
          }
          &:not(:first-child) {
            margin-top: 0.5rem;
          }
        }
      }
      .price_tips_wrap {
        height: 3.6rem;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        .tips_left {
          color: #999;
          font-size: 0.8rem;
          line-height: 0.8rem;
          height: 0.8rem;
          display: flex;
          align-items: center;
          .small_text {
            position: relative;
            display: inline-block;
            overflow: visible;
            margin-right: 0.3rem;
          }
        }
        .tips_price {
          font-size: 0.8rem;
          color: #ff5e2c;
          line-height: 0.8rem;
          &::before {
            content: "应付款：";
            display: inline-block;
            font-size: 0.8rem;
            line-height: 0.8rem;
            height: 0.8rem;
            color: #333;
          }
        }
      }
    }

    .service_wrap {
      padding: 1rem 1rem 0;
      .service_title {
        display: flex;
        justify-content: space-between;
        font-size: 0.95rem;
        color: #333333;
        font-weight: 500;
        line-height: 0.95rem;
      }
      .service_list {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 0.8rem;
        margin-top: 1rem;
        .service_list_item {
          display: flex;
          align-items: center;
          line-height: 0.9rem;
          padding-bottom: 1rem;
          img {
            width: 0.9rem;
            height: 0.9rem;
            margin-right: 0.2rem;
          }
        }
      }
    }
  }

  .order_modules_content {
    background: #fff;
    margin: 1rem auto 0;
    width: 22rem;
    border-radius: 0.4rem;
    text-align: left;
    .order_header {
      padding: 0.5rem 1rem;
      font-size: 1rem;
      color: #36404a;
      font-weight: 700;
    }
    .van-cell-group {
      border-radius: 0.4rem;
      .van-cell {
        border-radius: 0.4rem;
      }
      .van-cell--clickable:active {
        background-color: #fff;
      }
    }
    .van-hairline--top-bottom::after {
      border-width: 0;
    }
    img {
      width: 2rem;
      height: 2rem;
      font-size: 0;
      margin-right: 0.8rem;
    }
    .c_title {
      display: inline-block;
      height: 2rem;
      vertical-align: top;
      line-height: 2rem;
    }
  }

  .order_bottom_wrap {
    position: fixed;
    height: 5.6rem;
    width: 100%;
    bottom: 0;
    background: #ffffff;
    z-index: 1;
    .btn-count-content {
      display: flex;
      justify-content: center;
      line-height: 1.8rem;
      background-color: #ffeee9;
      font-size: 0.78rem;
      color: #ff3d33;
      align-items: center;
      .btn-count-down {
        margin: 0 0.5rem;
      }
      .block {
        display: inline-block;
        width: 1rem;
        height: 1rem;
        color: #fff;
        text-align: center;
        font-size: 0.78rem;
        background: #ff3d33;
        border-radius: 0.3rem;
      }
      .colon {
        display: inline-block;
        margin: 0 0.2rem;
        color: #ff2e21;
      }
    }
    .order_bottom_content {
      position: fixed;
      bottom: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 1rem;
      height: 3.6rem;
      width: 100%;
      background: #fff;
      box-sizing: border-box;
      .bottom_price {
        color: red;
        font-size: 0.95rem;
        &::before {
          content: "应付：";
          font-size: 0.95rem;
          color: #000000;
        }
      }
      .orderbottom_btn {
        background: red;
        height: 2.4rem;
        width: 6.8rem;
        border-radius: 1.5rem;
        color: #fff;
        text-align: center;
        line-height: 2.4rem;
        font-size: 1rem;
      }
    }
  }

  .order_bottom_wrap_group {
    position: fixed;
    height: 3.6rem;
    width: 100%;
    bottom: 0;
    background: #ffffff;
    z-index: 1;
    .order_group {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      .btn {
        width: 50%;
        border: 0;
        height: 3.6rem;
        .rp {
          padding-right: 0.75rem;
        }
      }
    }
  }

  .policy_style {
    display: flex;
    align-items: center;
    padding-left: 1rem;
    line-height: 3rem;
    color: #848a90;
    font-size: 0.9rem;

    .a_z_c {
      color: #1989fa;
    }

    /deep/.van-checkbox {
      margin-right: 0.5rem;
      .van-checkbox__icon {
        width: 1rem;
        height: 1rem;
        line-height: 1rem;
        font-size: 0.9rem;
      }
    }
  }

  .group_work {
    width: 22rem;
    background: #fff;
    margin: 1rem auto 0;
    height: 4.8rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 0.4rem;
    text-align: left;
    .item {
      flex-shrink: 0;
      width: 28%;
      font-size: 0.9rem;
      .item_cc {
        display: flex;
        align-items: center;
        .text_cc {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .img_m {
          width: 2rem;
          height: 2rem;
          border-radius: 50%;
          margin-right: 0.5rem;
        }
        .b_img {
          font-size: 1.8rem;
          text-align: center;
          border: 1px solid;
        }
      }
    }
    .btn {
      height: 2.5rem;
      border-radius: 0.5rem;
      padding: 0 0.6rem;
    }
  }

  .sheet_content {
    padding: 0rem 1rem 0;
    max-height: 22rem;
    overflow-y: scroll;

    .sheet_content_item {
      display: flex;
      flex-direction: row;
      padding: 1rem 0;
      border-bottom: 1px solid #f2f2f2;

      img {
        width: 0.9rem;
        height: 0.9rem;
        margin-right: 0.4rem;
      }

      .content_title {
        margin: 0rem 0 0.65rem 0;
        color: #000;
        font-size: 0.8rem;
        text-align: left;
        font-weight: 500;
      }

      .content_title_content {
        color: #999999;
        font-size: 0.65rem;
        text-align: left;
        line-height: 1rem;
      }
    }
  }

  .tip_div {
    margin: 1rem auto;
    .tip_title {
      margin-bottom: 1rem;
      .title_color {
        color: #f5493f;
      }
    }
    .tip_calss {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      .tip_calss_item {
        width: 42%;
        font-size: 0.9rem;
        margin: 0.4rem 0;
        padding: 0.3rem 0;
        background: rgba(255, 137, 23, 0.3);
        border-radius: 0.9rem 0.1rem 0.9rem 0.1rem;
      }
    }
  }

  .address_m_c {
    text-align: left;
    position: relative;
    &::before {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: 2px;
      background: -webkit-repeating-linear-gradient(
        135deg,
        #ff6c6c 0,
        #ff6c6c 20%,
        transparent 0,
        transparent 25%,
        #1989fa 0,
        #1989fa 45%,
        transparent 0,
        transparent 50%
      );
      background: repeating-linear-gradient(
        -45deg,
        #ff6c6c 0,
        #ff6c6c 20%,
        transparent 0,
        transparent 25%,
        #1989fa 0,
        #1989fa 45%,
        transparent 0,
        transparent 50%
      );
      background-size: 80px;
      content: "";
    }
    .title-area {
      font-size: 0.8rem;
    }
    .title-address {
      font-size: 1rem;
      font-weight: 700;
    }
    .title-name {
      font-size: 0.8rem;
    }
  }

  .popup-cc {
    height: 90%;
    padding-top: 4px;
    text-align: left;
    /deep/.van-coupon__head {
      width: 6rem;
    }
    /deep/.van-coupon__name {
      width: 12rem;
    }
  }
}
</style>
