import axios from "axios";
import router from "@/router";
import { Notify } from "vant";
const qs = require("qs");

let path = location.protocol + process.env.VUE_APP_BASE_API;

axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";
axios.defaults.withCredentials = true;
// http request 拦截器
axios.interceptors.request.use(
  (config) => {
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

// http response 拦截器
axios.interceptors.response.use(
  (response) => {
    errcode(response.data.code);
    return response.data;
  },
  (error) => {
    if (error && error.response) {
      // console.log("error:"+error);
      responseError(error);
    }
    return Promise.reject(error);
  }
);

//错误执行提示
const responseError = function (err) {
  let newMessage = new Date().getTime();
  let oldMessage = "";
  switch (err.response.status) {
    case 400:
      oldMessage = JSON.parse(sessionStorage.getItem("time"));
      if (oldMessage + 500 < newMessage) {
        Notify({ type: "danger", message: err.response.data.message });
      }
      break;
    case 401:
      // err.message = '401:未授权，请登录'
      oldMessage = JSON.parse(sessionStorage.getItem("time"));
      if (oldMessage + 500 < newMessage) {
        sessionStorage.removeItem("uuid");
        Notify({ type: "danger", message: err.response.data.message });
      }
      break;
    case 403:
      // err.message = '503:拒绝访问'
      break;
    case 404:
      // err.message = `404:请求地址出错: ${err.response.config.url}`
      break;
    case 408:
      // err.message = '408:请求超时'
      break;
    case 500:
      // err.message = '500:服务器内部错误'
      err.message = "500:登录已失效, 请重新登录";
      break;
    case 501:
      err.message = "501:服务未实现";
      break;
    case 502:
      err.message = "502:网关错误";
      break;
    case 503:
      err.message = "503:服务不可用";
      break;
    case 504:
      err.message = "504:网关超时";
      break;
    case 505:
      err.message = "505:HTTP版本不受支持";
      break;
    default:
  }
  sessionStorage.setItem("time", JSON.stringify(newMessage));
  // Notice.error({title: '错误提示',desc: err.message });
};

const http = {
  /**
   * post 请求方法
   * @param url
   * @param data
   * @returns {Promise}
   */
  post(url, data) {
    url = path + url;
    return new Promise((resolve, reject) => {
      axios.post(url, qs.stringify(data)).then(
        (response) => {
          resolve(response);
        },
        (err) => {
          reject(err);
        }
      );
    });
  },
  /**
   * get 请求方法
   * @param url
   * @param data
   * @returns {Promise}
   */
  get(url, data) {
    url = path + url;
    return new Promise((resolve, reject) => {
      axios
        .get(url, {
          params: data,
        })
        .then(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  },
  /**
   * get 请求方法
   * @param url
   * @param data
   * @returns {Promise}
   */
  getTj(url, data) {
    url = "https://tj.backend.huiyu.org.cn" + url;
    return new Promise((resolve, reject) => {
      axios
        .get(url, {
          params: data,
        })
        .then(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  },
  /**
   * get 请求方法
   * @param url
   * @param data
   * @returns {Promise}
   */
  get22(url, data) {
    url = "https://fish.test.backend.huiyu.org.cn" + url;
    return new Promise((resolve, reject) => {
      axios
        .get(url, {
          params: data,
        })
        .then(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  },
  /**
   * post 请求方法  请求类型为application/json
   * @param url
   * @param data
   * @returns {Promise}
   */
  json_post22(url, data) {
    url = "https://fish.test.backend.huiyu.org.cn" + url;
    return new Promise((resolve, reject) => {
      axios
        .post(url, data, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
        })
        .then(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  },

  /**
   * put 请求方法
   * @param url
   * @param id
   * @param data
   * @returns {Promise}
   */
  // put(url, id, data) {
  // 	return new Promise((resolve, reject) => {
  // 		axios.put(url + id, data).then(response => {
  // 			resolve(response)
  // 		}, err => {
  // 			reject(err)
  // 		})
  // 	})
  // },
  /**
   * delete 请求方法
   * @param url
   * @param id
   * @returns {Promise}
   */
  // delete(url, id) {
  // 	return new Promise((resolve, reject) => {
  // 		axios.delete(url + id).then(response => {
  // 			resolve(response)
  // 		}, err => {
  // 			reject(err)
  // 		})
  // 	})
  // },
  /**
   * post 请求方法  请求类型为application/json
   * @param url
   * @param data
   * @returns {Promise}
   */
  json_post(url, data) {
    url = path + url;
    return new Promise((resolve, reject) => {
      axios
        .post(url, data, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
        })
        .then(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  },
};
function errcode(code) {
  if (code == 2000) {
    Notify({ type: "danger", message: "登录过期,请重新登录!" });
    router.push({ path: "/" });
  }
}

export default http;
