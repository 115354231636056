import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import common from './lib/common';
import http from './lib/axios';
import './common/font/font.css'  //引入字体
Vue.prototype.$common = common
Vue.prototype.$http = http

import 'vant/lib/index.css';
import Vant from 'vant';
Vue.use(Vant);
import { Lazyload } from 'vant';

import Directives from './directives'
Vue.use(Directives)

Vue.use(Lazyload);

// 注册时可以配置额外的选项
Vue.use(Lazyload, {
  lazyComponent: true
});
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
