<template>
  <div class="sharingFriend">
    <img :src="imgUrl" alt="">
  </div>
</template>
<script>
import * as api from "../lib/groupWork.js";
export default {
  name: "sharingFriend",
  data() {
    return {
      imgUrl: "",
      orderId: ""
    }
  },
  mounted() {
    document.title = "拼团分享海报";
    this.orderId = this.$route.query.orderId;
    this.getGroupSharePosterInfo();
  },
  methods: {
    // 获取拼团分享海报
    getGroupSharePosterInfo() {
      api.getSharePosterInfo({ orderId: this.orderId}).then(res => {
        if (res.result === 200) {
          // 分享
          let thisUrl = window.location.href;
          this.imgUrl = res.data.groupingPosterUrl;
          let params = {
            title: res.data.shareTitle,
            imgUrl: res.data.shareIcon,
            desc: res.data.shareContent
          }
          this.$common.shareClick(thisUrl, params);
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.sharingFriend {
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  flex-flow: column;
  justify-content: center;
  img {
    width: 100%;
  }
}
</style>