<template>
  <van-tabs v-model="active" @change="activeChange" sticky>
    <van-tab title="全部">
      <orderInfo v-if="active === 0"></orderInfo>
    </van-tab>
    <van-tab title="待支付">
      <orderInfo v-if="active === 1" :status="status"></orderInfo>
    </van-tab>
    <van-tab title="已完成">
      <orderInfo v-if="active === 2" :status="status"></orderInfo>
    </van-tab>
  </van-tabs>
</template>
<script>
import orderInfo from './orderInfo.vue';
export default {
  name: "orderList",
  components: {
    orderInfo
  },
  data() {
    return {
      active: 0,
      status: "",
    };
  },
  mounted() {
    document.title = "订单列表";
  },
  methods: {
    activeChange(name, title) {
      switch (name) {
        case 0:
          this.status = "";
          break;
        case 1:
          this.status = "INIT";
          break;
        case 2:
          this.status = "SUCCESS";
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>

</style>