<template>
  <div class="home_style">
    <router-view name="second" :v_WeiXin="v_WeiXin" v-if="show" />
  </div>
</template>
<script>
import wx from "weixin-js-sdk";
export default {
  name: "home",
  data() {
    return {
      active: 0,
      v_WeiXin: this.isWeiXin(),
      navigationId: "",
      entranceId: "",
      productId: "",
      activityPageId: "",
      // 自定义文案及图片
      title: "",
      imgUrl: "",
      desc: "",
      show: false,
    };
  },
  mounted() {
    this.navigationId = this.$route.query.navigationId;
    this.entranceId = this.$route.query.entranceId;
    this.productId = this.$route.query.productId;
    this.activityPageId = this.$route.query.activityPageId;
    if (this.navigationId) {
      this.getNavigationInfo();
    }
    if (this.productId) {
      this.getShareInfo(this.productId);
      // this.getProductId();
    }
    if (this.activityPageId) {
      this.getActivityPageInfo(this.activityPageId);
    }
    setTimeout(() => {
      this.show = true;
    }, 500);
  },
  methods: {
    // 查询商品id
    getProductId() {
      this.show = false;
      this.$http
        .json_post("/user/session", {
          entranceId: this.entranceId,
        })
        .then((res) => {
          if (res.result === 200) {
            this.productId = res.data;
            this.show = true;
            this.getShareInfo();
          }
        });
    },
    // 查询活动分享信息
    getActivityPageInfo(activityPageId) {
      this.$http
        .get("/manager/activityPage/shareInfo", {
          activityPageId: activityPageId,
        })
        .then((res) => {
          if (res.result === 200) {
            this.show = true;
            let list = res.data;
            this.title = list.shareTitle;
            this.imgUrl = list.shareIcon;
            this.desc = list.shareContent;
            this.shareClick();
          }
        });
    },
    // 查询商品分享信息
    getShareInfo(productId) {
      this.$http
        .get("/product/getShareInfo", { productId: productId })
        .then((res) => {
          if (res.result === 200) {
            this.show = true;
            let list = res.data;
            this.title = list.shareTitle;
            this.imgUrl = list.shareIcon;
            this.desc = list.shareContent;
            this.shareClick();
          }
        });
    },
    // 获取集合页面
    getNavigationInfo() {
      this.$http
        .get("/manager/h5/naviagate/detail", {
          id: this.navigationId,
        })
        .then((res) => {
          if (res.result === 200) {
            this.show = true;
            let list = res.data;
            this.title = list.shareTitle;
            this.imgUrl = list.shareIcon;
            this.desc = list.shareContent;
            this.shareClick();
          }
        });
    },
    // 判断是否微信浏览器登录
    isWeiXin() {
      let ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
      } else {
        return false;
      }
    },
    // 分享
    shareClick() {
      let thisUrl = window.location.href;
      let params = {
        title: this.title,
        imgUrl: this.imgUrl,
        desc: this.desc,
      };
      this.$common.shareClick(thisUrl, params);
    },
    setFontSize() {
      let HTML = document.documentElement;
      let winW = HTML.clientWidth;
      HTML.style.fontSize = `${(winW / 1920) * 81.92}px`;
    },
  },
};
</script>
<style lang="scss" scoped>
.home_style {
  width: 100%;
  height: 100%;
}
</style>
