import http from "./axios";

// 查询用户所有的打卡课程
export const allClock = () => {
  return http.get("/course/listMemberCourseClockVO");
};
// 微信授权--静默授权
export const weChantAuth = ({ redirectUri, appId }) => {
  return http.get("/wechat/snsapiBase", {
    redirectUri: redirectUri,
    appId: appId,
  });
};

// 微信用户登录--静默登录
export const weChatLogin = ({ code, appId }) => {
  return http.json_post("/user/snsapiBase/wxLogin", {
    code: code,
    appId: appId,
  });
};

// 检查用户是否登陆
export const userCheckLogin = ({ appId }) => {
  return http.get("/user/checkLogin", {
    appId: appId,
  });
};
