<template>
  <div class="introduceVideo" id="videoDiv">
    <video
      id="video"
      :src="url"
      controls
      preload
      webkit-playsinline
      x-webkit-airplay
      playsinline
      x5-playsinline
      :poster="coverUrl"
      :width="width"
      :height="height"
      class="video_class"
    >
      您的浏览器不支持 video 标签。
    </video>
    <!-- <img v-if="isImgShow" src="../assets/bofang.png" class="play_ccc" @click="playClick" alt=""> -->
  </div>
</template>
<script>
export default {
  name: "introduceVideo",
  data() {
    return {
      width: 320,
      height: 240,
      id: "",
      url: "",
      coverUrl: "",
      isImgShow: false,
    };
  },
  mounted() {
    this.videoId = this.$route.query.videoId;
    let ele = document.getElementById("videoDiv");
    this.width = ele.clientWidth;
    this.height = ele.clientHeight;
    this.getVideoSrc();
  },
  methods: {
    // 获取视频
    getVideoSrc() {
      let data = {
        videoId: this.videoId,
      };
      this.$http
        .get("/manager/getVodVideoLocation", data)
        .then((res) => {
          if (res.result === 200) {
            this.isImgShow = true;
            this.url = res.data.playUrl;
            this.coverUrl = res.data.coverUrl;
          }
        })
        .catch((err) => {});
    },
    playClick() {
      let video = document.getElementById("video");
      video.play();
      this.isImgShow = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.introduceVideo {
  position: relative;
  width: 100%;
  height: 100%;
  .video_class {
    display: block;
  }
  .play_ccc {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 5rem;
    height: 5rem;
    transform: translate(-50%, -50%);
  }
}
</style>
