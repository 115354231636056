import http from "./axios";

// 发送验证码
export const getCode = ({ phone }) => {
  return http.get("/user/getAuthCode", {
    phone: phone,
  });
};

// 绑定手机号
export const getPhoneBind = ({ phone, checkCode }) => {
  return http.json_post("/user/phoneBind", {
    phone: phone,
    checkCode: checkCode,
  });
};
