<template>
  <div class="qrCode">
    <div class="img">
      <img :src="imgUrl" alt="" />
    </div>
  </div>
</template>
<script>
import * as api from "../lib/qrCode.js";
// import { weChantAuth, weChatLogin, userCheckLogin } from "../lib/myClockIn.js";

export default {
  name: "qrCode",
  props: ["v_WeiXin"],
  components: {},
  data() {
    return {
      imgUrl: "",
    };
  },
  mounted() {
    document.title = "二维码";
    this.getQrCodeLink();
  },
  methods: {
    getQrCodeLink() {
      api.getQrCodeLink().then((res) => {
        if (res.result === 200) {
          this.imgUrl = res.data;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.qrCode {
  // position: relative;
  .img {
    // position: absolute;
    // left: 50%;
    // transform: translate(-50%);
    // top: 10rem;
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>