<template>
  <div class="paymentSuccess">
    <div class="pay_title">
      <van-icon name="success" class="icon"/>
      <div>{{productName}}</div>
      <div>购买成功</div>
    </div>
    <div class="pay_content">我们将尽快联系您</div>
  </div>
</template>
<script>
export default {
  name: "paymentSuccess",
  data() {
    return {
      productName: ""
    };
  },
  mounted() {
    document.title = "支付成功";
    this.productName = this.$route.query.productName;
  },
  methods: {}
};
</script>
<style lang="scss" scoped>
.paymentSuccess {
  text-align: center;
  padding: 10rem 0 0;
  .pay_title {
    font-size: 1.5rem;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .icon {
      color: #ffffff;
      width: 2.5rem;
      height: 2.5rem;
      font-size: 2.5rem;
      background: #67c23a;
      border-radius: 50%;
      padding: 0.3rem;
      margin-bottom: 0.5rem;
    }
  }
  .pay_content {
    margin-top: 1rem;
    font-size: 0.9rem;
  }
}
</style>

