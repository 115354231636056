<template>
  <div class="groupWork">
    <!-- 拼团人员 -->
    <div class="group_user">
      <div class="g_x" v-if="!groupSuccess">
        <div class="group_starus">拼团中</div>
        <div class="group_time">拼团剩余时间{{ activityValidMinutes }}</div>
      </div>
      <div class="g_x" v-if="groupSuccess">
        <div class="group_starus">拼团成功</div>
      </div>
      <div class="group_user_c">
        <div v-for="(item, index) in userList" :key="index" class="b_x">
          <div class="b_x_i" v-if="!item.show"></div>
          <van-icon name="manager" class="img_m img_b" v-if="item.show && !item.imgUrl"/>
          <img 
            :src="item.imgUrl" 
            alt="加载失败" 
            class="img_m"
            v-if="item.show && item.imgUrl"
          >
          <div v-if="index === 0" class="tag_m">团长</div>
        </div>
      </div>
    </div>
    <!-- 订单信息 -->
    <div class="cart_page_conent">
      <div class="cart_page_content_title">
        <p class="c_p_c_d">团单编号：{{ groupData.groupOrderNumber }}</p>
      </div>
      <div class="cart_page_content_c">
        <div class="c_K_c">
          <img :src="groupData.skuImageUrl" alt="">
          <div class="text_disc">
            <div class="text_title">{{ groupData.skuName }}</div>
            <div class="price_disc">
              <div class="text_1">拼团价 ¥ {{ groupData.groupSkuPrice / 100 }}</div>
              <div class="text_2">X 1</div>
            </div>
          </div>
        </div>
      </div>
      <div class="order_price_content">
        <div class="price_text_area">
          <div class="price_text">
            <div>实付款：</div>
            <div style="color: #ff5e2c;">¥{{ groupData.payment / 100 }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 支付信息 -->
    <div class="pay_page_conent">
      <div class="pay_info">
        <div class="text">
          订单编号：{{ groupData.orderId }}
        </div>
        <div class="text">
          支付方式：微信
        </div>
        <div class="text">
          下单时间：{{ groupData.payTime }}
        </div>
      </div>
    </div>
    <!-- 拼团邀请 -->
    <div class="order_bottom_wrap_group">
      <!-- <van-button type="danger" block v-copy="joinGroupUrl">复制连接邀请好友拼团</van-button> -->
      <van-button type="danger" block @click="sharingFriendClick">邀请好友拼团</van-button>
    </div>
    <!-- 分享指引 -->
    <div class="sharing_guidelines" v-if="sharingShow" @click="sharingClick">
      <div class="content_i">
        <div class="content_i_i">
          <div class="content_i_i_i">点击右上角分享</div>
        </div>
        <div class="circle_1"></div>
        <div class="circle_2"></div>
        <div class="circle_3"></div>
      </div>
    </div>
  </div>
</template>
<script>
import * as api from "../lib/groupWork.js";
export default {
  name: "groupWork",
  data() {
    return {
      sharingShow: false,
      groupSuccess: false,
      validMinutes: 0,
      userList: [],
      groupData: {
        groupOrderNumber: "",
        groupSkuPrice: "",
        joinGroupCount: "",
        orderId: "",
        payTime: "",
        payment: "",
        skuImageUrl: "",
        skuName: "",
        successCount: ""
      },
      orderId: "",
      joinGroupUrl: ""
    }
  },
  computed: {
    activityValidMinutes() {
      let m = this.validMinutes;
      let res = this.$common.secondCompute(m);
      return res
    }
  },
  mounted() {
    document.title = "订单详情";
    this.orderId = this.$route.query.orderId;
    this.getJoinGroupSuccessInfo();
  },
  methods: {
    // 查询拼团信息
    getJoinGroupSuccessInfo() {
      api.joinGroupSuccessInfo({ orderId: this.orderId }).then(res => {
        if (res.result === 200) {
          this.groupData = res.data;
          this.joinGroupUrl = res.data.joinGroupUrl;
          let time = (this.groupData.expireTime - new Date().getTime()) / 1000;
          this.validMinutes = Math.floor(time);
          let timer = setInterval(() => {
            let m = this.validMinutes;
            if (m < 1) {
              clearInterval(timer);
              this.validMinutes = m;
              return
            }
            this.validMinutes = (m - 1);
          }, 1000);
          if (this.groupData.successCount === this.groupData.joinGroupCount) {
            this.groupSuccess = true;
          }
          for (let i = 0; i < this.groupData.successCount; i++) {
            this.userList.push({
              id: i + 1,
              show: false,
              imgUrl: ""
            })
          }
          for (let j = 0; j < this.groupData.joinGroupCount; j++) {
            this.userList[j].show = true;
          }
          if (this.groupData.headImageUrlList) {
            if (this.groupData.leaderHeadImageUrl) {
              this.groupData.headImageUrlList.unshift(this.groupData.leaderHeadImageUrl);
            } else {
              this.groupData.headImageUrlList.unshift("");
            }
          }
          for (let i in this.groupData.headImageUrlList) {
            let item = this.groupData.headImageUrlList[i];
            this.userList[i].imgUrl = item;
          }
        }
      })
    },
    // 分享
    shareClick() {
      let thisUrl = window.location.href;
      let params = {
        title: "拼团测试",
        imgUrl: "https://image.huiyu.org.cn/20210303162456km7JsTOCxQxh.png",
        desc: "测试的内容"
      }
      this.$common.shareClick(thisUrl, params);
    },
    // 点击邀请好友拼团
    sharingClick() {
      this.sharingShow = !this.sharingShow;
    },
    // 点击邀请好友拼团
    sharingFriendClick() {
      this.$router.push({
        path: "/sharingFriend",
        query: { orderId: this.orderId }
      });
    }
  },
}
</script>
<style lang="scss" scoped>
.groupWork {
  padding: 0rem 0rem 3.6rem;
  text-align: left;

  .group_user {
    margin: 1rem auto;
    background: #ffffff;
    border-radius: 0.4rem;
    width: 22rem;
    .g_x {
      padding: 0.8rem;
      .group_starus {
        color: red;
        font-weight: 700;
        font-size: 1.2rem;
      }
      .group_time {
        font-size: 0.8rem;
      }
    }
    .group_user_c {
      display: flex;
      justify-content: center;
      width: 12rem;
      margin: 0 auto;
      flex-wrap: wrap;
      padding-bottom: 0.8rem;
      .b_x {
        width: 30%;
        height: 3.8rem;
        position: relative;
        .b_x_i {
          display: block;
          margin: 0 auto;
          width: 2.5rem;
          height: 2.5rem;
          border-radius: 50%;
          flex-shrink: 0;
          font-size: 2.2rem;
          border: 1px solid #000000;
          text-align: center;
        }
        .img_m {
          display: block;
          margin: 0 auto;
          width: 2.5rem;
          height: 2.5rem;
          border-radius: 50%;
          flex-shrink: 0;
        }
        .img_b {
          font-size: 2.2rem;
          border: 1px solid;
          text-align: center;
        }
        .tag_m {
          margin: 0 auto;
          width: 2.5rem;
          font-size: 0.8rem;
          text-align: center;
          background: #ffd01e;
          color: #ffffff;
          border-radius: 0.3rem;
        }
      }
    }
  }

  .cart_page_conent {
    background: #fff;
    margin: 1rem auto;
    border-radius: 0.4rem;
    width: 22rem;

    .cart_page_content_title {
      text-align: left;
      padding: 1rem 1rem 0;
      padding-bottom: 1rem;
      .c_p_c_d {
        line-height: 1.5rem;
        font-size: 1rem;
        color: #36404a;
        font-weight: 700;
        margin-top: 0;
      }
      .c_p_c_t {
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.5rem;
        color: #333333;
        text-indent: -0.4rem;
      }
      .c_P_C_m {
        font-size: 0.24rem;
        line-height: 0.33rem;
        color: #999999;
        margin-top: 0.12rem;
      }
    }

    .cart_page_content_c {
      text-align: left;
      padding: 0rem 1rem 0;
      .cart_page_content_c_t {
        border-top: 1px solid #f2f2f2;
        color: #333333;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1rem;
        margin: 0 0 1rem;
        padding-top: 1rem;
      }
      .c_K_c {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin-bottom: 1.3rem;
        img {
          width: 4rem;
          height: 4rem;
          box-sizing: border-box;
          display: block;
          border-radius: 0.5rem;
          flex-grow: 0;
          flex-shrink: 0;
          border: 1px solid #f8f8f8;
        }
        .text_disc {
          margin-left: 0.3rem;
          width: calc(100% - 4rem);
          .text_title {
            font-size: 0.95rem;
            color: #333333;
            line-height: 1.2rem;
            margin: 0;
            text-indent: 0.4rem;
            overflow: hidden;    
            text-overflow:ellipsis;    
            white-space: nowrap;
          }
          .price_disc {
            text-align: right;
            .text_1 {
              font-size: 0.9rem;
            }
            .text_2 {
              font-size: 0.8rem;
            }
          }
        }
        .c_tag {
          margin-top: 0.3rem;
          color: #ff7f58;
          padding: 0.1rem 0.3rem;
        }
      }
    }

    .order_price_content {
      padding: 0rem 1rem 0;
      .price_text_area {
        padding-top: 1rem;
        border-top: 1px solid #f2f2f2;
        border-bottom: 1px solid #f2f2f2;
        .price_text {
          font-size: 0.95rem;
          color: #333;
          display: flex;
          justify-content: flex-end;
          &:last-child {
            margin-bottom: 1rem;
          }
          &:not(:first-child) {
            margin-top: 0.5rem;
          }
        }
      }
    }
  }
  
  .pay_page_conent {
    background: #fff;
    margin: 1rem auto;
    border-radius: 0.4rem;
    width: 22rem;
    .pay_info {
      padding: 1rem;
      .text {
        color: #909399;
        font-size: 0.9rem;
        line-height: 1.5rem;
      }
    }
  }

  .order_bottom_wrap_group {
    position: fixed;
    height: 2.75rem;
    width: 100%;
    bottom: 0;
    background: #ffffff;
    z-index: 1;
  }

  .sharing_guidelines {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background: #000;
    z-index: 2000;
    .content_i {
      position: absolute;
      top: 20%;
      left: 50%;
      transform: translate(-50%,-20%);
      width: 14rem;
      height: 5rem;
      border-radius: 3rem;
      border: 1px dashed #EBEEF5;
      display: flex;
      align-items: center;
      justify-content: center;
      .content_i_i {
        width: 13rem;
        height: 4rem;
        border-radius: 2.5rem;
        border: 1px solid #EBEEF5;
        display: flex;
        align-items: center;
        justify-content: center;
        .content_i_i_i {
          font-size: 1.3rem;
          color: #ffffff;
        }
      }
      .circle_1 {
        width: 2rem;
        height: 1rem;
        border-radius: 50%;
        border: 1px dashed #EBEEF5;
        position: absolute;
        top: -2rem;
        right: -1rem;
      }
      .circle_2 {
        width: 1.5rem;
        height: 0.5rem;
        border-radius: 50%;
        border: 1px dashed #EBEEF5;
        position: absolute;
        top: -3.5rem;
        right: -1.6rem;
      }
      .circle_3 {
        width: 1.2rem;
        height: 0.5rem;
        border-radius: 50%;
        border: 1px dashed #EBEEF5;
        position: absolute;
        top: -5rem;
        right: -2.2rem;
      }
    }
  }
}
</style>