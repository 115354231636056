<template>
  <div class="gamePage" id="videoDiv">
    <div class="head">
      <van-swipe
        class="my-swipe"
        :show-indicators="false"
        @change="onChange"
        ref="checkbox"
        :width="width"
        :autoplay="5000"
      >
        <van-swipe-item v-for="item in videoList" :key="item.url">
          <div class="medium" @click="swipeClick(item)"></div>
          <div>
            <img :src="item.imageUrl" alt="" />
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="tabs">
      <van-tabs v-model="active">
        <van-tab title="新游推荐">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <div class="content">
              <div class="card" v-for="item in list" :key="item.url">
                <div class="sign">{{item.tag}}</div>
                <div class="top">
                  <div class="gmImg">
                    <img :src="item.icon" alt="" />
                  </div>
                  <div class="txt">
                    <div>{{ item.title }}</div>
                    <div>{{item.tag}}</div>
                    <div>{{ item.remark }}</div>
                  </div>
                  <div class="btn">
                    <van-button
                      round
                      type="primary"
                      size="small"
                      @click="btnClick(item)"
                      >立即去玩</van-button
                    >
                  </div>
                </div>
                <div class="down">
                  <van-icon name="fire-o" color="#2da132" /><span>{{item.tag}}</span>
                </div>
              </div>
            </div>
          </van-list>
        </van-tab>
        <van-tab disabled title="">内容 1</van-tab>
        <van-tab disabled title="">内容 1</van-tab>
        <van-tab disabled title="">内容 1</van-tab>
      </van-tabs>
    </div>
  </div>
</template>
<script>
import * as api from "../lib/gamePage.js";
import { videoPlayer } from "vue-video-player";
import "video.js/dist/video-js.css";
import "vue-video-player/src/custom-theme.css";
export default {
  name: "gamePage",
  props: ["v_WeiXin"],
  components: {
    videoPlayer,
  },
  data() {
    return {
      active: 0,
      list: [
        // {
        //   id: 1,
        //   url: require("../assets/tanwanlanyue.png"),
        //   webUrl:
        //     "https://h5-sdk.43wan.com/#/index?game_id=3093&channel=1006237",
        //   name: "蓝月返利版",
        //   num: "44.3",
        // },
        // {
        //   id: 2,
        //   url: require("../assets/chuanqi.jpg"),
        //   webUrl:
        //     "https://h5-sdk.43wan.com/#/index?game_id=3109&channel=1007228",
        //   name: "传奇世界",
        //   num: "39.8",
        // },
      ],
      loading: false,
      finished: true,
      width: 320,
      swipeIndex: "0",
      imgUrl: "",
      videoList: [
        // {
        //   id: 1,
        //   videoType: "GIF",
        //   url: "https://vod.huiyu.org.cn/image/default/A43913812AB84B14AF23888AB10E8233-6-2.gif",
        //   webUrl:
        //     "https://h5-sdk.43wan.com/#/index?game_id=3093&channel=1006237",
        // },
        // {
        //   id: 2,
        //   videoType: "IMG",
        //   url: "https://img01.yzcdn.cn/vant/apple-1.jpg",
        //   webUrl:
        //     "https://h5-sdk.43wan.com/#/index?game_id=3093&channel=1006237",
        // },
      ],
      nowVideoList: [],
      timer: null,
      timer1: null,
      current: 0,
    };
  },
  mounted() {
    let ele = document.getElementById("videoDiv");
    this.width = ele.clientWidth * 0.97;
    document.title = "游戏中心";
    this.onChange(0);
    this.getList();
  },
  methods: {
    onChange(index) {
      this.swipeIndex = index;
    },
    onLoad() {},
    // 轮播图点击
    swipeClick(val) {
      let data = {
        client: "game",
        content: val.url,
      };
      api.eventUpload(data).then((res) => {
        if (res.result === 200) {
          window.location.href = val.url;
        }
      });
    },
    // 按钮点击
    btnClick(val) {
      let data = {
        client: "game",
        content: val.url,
      };
      api.eventUpload(data).then((res) => {
        if (res.result === 200) {
          window.location.href = val.url;
        }
      });
    },
    // 获取数据
    getList() {
      let data = {
        code: "GAME_CONFIG",
      };
      api.loadConfig(data).then((res) => {
        if (res.result === 200) {
          this.videoList = res.data.banner;
          this.list = res.data.list;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.gamePage {
  position: relative;
  width: 100%;
  // height: 100%;
  background-color: #f7f7f7;
  .head {
    // padding: 1rem 1rem 0 1rem;
    position: relative;
    /deep/.custom-indicator {
      position: absolute;
      right: 5px;
      bottom: 5px;
      padding: 2px 5px;
      font-size: 12px;
      background: rgba(0, 0, 0, 0.1);
    }
    .medium {
      position: absolute;
      width: 90%;
      height: 13rem;
      // background-color: green;
      z-index: 99;
    }
    .my-swipe .van-swipe-item {
      color: #fff;
      font-size: 20px;
      line-height: 150px;
      height: 13rem;
      div {
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
          // border-radius: 0.5rem;
        }
      }
      /deep/.video-js {
        border-radius: 1rem;
      }
      /deep/ .video-js .vjs-tech {
        border-radius: 0.5rem;
      }
      /deep/ .video-js .vjs-control-bar {
        display: none;
      }
      /deep/ .video-js .vjs-big-play-button {
        display: none;
      }
      /deep/.video-js.vjs-ended .vjs-big-play-button {
        display: none !important ;
      }
    }
    .video_class {
      display: block;
    }
  }
  .tabs {
    /deep/.van-tabs__line {
      background-color: #2da132;
      width: 30px !important ;
      height: 3px;
    }
    /deep/.van-tab--active {
      color: #2da132;
    }
    /deep/.van-tabs__nav {
      background-color: #f7f7f7;
    }
    .content {
      padding: 0 1rem;
    }
    .card {
      height: 7rem;
      background-color: #fff;
      border-radius: 0.5rem;
      margin-top: 1rem;
      padding: 1rem;
      position: relative;
      .sign {
        width: 3rem;
        font-size: 0.8rem;
        margin-top: 0.5rem;
        background-color: #60b5c3;
        color: #fff;
        position: absolute;
        left: 0rem;
        top: -0.5rem;
        border-top-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
      }
      .top {
        display: flex;
        .gmImg {
          width: 5rem;
          height: 5rem;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .txt {
          margin-left: 1rem;
          div:nth-child(1) {
            font-size: 1rem;
            font-weight: 700;
          }
          div:nth-child(2) {
            width: 3rem;
            font-size: 0.8rem;
            margin-top: 0.5rem;
            background-color: #f8e9e9;
            color: #cd8a7b;
            border-radius: 0.3rem;
          }
          div:nth-child(3) {
            margin-top: 0.5rem;
            margin-left: 0.1rem;
            font-size: 0.8rem;
            color: #808080;
          }
        }
        .btn {
          margin-left: 4rem;
          margin-top: 1rem;
        }
      }
      .down {
        position: absolute;
        bottom: 0.3rem;
        left: -9rem;
        width: 100%;
        font-size: 0.8rem;
        span {
          display: inline-block;
          color: #808080;
          margin-left: 0.5rem;
          position: absolute;
          bottom: 0.1rem;
        }
      }
    }
  }
}
</style>