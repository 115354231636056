<template>
  <div class="coupon">
    <div class="contentBgi">
      <div class="txt">

      </div>
      <div class="nav">
        <span class="full">{{ fullValue / 100 }}</span>
        <span class="reduction">{{ reductionValue / 100 }}</span>
        <span class="expireTime">{{ expireTime }}</span>
      </div>
      <div class="content">
        <div class="gaoguang">
          <img src="../assets/gaoguang.png" alt="" />
        </div>
        <div class="contentProduct">
          <div
            class="product"
            @click="goHomePage(item)"
            v-for="item in productList"
            :key="item.title"
          >
            <div class="iconUrl">
              <img :src="item.iconUrl" alt="" />
            </div>
            <div class="title">{{ item.title }}</div>
            <div class="payment">
              券后价: <span>¥{{ item.payment / 100 }}</span>
            </div>
            <div class="bot">
              <img src="../assets/shop.png" alt="" />
            </div>
            <div class="buy">立即加购</div>
          </div>
        </div>
      </div>
    </div>
    <van-dialog v-model="show" title="标题" show-cancel-button>
      <img src="https://img01.yzcdn.cn/vant/apple-3.jpg" />
    </van-dialog>
  </div>
</template>
<script>
import * as api from "../lib/coupon.js";
import { weChantAuth, weChatLogin, userCheckLogin } from "../lib/myClockIn.js";
import { Dialog } from "vant";

export default {
  name: "coupon",
  props: ["v_WeiXin"],
  data() {
    return {
      fullValue: "",
      reductionValue: "",
      expireTime: "",
      productList: [],
      couponId: "",
      appId: "",
      show: false,
    };
  },
  mounted() {
    let url = location.href.split("?")[1].split("&");
    let couponId = url[0].split("=")[1];
    let appId = url[1].split("=")[1];
    // console.log(couponId,appId)
    this.appId = appId;
    this.couponId = couponId;
    // this.getCoupon();
    this.code = this.$route.query.code;
    if (this.code) {
      this.weChatLogin();
    } else {
      this.userCheckLogin();
    }
  },
  methods: {
    // 微信授权
    weChantAuth() {
      let data = {
        redirectUri: window.location.href,
        appId: this.appId,
      };
      weChantAuth(data).then((res) => {
        if (res.result === 200) {
          window.location.href = res.data;
        }
      });
    },
    // 微信用户登陆
    weChatLogin() {
      let data = {
        code: this.code,
        appId: this.appId,
      };
      weChatLogin(data).then((res) => {
        if (res.result === 200) {
          this.getCoupon();
        }
      });
    },
    // 检查用户是否登陆
    userCheckLogin() {
      userCheckLogin({
        appId: this.appId,
      }).then((res) => {
        if (res.result === 200) {
          if (res.data.userSessionInfo !== null) {
            this.getCoupon();
          } else {
            if (this.v_WeiXin) {
              // 微信授权
              this.weChantAuth();
            }
          }
        }
      });
    },
    getCoupon() {
      let data = {
        couponId: this.couponId,
      };
      api
        .distribute(data)
        .then((res) => {
          if (res.result === 200) {
            this.getCouponViewInfo();
          }
        })
        .catch((res) => {
          if (res.result !== 200) {
            Dialog.alert({
              message: "优惠券活动已过期",
            }).then(() => {
              this.$router.go(-1);
            });
          }
        });
    },
    getCouponViewInfo() {
      let data = {
        couponId: this.couponId,
        // couponId: 10,
        appId: this.appId,
      };
      api
        .couponView(data)
        .then((res) => {
          if (res.result === 200) {
            this.fullValue = res.data.fullValue;
            this.reductionValue = res.data.reductionValue;
            this.expireTime = res.data.expireTime;
            this.productList = res.data.productViewInfoList;
          }
        })
        .catch((res) => {
          if (res.result !== 200) {
            Dialog.alert({
              message: "优惠券已过期",
            }).then(() => {
              this.$router.go(-1);
            });
          }
        });
    },
    goHomePage(val) {
      window.location.href = val.url;
    },
  },
};
</script>
<style lang="scss" scoped>
.coupon {
  // width: 100%;
  height: 100%;
  // background-color: #c82828;
  .contentBgi {
    width: 100%;
    // height: 37.7rem;
    height: 100%;
    background: url("../assets/couponBgi.jpeg") no-repeat;
    background-size: 100% 100%;
    position: relative;
    .txt{
      width:20rem;
      height: 30rem;
      position: absolute;
      background: url("../assets/conponTxt.png") no-repeat;
      background-size: 100% 100%;
      left: 50%;
      transform: translate(-50%);
      top: 1.5rem;
    }
    .nav {
      position: absolute;
      width: 18rem;
      height: 6rem;
      background: url("../assets/couponNav.png") no-repeat;
      background-size: 100% 100%;
      top: 10rem;
      // top: 27%;
      left: 50%;
      transform: translate(-50%);
      .full {
        position: absolute;
        top: 0.8rem;
        left: 9.6rem;
        color: #fff;
        font-family: FZCYJ;
        font-size: 1.5rem;
      }
      .reduction {
        position: absolute;
        top: 0.8rem;
        left: 13rem;
        color: #fff;
        font-family: FZCYJ;
        font-size: 1.5rem;
      }
      .expireTime {
        position: absolute;
        top: 3rem;
        left: 13rem;
        font-size: 0.7rem;
        color: #fff;
      }
    }
    .content {
      position: absolute;
      width: 17rem;
      // height: 13rem;
      background-color: #db4641;
      top: 16.5rem;
      left: 50%;
      transform: translate(-50%);
      border-radius: 0.5rem;
      box-shadow: 0.5rem -0.5rem 2rem #b92120;
      box-sizing: border-box;
      .gaoguang {
        width: 100%;
        height: 1rem;
        margin-top: -0.2rem;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .contentProduct {
        // position: absolute;
        padding: 0 0.8rem;
        .product {
          width: 100%;
          height: 4.5rem;
          background-color: #e56361;
          border-radius: 0.5rem;
          margin-bottom: 0.8rem;
          padding: 0.4rem;
          box-sizing: border-box;
          .iconUrl {
            position: absolute;
            width: 4rem;
            height: 3.7rem;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .title {
            position: absolute;
            font-size: 1.1rem;
            // color: rgb(55, 203, 123);
            color: #eaac33;
            font-family: FZCYJ;
            left: 6.5rem;
            text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
          }
          .payment {
            position: absolute;
            left: 7rem;
            font-size: 0.6rem;
            margin-top: 1.4rem;
            font-family: FZCYJ;
            color: #fff;
            span {
              font-size: 0.9rem;
            }
          }
          .bot {
            position: absolute;
            left: 7rem;
            img {
              width: 2rem;
              height: 2rem;
              margin-top: 2.2rem;
            }
          }
          .buy {
            position: absolute;
            left: 9.2rem;
            margin-top: 2.6rem;
            background-color: #fdf747;
            width: 6rem;
            font-size: 0.8rem;
            border-radius: 1rem;
          }
        }
      }
    }
  }
}
</style>

