<template>
  <van-list
    v-model="loading"
    :finished="finished"
    finished-text="没有更多了"
    @load="onLoad"
  >
    <div class="withDraw">
      <div class="content" v-for="(item, index) in drawList" :key="index">
        <div class="status">
          <span v-if="item.status == 'SENDING'" type="warning">发放中</span>
          <span v-if="item.status == 'FAILED'" type="warning">失败</span>
          <span v-if="item.status == 'REFUND'" type="warning">已退款</span>
          <span v-if="item.status == 'RECEIVED'" type="warning">已领取</span>
          <span v-if="item.status == 'WAITING_AUDIT'" type="warning"
            >待审核</span
          >
          <span v-if="item.status == 'SENT'" type="warning">待领取</span>
          <span v-if="item.status == 'WAITING'" type="warning">等待发放</span>
          <span v-if="item.status == 'RFUND_ING'" type="warning">退款中</span>
          <span v-if="item.status == 'AUDIT_NO_PASS'" type="warning">不通过</span>
        </div>
        <div class="time">{{ item.gmtCreated }}</div>
        <div class="money">¥{{ (item.money / 100).toFixed(2) }}</div>
      </div>
    </div>
  </van-list>
</template>
<script>
import * as api from "../lib/withDraw.js";

export default {
  name: "withDraw",
  components: {},
  data() {
    return {
      drawList: [],
      loading: false,
      finished: false,
    };
  },
  mounted() {
    document.title = "提现记录";
    this.getWithDrawList();
  },
  methods: {
    onLoad() {
      // 异步更新数据
      let data = {
        pageNo: this.pageNo,
        pageSize: 5,
        status: this.status,
      };
      this.getWithDrawList();
    },
    getWithDrawList() {
      api.withDrawList().then((res) => {
        this.loading = false;
        if (res.result === 200) {
          this.drawList = res.data;
          this.finished = true;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.withDraw {
  text-align: left;
  padding: 1rem;
  .content {
    position: relative;
    margin-bottom: 0.5rem;
    height: 2.5rem;
    width: 100%;
    border-bottom: 0.1rem dashed grey;
    .money {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      font-size: 1rem;
      font-weight: 700;
    }
    .status {
      position: absolute;
      font-weight: 500;
      font-size: 0.9rem;
      //   color: blue;
    }
    .time {
      position: absolute;
      font-size: 0.7rem;
      bottom: 0.2rem;
    }
  }
}
</style>