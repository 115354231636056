<template>
  <div class="courseCollection">
    <van-swipe
      class="courseCollection_header"
      :autoplay="3000"
      indicator-color="white"
      :height="152"
    >
      <van-swipe-item v-for="(item, index) in headImg" :key="index">
        <img v-lazy="item.navigateUrl" />
      </van-swipe-item>
    </van-swipe>
    <div class="courseCollection_content">
      <div
        v-for="(item, index) in cardList"
        :key="index"
        class="card"
        @click="clickCourse(item)"
      >
        <img :src="item.image" alt="" />
        <div class="caption">
          <p class="label">{{ item.title }}</p>
          <p class="description">{{ item.content }}</p>
          <p class="tag-group">
            <span class="tag" v-for="(menu, k) in item.labelList" :key="k">{{
              menu
            }}</span>
          </p>
          <p class="tag-group-2">
            <span class="price">￥{{ item.price }}</span>
            <span class="pay-s">限时抢购</span>
          </p>
        </div>
      </div>
    </div>
    <div class="more-info">更多团购活动，敬请期待</div>
  </div>
</template>
<script>
import * as api from "../lib/courseCollection.js";
export default {
  name: "courseCollection",
  data() {
    return {
      headImg: [],
      cardList: [],
      navigationId: "",
      userId: "",
    };
  },
  mounted() {
    this.navigationId = this.$route.query.navigationId;
    this.userId = this.$route.query.userId;
    this.$common.setCookie("home_url", window.location.href, 1);
    this.getNavigateProductRelationList();
  },
  methods: {
    // 根据导航查询商品
    getNavigateProductRelationList() {
      let data = {
        navigateId: this.navigationId,
        userId: this.userId,
      };
      api.navigateProductRelationList(data).then((res) => {
        if (res.result === 200) {
          let list = res.data.data;
          list.forEach((item) => {
            item.labelList = item.label.split(",");
          });
          this.cardList = list;
        }
      });
      api.naviagateUrlList(data).then((res) => {
        if (res.result === 200) {
          let list = res.data;
          this.headImg = list;
        }
      });
    },
    // 点击课程
    clickCourse(val) {
      let data = {
        userId: this.userId,
        productId: val.id,
        navigationId: this.navigationId,
      };
      api.queryProductLinks(data).then((res) => {
        if (res.result === 200) {
          let url = res.data;
          let firstIndex = url.lastIndexOf("/");
          let lastIndex = url.indexOf("?");
          if (firstIndex !== -1 && lastIndex !== -1) {
            let str = url.substring(firstIndex + 1, lastIndex);
            if (str === "homePage") {
              url = url + "&homeBtn=true";
            }
          }
          window.location = url;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.courseCollection {
  height: 100%;
  overflow: auto;
  text-align: left;
  background: #fff;
  font-family: sans-serif;
  img {
    border: 0;
    display: block;
  }
  .courseCollection_header {
    img {
      width: 100%;
    }
  }
  .courseCollection_content {
    padding: 0 1.25rem 1.25rem;
    .card {
      margin-top: 1.25rem;
      width: 100%;
      border-radius: 0.6rem;
      overflow: hidden;
      box-shadow: 0 0.026667rem 0.8rem 0 rgba(0, 0, 0, 0.1);
      img {
        width: 100%;
      }
      .caption {
        width: 100%;
        padding: 0.625rem 0.95rem;
        box-sizing: border-box;
        .label {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
          font-size: 1rem;
          color: #333;
          font-weight: 700;
          line-height: 1.17rem;
          margin-top: 0.03rem;
        }
        .description {
          font-size: 0.75rem;
          color: #999;
          line-height: 0.8rem;
          font-weight: 400;
          margin-top: 0.4rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .tag-group {
          margin-top: 0.625rem;
          margin-bottom: 0.312rem;
          font-size: 0;
          .tag {
            box-sizing: border-box;
            display: inline-block;
            position: relative;
            min-width: 2.49rem;
            height: 1.0625rem;
            line-height: 1.0625rem;
            font-size: 0.687rem;
            text-align: center;
            color: #e6a23c;
            margin-right: 0.624rem;
            padding: 0 0.406rem;
            &:after {
              content: "";
              display: block;
              position: absolute;
              top: -50%;
              left: -50%;
              width: 200%;
              height: 200%;
              border-radius: 0.5rem;
              background-color: rgba(250, 173, 20, 0.2);
              transform: scale(0.5);
            }
          }
          .price {
            float: right;
            color: #e5392c;
            font-size: 0.875rem;
            font-weight: 700;
            line-height: 1.0625rem;
          }
        }
        .tag-group-2 {
          margin-top: 0.625rem;
          margin-bottom: 0.312rem;
          font-size: 0;
          text-align: right;
          .price-orl {
            color: #646566;
            font-size: 0.875rem;
            font-weight: 700;
            line-height: 1.0625rem;
            text-decoration: line-through;
          }
          .price {
            color: #e5392c;
            font-size: 0.875rem;
            font-weight: 700;
            line-height: 1.0625rem;
            padding: 0 5px;
          }
          .pay-s {
            color: #e5392c;
            font-size: 0.875rem;
            line-height: 1.0625rem;
            border: 1px solid #e5392c;
          }
        }
        .btn-c {
          margin-top: 0.625rem;
          margin-bottom: 0.312rem;
          font-size: 0;
          .c-title {
            color: #303133;
            font-size: 14px;
            text-align: center;
            background-image: linear-gradient(90deg, #ffc53d, #faad14);
            line-height: 28px;
            width: 80%;
            border-radius: 20px;
            margin: 0 auto;
          }
        }
      }
    }
  }
  .more-info {
    height: 2.0625rem;
    font-size: 0.75rem;
    color: #999;
    line-height: 0.75rem;
    text-align: center;
  }
}
</style>