<template>
  <div class="orderDetails">
    
  </div>
</template>
<script>
export default {
  name: "orderDetails",
  data() {
    return {
      
    }
  },
  mounted() {

  },
  methods: {
    
  },
}
</script>
<style lang="scss" scoped>
  
</style>