<template>
  <div class="myClockIn">
    <div class="nav"></div>
    <!-- <button @click="go">123</button> -->
    <div class="content">
      <div v-for="item in allClockList" :key="item.courseId">
        <div class="course" @click="goClickIn(item)">
          <!-- <img :src="src" alt="" /> -->
          <img :src="item.courseCoverUrl" alt="" />
          <span class="title">{{ item.courseTitle }}</span>
          <van-tag
            plain
            type="warning"
            class="tag"
            v-if="item.courseStatus == 'WATCHED_PARTIAL'"
            >打卡</van-tag
          >
          <van-tag
            plain
            type="danger"
            class="tag"
            v-if="item.courseStatus == 'EXPIRED'"
            >已过期</van-tag
          >
          <van-tag
            plain
            type="warning"
            class="tag"
            v-if="item.courseStatus == 'NOT_WATCH'"
            >未打卡</van-tag
          >
          <van-tag
            plain
            type="warning"
            class="tag"
            v-if="item.courseStatus == 'WATCHED_ALL'"
            >打卡完成</van-tag
          >
          <!-- <span
            class="click"
            v-if="
              item.courseStatus == 'WATCHED_PARTIAL' ||
              item.courseStatus == 'NOT_WATCH'
            "
            @click="goClickIn(item)"
            >去打卡></span
          >
          <span
            class="click"
            v-if="item.courseStatus == 'WATCHED_ALL'"
            @click="goClickIn(item)"
            >已完成打卡></span
          >
          <span
            class="click"
            v-if="item.courseStatus == 'EXPIRED'"
            @click="goClickIn(item)"
            >去观看></span
          > -->
          <span class="expireTime">打卡截止:{{ item.expireTime }}</span>
        </div>
        <van-divider />
      </div>
    </div>
  </div>
</template>
<script>
import * as api from "../lib/myClockIn.js";

export default {
  name: "myClockIn",
  props: ["v_WeiXin"],
  components: {},
  data() {
    return {
      appId: "",
      unLogin: false,
      // 课程数组
      allClockList: [],
      src: "https://vod.huiyu.org.cn/2ad53b4658924d85b421ead8448003f1/snapshots/0b188ce1dc9244b79a7cf43e1f57ffaa-00005.jpg",
    };
  },
  mounted() {
    document.title = "我的打卡课程";
    // this.getAllClock();
    let appId1 = location.href.split("&");
    let newAppId = "";
    for (var i = 0; i < appId1.length; i++) {
      if (appId1[i].indexOf("appid") != -1) {
        newAppId = appId1[i].split("=")[1];
      }
    }
    this.appId = newAppId;
    this.code = this.$route.query.code;
    if (this.code) {
      this.weChatLogin();
    } else {
      this.userCheckLogin();
    }
  },
  methods: {
    // 微信授权
    weChantAuth() {
      // let appId1 = location.href.split("?");
      // let newAppId = "";
      // for (var i = 0; i < appId1.length; i++) {
      //   if (appId1[i].indexOf("appid") != -1) {
      //     newAppId = appId1[i].split("=")[1];
      //   }
      // }
      let data = {
        redirectUri: window.location.href,
        appId: this.appId,
      };
      api.weChantAuth(data).then((res) => {
        if (res.result === 200) {
          window.location.href = res.data;
        }
      });
    },
    // 微信用户登陆
    weChatLogin() {
      // let appId1 = location.href.split("&");
      // let newAppId = "";
      // for (var i = 0; i < appId1.length; i++) {
      //   if (appId1[i].indexOf("appid") != -1) {
      //     newAppId = appId1[i].split("=")[1];
      //   }
      // }
      let data = {
        code: this.code,
        appId: this.appId,
      };
      api.weChatLogin(data).then((res) => {
        if (res.result === 200) {
          this.userCheckLogin();
          this.getAllClock();
        }
      });
    },
    // 检查用户是否登陆
    userCheckLogin() {
      api
        .userCheckLogin({
          appId: this.appId,
        })
        .then((res) => {
          if (res.result === 200) {
            // this.unLogin = res.data.userSessionInfo ? false : true;
            if (res.data.userSessionInfo !== null) {
              this.getAllClock();
            } else {
              if (this.v_WeiXin) {
                // 微信授权
                this.weChantAuth();
              }
            }
            // 没有登录
            // if (this.unLogin) {
            //   if (this.v_WeiXin) {
            //     // 微信授权
            //     this.weChantAuth();
            //   }
            // } else {
            //     // this.weChantAuth();
            //     // this.weChatLogin()
            //   if (res.data.userSessionInfo.phone) {
            //     this.localNumberShow = !this.unLogin;
            //     this.telEncryptionValue_local = res.data.userSessionInfo.phone;
            //   }
            // }
          }
        });
    },
    //查询用户所有的打卡课程
    getAllClock() {
      api.allClock().then((res) => {
        if (res.result === 200) {
          this.allClockList = res.data;
        }
      });
    },
    // go(){
    //   window.location.href='http://mallnew.huiyu.org.cn/groupClockIn?courseId=24'
    // },
    goClickIn(val) {
      // this.$router.push({
      //   path: "/clockIn",
      //   query: {
      //     courseId: val.courseId,
      //   },
      // });
      window.location.href = val.courseIndexUrl;
    },
  },
};
</script>
<style lang="scss" scoped>
.myClockIn {
  text-align: left;
  background-color: #ffdd8b;
  padding-bottom: 1rem;

  .nav {
    width: 100%;
    height: 23.4rem;
    // background: url("https://image.huiyu.org.cn/20210406162301jmcBEr5sSVXv.png")
    background: url("../assets/myClockIn.png") no-repeat;
    background-size: 100% 100%;
  }
  .content {
    // background-color: red;
    padding: 0.5rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    margin-top: -1rem;
    border-radius: 1rem;
    background-color: #fff;
    .van-divider {
      margin-bottom: 0;
    }
    .course {
      box-sizing: border-box;
      padding-top: 1rem;
      padding-left: 1rem;
      // margin-bottom: 1rem;
      // border-radius: 1rem;
      // border-top-left-radius: 1rem;
      // border-top-right-radius: 1rem;
      position: relative;
      width: 100%;
      height: 6.5rem;
      // background-color: green;
      background-color: #fff;

      img {
        position: absolute;
        width: 8rem;
        height: 6rem;
        border-radius: 1rem;
      }
      .title {
        width: 10rem;
        position: absolute;
        font-size: 1rem;
        left: 10rem;
        top: 1.5rem;
        font-weight: 400;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .tag {
        position: absolute;
        left: 10rem;
        top: 3.3rem;
      }
      .click {
        color: skyblue;
        position: absolute;
        font-size: 1rem;
        left: 18rem;
        bottom: 0.5rem;
        font-family: "PingFang SC";
      }
      .expireTime {
        position: absolute;
        left: 10rem;
        top: 4.8rem;
        font-size: 0.8rem;
      }
    }
  }
}
</style>