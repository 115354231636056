<template>
  <div class="clockIn">
    <div class="head_img">
      <img :src="imgUrl" alt="" />
    </div>

    <div class="clock_in_date">
      <div
        v-for="(item, index) in courseList"
        :key="item.courseItemId"
        @click="getPlay(item)"
      >
        <h4 v-if="item.itemTitle">{{ item.itemTitle }}</h4>
        <h4 v-else>{{ "第" + (index + 1) + "天" }}</h4>
        <div class="video_box">
          <img :src="item.coverUrl" alt="" />
          <div class="z_z_c" v-if="item.itemStatus == 'NOT_PUNCH' || 'PUNCHED'">
            <img :src="require('../assets/bofang.png')" alt="" class="z_img" />
          </div>
          <div class="videoBox" v-if="item.canPlay == true">
            <video-player
              v-if="item.itemStatus == 'NOT_PUNCH' || 'PUNCHED'"
              class="video-player vjs-custom-skin"
              ref="videoPlayer"
              :playsinline="true"
              :options="playerOptions"
              @play="onPlayerPlay($event)"
              @pause="onPlayerPause($event)"
              @ended="onPlayerEnded($event)"
            >
            </video-player>
          </div>
          <div class="z_z_c z_color" v-if="item.itemStatus == 'LOCKED'">
            <img :src="require('../assets/suo.png')" alt="" class="z_img" />
          </div>
          <!-- <div class="z_z_c z_color" v-if="item.canUnLock == true"> -->
          <div class="z_z_c z_color" v-if="item.itemStatus == 'RELEASED'">
            <img
              :src="require('../assets/jiesuo_4.png')"
              alt=""
              class="z_img"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as api from "../lib/clockIn.js";
import { Dialog } from "vant";
import { Toast } from "vant";
import { videoPlayer } from "vue-video-player";
import "video.js/dist/video-js.css";
import "vue-video-player/src/custom-theme.css";
import { weChantAuth, weChatLogin, userCheckLogin } from "../lib/myClockIn.js";

export default {
  name: "clockIn",
  props: ["v_WeiXin"],
  components: {
    videoPlayer,
  },
  data() {
    return {
      unLogin: false,
      codeL: "",
      courseList: [],
      imgUrl: "",
      playVideoId: "",
      itemStatus: "",
      // yc视频插件数据
      playerOptions: {
        // playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: true, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4",
            src: "", //你的视频地址（必填）
          },
        ],
        poster: "", //你的封面地址
        // width: document.documentElement.clientWidth,
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true, //全屏按钮
        },
      },
      url: "",
      coverUrl: "",
      // yc视频插件数据end
      appId: "",
      historyId: "",
    };
  },
  mounted() {
    let appId1 = location.href.split("&");
    let newAppId = "";
    for (var i = 0; i < appId1.length; i++) {
      if (appId1[i].indexOf("appid") != -1) {
        newAppId = appId1[i].split("=")[1];
      }
    }
    this.appId = newAppId;
    this.code = this.$route.query.code;
    if (this.code) {
      this.weChatLogin();
    } else {
      this.userCheckLogin();
    }
  },
  methods: {
    // 微信授权
    weChantAuth() {
      let data = {
        redirectUri: window.location.href,
        appId: this.appId,
      };
      weChantAuth(data).then((res) => {
        if (res.result === 200) {
          window.location.href = res.data;
        }
      });
    },
    // 微信用户登陆
    weChatLogin() {
      let data = {
        code: this.code,
        appId: this.appId,
      };
      weChatLogin(data).then((res) => {
        if (res.result === 200) {
          this.userCheckLogin();
          this.getCourseView();
          this.getGroupSharePosterInfo();
        }
      });
    },
    // 检查用户是否登陆
    userCheckLogin() {
      userCheckLogin({
        appId: this.appId,
      }).then((res) => {
        if (res.result === 200) {
          // this.unLogin = res.data.userSessionInfo ? false : true;
          if (res.data.userSessionInfo !== null) {
            this.getCourseView();
            this.getGroupSharePosterInfo();
          } else {
            if (this.v_WeiXin) {
              // 微信授权
              this.weChantAuth();
            }
          }
        }
      });
    },
    // yc点击视频
    getPlay(item) {
      // 点击未解锁视频
      if (item.itemStatus == "LOCKED") {
        Toast("还不能观看此视频，请按顺序解锁观看");
      }
      // yc点击可解锁视频
      if (item.itemStatus == "RELEASED") {
        Toast("请于明日观看");
      } else if (item.itemStatus == "NOT_PUNCH" || "PUNCHED") {
        for (var i = 0; i < this.courseList.length; i++) {
          if (this.courseList[i].courseItemId !== item.courseItemId) {
            this.courseList[i].canPlay = false;
          }
        }
        // yc点击可播放视频
        let data = {
          videoId: item.videoId,
        };
        if (item.itemStatus == "NOT_PUNCH" || item.itemStatus == "PUNCHED") {
          api.getPlay(data).then((res) => {
            if (res.result === 200) {
              item.canPlay = true;
              this.playerOptions.sources[0].src = res.data.playUrl;
              // this.url = res.data.playUrl;
              this.playerOptions.poster = res.data.coverUrl;
              // this.coverUrl = res.data.coverUrl;
              this.playVideoId = item.courseItemId;
              this.itemStatus = item.itemStatus;
              let data = {
                courseItemId: this.playVideoId,
              };
              if (this.itemStatus == "NOT_PUNCH") {
                api.starVideo(data).then((res) => {
                  if (res.result === 200) {
                    this.historyId = res.data.historyId;
                  }
                });
              }
            }
          });
        }
      }
    },
    // video标签事件
    videoChange() {
      let videos = document.getElementsByTagName("video");
    },
    // 一个视频播放其它视频停止
    // pauseAll() {
    //   let videos = document.getElementsByTagName("video");
    //   for (let i = videos.length - 1; i >= 0; i--) {
    //     let p = i;
    //     videos[p].addEventListener("play", () => {
    //       for (let j = videos.length - 1; j >= 0; j--) {
    //         if (j != p) {
    //           videos[j].pause();
    //         }
    //       }
    //     });
    //   }
    // },
    // yc视频插件功能
    onPlayerPlay(player) {},
    onPlayerPause(player) {
      console.log("暂停1");
    },
    onPlayerEnded(player) {
      if (this.itemStatus == "NOT_PUNCH") {
        let data = {
          courseItemId: this.playVideoId,
          historyId: this.historyId,
          clockInCheck: true,
        };
        api.finishVideo(data).then((res) => {
          if (res.result === 200) {
            if (res.data.punchSuccess == true) {
              let data = {
                courseId: window.location.href.split("=")[1].split("&")[0],
              };
              api.getNextUnlockableItem(data).then((res) => {
                if (res.result === 200) {
                  if (res.data.courseItemId != null) {
                    // Dialog.confirm({
                    //   title: "解锁视频",
                    //   message: "确定解锁视频吗",
                    // })
                    //   .then(() => {
                    //     let data = {
                    //       courseItemId: res.data.courseItemId,
                    //     };
                    //     api.unLock(data).then((res) => {
                    //       if (res.result === 200) {
                    //         this.getCourseView();
                    //         Toast.success("解锁成功");
                    //       } else {
                    //         Toast.fail(res.message);
                    //       }
                    //     });
                    //   })
                    //   .catch(() => {
                    //     Toast.fail("已取消解锁");
                    //   });
                    Dialog.alert({
                      title: "恭喜您, 今日打卡成功！",
                      // message: "弹窗内容",
                    }).then(() => {
                      let data = {
                        courseItemId: res.data.courseItemId,
                      };
                      api.unLock(data).then((res) => {
                        if (res.result === 200) {
                          this.getCourseView();
                          Toast.success("解锁成功,请于明日观看");
                        } else {
                          Toast.fail(res.message);
                        }
                      });
                    });
                  }
                }
              });

              this.getCourseView();
            } else {
              Toast.fail("请认真观看完视频");
              this.getCourseView();
            }
          }
        });
      }
    },

    // 获取课程显示视图
    getCourseView() {
      let data = {
        courseId: window.location.href.split("=")[1].split("&")[0],
      };
      api.courseView(data).then((res) => {
        if (res.result === 200) {
          // 数据处理
          for (var i = 0; i < res.data.courseItemViewList.length; i++) {
            res.data.courseItemViewList[i].canUnLock = false;
            res.data.courseItemViewList[i].canPlay = false;
          }
          if (res.data.unlockableItemId != null) {
            let nArr = res.data.courseItemViewList.filter(
              (el) => el.courseItemId == res.data.unlockableItemId
            );
            nArr[0].canUnLock = true;
          }

          this.courseList = res.data.courseItemViewList;
          this.imgUrl = res.data.banner;
        }
      });
    },
    // 分享链接处理
    getGroupSharePosterInfo() {
      let data = {
        courseId: window.location.href.split("=")[1].split("&")[0],
      };
      api.courseView(data).then((res) => {
        if (res.result === 200) {
          // 分享
          let thisUrl = window.location.href;
          this.imgUrl = res.data.shareIcon;
          let params = {
            title: res.data.shareTitle,
            imgUrl: res.data.shareIcon,
            desc: res.data.shareContent,
          };
          this.$common.shareClick(thisUrl, params);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.clockIn {
  text-align: left;
  img {
    width: 100%;
  }
  .clock_in_date {
    padding: 0 1rem 1.5rem;
    h4 {
      margin: 0.8rem 0;
    }
    .video_box {
      position: relative;
      .z_z_c {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        .z_img {
          position: absolute;
          width: 5rem;
          height: 5rem;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .z_color {
        background: rgba($color: #c8c9cc, $alpha: 0.6);
      }
      // yc视频样式
      .videoBox {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 97.5%;
      }
      .video_class {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      /deep/ .video-js {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      /deep/ .video-js .vjs-big-play-button {
        // position: absolute;
        // width: 5rem;
        // height: 3.5rem;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
        height: 2em;
        width: 2em;
        line-height: 2em;
        border-radius: 1em;
        margin-left: -1em;
        font-size: 0;
      }
    }
  }
}
</style>