<template>
  <van-tabs v-model="active" @change="activeChange" sticky>
    <van-tab title="全部">
      <myRedInfo v-if="active === 0"></myRedInfo>
    </van-tab>
    <van-tab title="未领取">
      <myRedInfo v-if="active === 1" :status="status"></myRedInfo>
    </van-tab>
    <van-tab title="已领取">
      <myRedInfo v-if="active === 2" :status="status"></myRedInfo>
    </van-tab>
  </van-tabs>
</template>
<script>
import myRedInfo from './myRedInfo.vue';
export default {
  name: "myRed",
  components: {
    myRedInfo
  },
  data() {
    return {
      active: 0,
      status: "",
    };
  },
  mounted() {
    document.title = "我的红包";
  },
  methods: {
    activeChange(name, title) {
      switch (name) {
        case 0:
          this.status = "";
          break;
        case 1:
          this.status = "SENT";
          break;
        case 2:
          this.status = "RECEIVED";
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>

</style>