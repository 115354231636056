import http from "./axios";

// test
export const postData = ({ type, msg }) => {
  return http.json_post22("/thirdParty/advertisement/user/postCallBackData", {
    type: type,
    msg: msg,
  });
};

// 登录
export const userLogin = ({ userNo, timestamp, sign }) => {
  return http.json_post22("/thirdParty/advertisement/user/userLogin", {
    userNo: userNo,
    timestamp: timestamp,
    sign: sign,
  });
};

// 获取用户信息
export const getUserInfo = () => {
  return http.get22("/thirdParty/advertisement/user/getUserInfo");
};
