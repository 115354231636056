<template>
  <van-list
    v-model="loading"
    :finished="finished"
    finished-text="没有更多了"
    @load="onLoad"
  >
    <div class="orderList_item">
      <div v-for="(item, index) in orderInfoList" :key="index" class="card">
        <div class="cart_page_content_title">
          <div class="c_p_c_d">{{item.gmtCreated}}</div>
          <div class="c_p_c_s">
            <span v-for="i in orderStatusList" :key="i.value">
              <span v-if="item.status === i.value" :style="{color: i.color}">{{i.label}}</span>
            </span>
          </div>
        </div>
        <div v-for="(menu, key) in item.orderItemList" :key="key" class="c_K_c">
          <!-- <img 
            :src="menu.imageUrl" 
            alt="加载失败" 
            onerror="this.src='https://image.huiyu.org.cn/20210119113140VLQJ3dpk0S0M.jpg';this.οnerrοr=null"
            class="img_m"
          > -->
          <img 
            :src="menu.imageUrl" 
            alt="加载失败" 
            class="img_m"
          >
          <div class="c_title">
            <span>{{menu.skuName}}</span>
          </div>
          <div class="c_price">
            <div><span style="font-size: 0.75rem">¥ </span>{{menu.showPrice}}</div>
            <div class="c_s">x 1</div>
          </div>
        </div>
        <div class="pay_c_c">
          <span>实付款<span style="font-size: 0.75rem">¥ </span>{{item.payment}}</span>
        </div>
      </div>
    </div>
  </van-list>
</template>
<script>
import * as api from "../lib/orderList.js";
export default {
  name: "orderInfo",
  props: {
    status: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      orderInfoList: [],
      loading: false,
      finished: false,
      pageNo: 1,
      total: 0,
      orderStatusList: [
        {
          label: "待支付",
          value: "INIT",
          color: "#E6A23C",
        },
        {
          label: "支付中",
          value: "PAYING",
          color: "#E6A23C",
        },
        {
          label: "支付成功",
          value: "PAY_SUCCESS",
          color: "#67C23A",
        },
        {
          label: "支付失败",
          value: "PAY_FAILED",
          color: "#F56C6C"
        },
        // {
        //   label: "成功",
        //   value: "SUCCESS"
        // },
        {
          label: "退款中",
          value: "REFUNDING",
          color: "#E6A23C",
        },
        {
          label: "部分退款",
          value: "REFUND_PARTIAL",
          color: "#67C23A",
        },
        {
          label: "全部退款",
          value: "REFUND_ALL",
          color: "#67C23A",
        },
        {
          value: "JOINING_GROUP",
          label: "拼团中",
          color: "#E6A23C"
        },
        {
          value: "JOIN_GROUP_SUCCESS",
          label: "参团成功",
          color: "#67C23A"
        },
        {
          value: "WAIT_REFUND",
          label: "等待退款",
          color: "#E6A23C"
        },
      ]
    };
  },
  mounted() {
    let data = {
      pageNo: this.pageNo,
      pageSize: 5,
      status: this.status
    };
    this.getOrderList(data);
  },
  methods: {
    onLoad() {
      // 异步更新数据
      let data = {
        pageNo: this.pageNo,
        pageSize: 5,
        status: this.status
      };
      this.getOrderList(data);
    },
    getOrderList(data) {
      api.orderList(data).then((res) => {
        // 加载状态结束
        this.loading = false;
        if (res.result === 200) {
          this.pageNo++;
          let list = res.data.records;
          list.forEach(item => {
            item.gmtCreated = this.$common.transformTime(item.gmtCreated);
            item.orderItemList.forEach(menu => {
              menu.showPrice = menu.showPrice / 100;
            })
            item.payment = item.payment / 100;
            this.orderInfoList.push(item);
          });
          this.total = res.data.total;
          // 数据全部加载完成
          if (this.orderInfoList.length >= this.total) {
            this.finished = true;
          }
        }
      }).catch(err => {
        // 加载状态结束
        this.loading = false;
        this.finished = true;
      })
    },
  },
};
</script>
<style lang="scss" scoped>
.orderList_item {
  // padding: 0 1.25rem 1.25rem;
  .card {
    margin-top: 1rem;
    width: 100%;
    min-height: 12rem;
    // border-radius: 0.6rem;
    overflow: hidden;
    box-shadow: 0 0.026667rem 0.8rem 0 rgba(0, 0, 0, 0.1);
    font-size: 0.9rem;
    .cart_page_content_title {
      text-align: left;
      padding: 0.5rem 1rem;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #E4E7ED;
      .c_p_c_d {
        line-height: 1.5rem;
        font-size: 0.9rem;
        color: #333;
      }
      .c_p_c_s {
        font-size: 0.9rem;
        line-height: 1.5rem;
        color: #ff6034;
      }
    }
    .c_K_c {
      display: flex;
      justify-content: space-between;
      padding: 0.5rem 1rem;
      flex-direction: row;
      align-items: flex-start;
      margin-bottom: 1.3rem;
      .img_m {
        width: 5rem;
        height: 5rem;
        display: block;
        flex-grow: 0;
        flex-shrink: 0;
        background: #E4E7ED;
      }
      .c_title {
        font-size: 0.9rem;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        display: -webkit-box; 
        -webkit-box-orient: vertical; 
        -webkit-line-clamp: 2; 
        text-align: left;
        padding: 0 0.5rem;
      }
      .c_price {
        flex-grow: 0;
        flex-shrink: 0;
        text-align: right;
        .c_s {
          font-size: 0.8rem;
          color: #909399;
        }
      }
    }
    .pay_c_c {
      padding: 0 1rem;
      padding-top: 0.3rem;
      text-align: right;
      border-top: 1px solid #E4E7ED;
    }
  }
}
</style>