import http from "./axios";

// 点击游戏
export const eventUpload = ({ client, content }) => {
  return http.getTj("/eventUpload", {
    client: client,
    content: content,
  });
};

// 页面数据获取
export const loadConfig = ({ code }) => {
  return http.getTj("/loadConfig", {
    code: code,
  });
};
// GAME_CONFIG
