<template>
  <div class="activitiesVideo" id="videoDiv">
    <video
      id="video"
      :src="url"
      controls
      preload
      :poster="coverUrl"
      :width="width"
      webkit-playsinline
      x-webkit-airplay
      playsinline
      x5-playsinline
      class="video_class"
    >
      您的浏览器不支持 video 标签。
    </video>
    <div class="img_list">
      <div v-for="(item, index) in imgList" :key="index">
        <img :src="item" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "activitiesVideo2",
  data() {
    return {
      width: 320,
      url: "",
      coverUrl: "",
      imgList: [
        require("../assets/1.png"),
        require("../assets/1-4.png"),
        require("../assets/6.png"),
      ],
    };
  },
  mounted() {
    document.title = "活动视频介绍";
    this.videoId = this.$route.query.videoId;
    let ele = document.getElementById("videoDiv");
    this.width = ele.clientWidth;
    this.getVideoSrc();
  },
  methods: {
    // 获取视频
    getVideoSrc() {
      let data = {
        videoId: this.videoId,
      };
      this.$http
        .get("/manager/getVodVideoLocation", data)
        .then((res) => {
          if (res.result === 200) {
            this.isImgShow = true;
            this.url = res.data.playUrl;
            this.coverUrl = res.data.coverUrl;
          }
        })
        .catch((err) => {});
    },
  },
};
</script>
<style lang="scss" scoped>
.activitiesVideo {
  position: relative;
  width: 100%;
  height: 100%;
  .video_class {
    display: block;
  }
  .img_list {
    img {
      width: 100%;
      display: block;
    }
  }
}
</style>
