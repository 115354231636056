import http from "./axios";

// 获取用户地址列表
export const addressList = () => {
  return http.get("/address/list");
};

// 更新订单收货地址信息
export const updateAddress = ({ orderId, receiverAddressId }) => {
  return http.json_post("/order/updateAddress", {
    orderId: orderId,
    receiverAddressId: receiverAddressId,
  });
};
