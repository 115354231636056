import http from "./axios";

// 查询红包列表
export const redList = ({ status }) => {
  return http.get("/account/redEnvelope/accountRedEnvelopeList", {
    status: status,
  });
};

// 红包领取
export const getRed = ({ id }) => {
  return http.get("/account/redEnvelope/receiveAccountRedEnvelope", {
    id: id,
  });
};
