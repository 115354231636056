<template>
  <div class="home_page_style">
    <div class="home_img_style">
      <div v-for="(item, index) in gradeList" :key="index">
        <img v-if="item.materialType === 'IMAGE_TYPE'" v-lazy="item.imageUrl" />
        <video
          v-if="item.materialType === 'VIDEO_TYPE'"
          :src="item.videoPlayUrl"
          controls
          preload
          :poster="item.videoCoverUrl"
          webkit-playsinline
          x-webkit-airplay
          playsinline
          x5-playsinline
          class="video_class"
        >
          您的浏览器不支持 video 标签。
        </video>
      </div>
    </div>
  </div>
</template>
<script>
import * as api from "../lib/homePage.js";
export default {
  name: "activityIntroduce",
  props: ["v_WeiXin"],
  data() {
    return {
      activityPageId: "",
      policyUrl: "",
      gradeList: [],
      activityName: "",
    };
  },
  mounted() {
    this.activityPageId = this.$route.query.activityPageId;
    this.getProductSubView();
    setTimeout(() => {
      this.pauseAll();
    }, 500);
  },
  methods: {
    // 查看商品图片
    getProductSubView() {
      let data = {
        activityPageId: this.activityPageId,
      };
      api.activityPageView(data).then((res) => {
        if (res.result === 200) {
          let list = res.data.materialViewList;
          this.activityName = res.data.activityName;
          this.setTitle();
          list.map((item) => {
            if (item.imageUrl) {
              item.imageUrl = item.imageUrl.replace("https", "https");
            }
          });
          if (list && list.length > 0) {
            this.gradeList = list;
          }
        }
      });
    },
    // 设置title
    setTitle() {
      document.title = this.activityName;
      let iframe = document.createElement("iframe");
      iframe.src = require("../../public/wxsc.png");
      iframe.style.display = "none";
      let fn = function () {
        setTimeout(function () {
          iframe.removeEventListener("load", fn);
          document.body.removeChild(iframe);
        }, 0);
      };
      iframe.addEventListener("load", fn);
      document.body.appendChild(iframe);
    },
    // 一个视频播放其它视频停止
    pauseAll() {
      let videos = document.getElementsByTagName("video");
      for (let i = videos.length - 1; i >= 0; i--) {
        let p = i;
        videos[p].addEventListener("play", () => {
          for (let j = videos.length - 1; j >= 0; j--) {
            if (j != p) {
              videos[j].pause();
            }
          }
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.home_page_style {
  width: 100%;
  // height: 100%;
  font-size: 0rem;
  background-color: #fff;
  // padding-bottom: 2.3rem;
  .home_img_style {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-x: hidden;
    overflow-y: auto;
    // background-color: #fff4d4;
    img {
      width: 100%;
      height: auto;
      display: block;
    }
    .video_class {
      display: block;
      width: 100%;
    }
  }
}
</style>

