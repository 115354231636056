<template>
  <div class="addressEdit">
    <van-address-edit
      ref="vanAddressEdit"
      :area-list="areaList"
      :address-info="addressInfo"
      show-delete
      show-set-default
      show-search-result
      :search-result="searchResult"
      :area-columns-placeholder="['请选择', '请选择', '请选择']"
      @save="onSave"
      @delete="onDelete"
      @change-detail="onChangeDetail"
    />
  </div>
</template>
<script>
import * as api from "../lib/addressEdit.js";
import areaList from "@/lib/area.js";
import { userCheckLogin } from "../lib/cartPage.js";
import { Picker } from 'vant';
export default {
  name: "addressEdit",
  data() {
    return {
      areaList,
      searchResult: [],
      addressInfo: {
        addressDetail: "",
        area: "",
        areaCode: "",
        city: "",
        county: "",
        id: "",
        isDefault: false,
        name: "",
        province: "",
        tel: ""
      },
      isAdd: false,
    };
  },
  mounted() {
    document.title = "地址编辑";
    let list = this.$route.query.list;
    this.isAdd = true;
    if (list) {
      this.addressInfo = list;
      this.isAdd = false;
    }
    this.getUserCheckLogin();
  },
  methods: {
    // 检查用户是否登陆
    getUserCheckLogin() {
      userCheckLogin().then(res => {
        if (res.result === 200) {
          if (res.data.userSessionInfo) {
            if (this.isAdd) {
              this.addressInfo.tel = res.data.userSessionInfo.phone;
            }
          }
        }
      })
    },
    onSave(val) {
      let data = {
        receiverName: val.name,
        receiverPhone: val.tel,
        province: val.province,
        city: val.city,
        area: val.county,
        addressDetail: val.addressDetail,
        areaCode: val.areaCode,
        defaultAddress: val.isDefault // 是否是设为默认地址
      };
      if (!this.isAdd) {
        data.addressId = val.id;
        api.addressModity(data).then(res => {
          if (res.result === 200) {
            this.$toast("修改成功");
            this.$router.back(-1);
          }
        });
      } else {
        api.addressAdd(data).then(res => {
          if (res.result === 200) {
            this.$toast("新增成功");
            this.$router.back(-1);
          }
        });
      }
    },
    onDelete(val) {
      if (val.id) { 
        let data = {
          addressId: val.id
        }
        api.addressDelete(data).then(res => {
          if (res.result === 200) {
            this.$toast("删除成功");
            this.$router.back(-1);
          }
        });
      } else {
        this.$router.back(-1);
      }
    },
    onChangeDetail(val) {
      if (val) {
        this.searchResult = [
          // {
          //   name: "黄龙万科中心",
          //   address: "杭州市西湖区"
          // }
        ];
      } else {
        this.searchResult = [];
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.addressEdit {
  .van-address-edit {
    padding: 0.8rem;
    /deep/.van-address-edit__fields {
      border-radius: 0.5rem;
      .van-field__error-message {
        font-size: 0.8rem;
      }
    }
  }
  /deep/.van-address-edit__default {
    margin-top: 0.8rem;
    border-radius: 0.5rem;
    .van-switch {
      font-size: 1.5rem !important;
      width: 3rem;
      height: 1.5rem;
    }
  }
  /deep/.van-address-edit-detail {
    padding: 0 !important;
    .van-field {
      .van-field__control {
        height: 1.5rem !important;
      }
    }
  }
  /deep/.van-cell__title {
    text-align: left;
  }
  /deep/.van-address-edit__buttons {
    padding: 2rem 0.25rem;
    .van-button--round {
      border-radius: 62rem;
    }
    .van-button--normal {
      padding: 0 1rem;
      font-size: 0.95rem;
    }
  }
  /deep/.van-address-edit__buttons {
    .van-button {
      margin-bottom: 0.8rem;
      line-height: 2.65rem;
      height: 2.65rem;
    }
  }
  /deep/.van-field__right-icon {
    margin-right: -0.5rem;
    padding: 0 0.5rem;
    .van-icon-arrow {
      min-width: 1rem;
      font-size: 1rem;
    }
  }
}
</style>

