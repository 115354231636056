<template>
  <div>
    <div class="zyb-overlay" v-show="leaveShow"></div>
    <div
      class="zyb-overlay-temp"
      v-show="overlayShow"
      @click="overlayClose"
      :class="{ 'zyb-coupon-leave-active': activeClass }"
    >
      <div class="general-popup-container zyb-coupon-dialog">
        <div class="general-popup-image">
          <img :src="url" alt="" class="zyb-coupon-dialog-pic" @click="imgClick"/>
        </div>
        <div class="zyb-coupon-dialog-footer"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "overlay",
  props: {
    url: {
      // 图片地址
      type: String,
      default: ""
    },
    visibleImg: {
      // 图片显示
      type: Boolean,
      default: false
    },
    visibleOverlay: {
      // 遮罩层显示
      type: Boolean,
      default: false
    },
  },
  watch: {
    visibleImg(val) {
      if (val) {
        this.overlayShow = val;
        this.leaveShow = val;
      }
    },
    visibleOverlay(val) {
      this.leaveShow = val;
      if (val) {
        this.activeClass = false;
      }
    },
  },
  data() {
    return {
      overlayShow: false,
      leaveShow: false,
      activeClass: false
    };
  },
  mounted() {},
  methods: {
    overlayClose() {
      this.leaveShow = false;
      this.activeClass = true;
      setTimeout(() => {
        this.overlayShow = false;
      }, 500);
      this.$emit("close", false);
    },
    imgClick() {
      this.$emit("imgClick");
    }
  },
};
</script>
<style lang="scss" scoped>
.zyb-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1000;
}
.zyb-overlay-temp {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2000;
  transition: transform 0.5s;
  .zyb-coupon-dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 0.2rem;
    opacity: 1;
    text-align: center;
    z-index: 2001;
    .general-popup-image {
      position: relative;
      .zyb-coupon-dialog-pic {
        width: 17.5rem;
        display: block;
        pointer-events: auto;
      }
    }
    .zyb-coupon-dialog-footer {
      display: inline-block;
      margin-top: 1.2rem;
      width: 2rem;
      height: 2rem;
      background: url("../assets/close.png") no-repeat;
      background-size: 100%;
    }
  }
}
.zyb-coupon-leave-active {
  opacity: 1;
  transform: translate(-57.29166666666666px, 83.16666666666669px) scale(0.01);
}
</style>