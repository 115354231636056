import http from "./axios";

// 查询订单列表
export const orderList = ({ pageNo, pageSize, status }) => {
  return http.get("/order/list", {
    pageNo: pageNo,
    pageSize: pageSize,
    status: status,
  });
};
