<template>
  <div class="writingClass">
    <div class="content" id="scrollable">
      <section class="container">
        <!-- 毛笔 -->
        <div class="brush none">
          <div class="brush-container"></div>
        </div>
        <!-- 硬笔 -->
        <div class="write">
          <!-- 首屏 -->
          <div class="info-box">
            <img class="width-100" v-lazy="firstScreenList[0]" />
            <img class="width-100" v-lazy="firstScreenList[1]" />
          </div>
          <!-- 视频 -->
          <div class="info-box">
            <img class="width-100 lazyload" v-lazy="firstScreenList[2]" />
            <video
              v-show="!videoImgShow"
              controls
              preload="none"
              class="video vimg1"
              controlslist="nodownload"
              x5-playsinline="true"
              webkit-playsinline="true"
              playsinline="true"
              x-webkit-airplay="true"
              ref="writeVideo"
            >
              <source
                src="http://v.hexiaoxiang.com/9d9333e5fe994b9fa03f77dc457e1c50/08583f9dfbc5407296c0b72588d53711-5287d2089db37e62345123a1be272f8b.mp4"
                type="video/mp4"
              />
            </video>
            <img
              v-show="videoImgShow"
              class="video vimg2"
              v-lazy="firstScreenList[3]"
              @click="playVideo"
            />
          </div>
          <div class="info-box product">
            <div class="info-box change pro">
              <img class="width-100 lazyload" v-lazy="firstScreenList[4]" />
              <van-swipe
                class="my-swipe"
                style="width: 100%; height: 72%"
                :autoplay="3000"
                indicator-color="white"
              >
                <van-swipe-item v-for="(item, i) in swipeProList" :key="i">
                  <div class="m-swipe-item">
                    <img v-lazy="item.img" />
                    <div class="pro_detail">
                      <div class="stu">
                        <span class="name">{{ item.name }}</span>
                        <span class="grade">{{ item.grade }}</span>
                      </div>
                      <div class="pro_pic">
                        <img
                          v-for="(menu, j) in item.proPic"
                          :key="j"
                          v-lazy="menu"
                          alt=""
                        />
                      </div>
                    </div>
                    <div class="proverb">{{ item.proverb }}</div>
                  </div>
                </van-swipe-item>
              </van-swipe>
            </div>
          </div>
          <img class="width-100 lazyload" v-lazy="firstScreenList[5]" />
          <img class="width-100 lazyload" v-lazy="firstScreenList[6]" />
          <div class="teacherList">
            <img
              class="width-100 lazyload"
              src="https://xcx.alicdn2.hexiaoxiang.com/activity/hexx_xiezi/img/sign_tab.png"
            />
            <div class="teacherTab">
              <van-tabs type="card">
                <van-tab
                  v-for="(item, i) in teacherTabList"
                  :key="i"
                  :title="item.title"
                >
                  <img class="lazyload" v-lazy="item.img" />
                </van-tab>
              </van-tabs>
            </div>
          </div>
          <img class="width-100 lazyload" v-lazy="firstScreenList[7]" />
          <img class="width-100 lazyload" v-lazy="firstScreenList[8]" />
          <img class="width-100 lazyload" v-lazy="firstScreenList[9]" />
          <img class="width-100 lazyload" v-lazy="firstScreenList[10]" />
          <img class="width-100 lazyload" v-lazy="firstScreenList[11]" />
          <img class="width-100 lazyload" v-lazy="firstScreenList[12]" />
          <div class="info-box change">
            <img class="width-100 lazyload" v-lazy="firstScreenList[13]" />
            <van-swipe
              class="my-swipe"
              style="width: 100%; height: 75%"
              :autoplay="30000"
              indicator-color="white"
            >
              <van-swipe-item v-for="(item, i) in swipeBoxList" :key="i">
                <div class="s_box">
                  <div class="detail">
                    <div>
                      <img v-lazy="item.headImageUrl" alt="" />
                    </div>
                    <div class="stu">
                      <div class="stu_detail">
                        <span class="name">{{ item.name }}</span>
                        <span class="grade">{{ item.grade }}</span>
                        <span class="days">{{ item.days }}</span>
                      </div>
                      <div class="voice">
                        <img v-lazy="item.icon" alt="" />
                        <span class="sec">{{ item.voiceSec }}</span>
                        <span>”</span>
                      </div>
                    </div>
                  </div>
                  <div class="pic">
                    <div class="bef" v-for="(menu, j) in item.picList" :key="j">
                      <img v-lazy="menu.img" alt="" />
                      <p>{{ item.title }}</p>
                    </div>
                  </div>
                </div>
              </van-swipe-item>
            </van-swipe>
          </div>
          <img class="width-100 lazyload" v-lazy="firstScreenList[14]" />
          <img class="width-100 lazyload" v-lazy="firstScreenList[15]" />
          <img class="width-100 lazyload" v-lazy="firstScreenList[16]" />
          <div class="company">
            <img class="width-100 lazyload" v-lazy="firstScreenList[17]" />
            <div class="referId"></div>
          </div>
        </div>
      </section>
    </div>
    <div class="bottom-btn-wrap-one">
      <div class="bottom-btn">
        <a href="javascript:void(0)" class="button-img-wrap" @click="buyNow"
          >立即报名</a
        >
      </div>
    </div>
    <!-- 课程选择 -->
    <van-action-sheet v-model="sheet_show" title="请选择孩子年级">
      <p class="chooseClass_detail">
        请根据孩子当前实际年级填写，我们将为孩子定制最合适的课程
      </p>
      <ul class="sheet_content">
        <li
          v-for="(item, index) in sheet_list"
          :key="index"
          class="sheet_content_item"
          :class="{ sheet_content_item_active: item.isActive }"
        >
          <div class="content_title" @click="gradeClick(item)">
            {{ item.skuName }}
          </div>
        </li>
      </ul>
      <div class="bottom-btn-wrap-one">
        <div class="bottom-btn">
          <a
            href="javascript:void(0)"
            class="button-img-wrap"
            @click="gradeClickTo"
            >立即报名</a
          >
        </div>
      </div>
    </van-action-sheet>
  </div>
</template>
<script>
import * as api from "../lib/homePage.js";
export default {
  name: "writingClass",
  props: ["v_WeiXin"],
  data() {
    return {
      itemData: {},
      entranceId: "",
      productId: "",
      isLogin: "",
      isWeiXin: false,
      lastRunTime: null,
      sheet_show: false,
      sheet_list: [],
      videoImgShow: true,
      firstScreenList: [
        "https://xcx.alicdn2.hexiaoxiang.com/activity/hexx_xiezi/img/write_head.png",
        "https://xcx.alicdn2.hexiaoxiang.com/activity/hexx_xiezi/img/write_skill.png",
        "https://xcx.alicdn2.hexiaoxiang.com/activity/hexx_xiezi/img/write_video_bg.png",
        "https://xcx.alicdn2.hexiaoxiang.com/activity/hexx_xiezi/img/video_bg.png",
        "https://xcx.alicdn2.hexiaoxiang.com/activity/hexx_xiezi/img/write_product_bg.png",
        "https://xcx.alicdn2.hexiaoxiang.com/activity/hexx_xiezi/img/write_teacher.png",
        "https://xcx.alicdn2.hexiaoxiang.com/activity/hexx_xiezi/img/write_system_list.png",
        "https://xcx.alicdn2.hexiaoxiang.com/activity/hexx_xiezi/img/write_online.png",
        "https://xcx.alicdn2.hexiaoxiang.com/activity/hexx_xiezi/img/write_encourage.png",
        "https://xcx.alicdn2.hexiaoxiang.com/activity/hexx_xiezi/img/write_one_to_one.png",
        "https://xcx.alicdn2.hexiaoxiang.com/activity/hexx_xiezi/img/write_wrong.png",
        "https://xcx.alicdn2.hexiaoxiang.com/activity/hexx_xiezi/img/write_wx_24.png",
        "https://xcx.alicdn2.hexiaoxiang.com/activity/hexx_xiezi/img/write_change.png",
        "https://xcx.alicdn2.hexiaoxiang.com/activity/hexx_xiezi/img/stu_evaluate.png",
        "https://xcx.alicdn2.hexiaoxiang.com/activity/hexx_xiezi/img/write_feedback_1.png",
        "https://xcx.alicdn2.hexiaoxiang.com/activity/hexx_xiezi/img/write_feedback_2.png",
        "https://xcx.alicdn2.hexiaoxiang.com/activity/hexx_xiezi/img/write_feedback_3.png",
        "https://xcx.alicdn2.hexiaoxiang.com/activity/hexx_xiezi/img/write_foot.jpg",
      ],
      swipeProList: [
        {
          img:
            "https://xcx.alicdn2.hexiaoxiang.com/activity/hexx_xiezi/img/stu_product_bg.png",
          name: "媛媛",
          grade: "三年级",
          proPic: [
            "https://xcx.alicdn2.hexiaoxiang.com/activity/hexx_xiezi/img/yy_product_1.jpg",
            "https://xcx.alicdn2.hexiaoxiang.com/activity/hexx_xiezi/img/yy_product_2.jpg",
          ],
          proverb: "文则数言乃成其意，书则一字已见其心",
        },
        {
          img:
            "https://xcx.alicdn2.hexiaoxiang.com/activity/hexx_xiezi/img/stu_product_bg.png",
          name: "张书豪",
          grade: "一年级",
          proPic: [
            "https://xcx.alicdn2.hexiaoxiang.com/activity/hexx_xiezi/img/zsh_product_1.jpg",
            "https://xcx.alicdn2.hexiaoxiang.com/activity/hexx_xiezi/img/zsh_product_2.jpg",
          ],
          proverb: "习字认真切勿止，提笔即是练字时",
        },
        {
          img:
            "https://xcx.alicdn2.hexiaoxiang.com/activity/hexx_xiezi/img/stu_product_bg.png",
          name: "叶冬妹",
          grade: "三年级",
          proPic: [
            "https://xcx.alicdn2.hexiaoxiang.com/activity/hexx_xiezi/img/ydm_product_1.jpg",
            "https://xcx.alicdn2.hexiaoxiang.com/activity/hexx_xiezi/img/ydm_product_2.jpg",
          ],
          proverb: "锲而不舍，金石可镂，练字亦如此",
        },
        {
          img:
            "https://xcx.alicdn2.hexiaoxiang.com/activity/hexx_xiezi/img/stu_product_bg.png",
          name: "丰湘宜",
          grade: "四年级",
          proPic: [
            "https://xcx.alicdn2.hexiaoxiang.com/activity/hexx_xiezi/img/fxy_product_1.jpg",
            "https://xcx.alicdn2.hexiaoxiang.com/activity/hexx_xiezi/img/fxy_product_2.jpg",
          ],
          proverb: "眉头无一事，笔下有千年",
        },
      ],
      teacherTabList: [
        {
          title: "海象老师",
          img:
            "https://xcx.alicdn2.hexiaoxiang.com/activity/hexx_xiezi/img/teacher_tab_1.png",
        },
        {
          title: "河马老师",
          img:
            "https://xcx.alicdn2.hexiaoxiang.com/activity/hexx_xiezi/img/teacher_tab_2.png",
        },
        {
          title: "飞象老师",
          img:
            "https://xcx.alicdn2.hexiaoxiang.com/activity/hexx_xiezi/img/teacher_tab_3.png",
        },
        {
          title: "栀子老师",
          img:
            "https://xcx.alicdn2.hexiaoxiang.com/activity/hexx_xiezi/img/teacher_tab_4.png",
        },
      ],
      swipeBoxList: [
        {
          headImageUrl:
            "https://xcx.alicdn2.hexiaoxiang.com/activity/hexx_xiezi/img/stu_avatar_1.png",
          name: "吉永光希",
          grade: "大班",
          days: "坚持练字90天",
          icon:
            "https://xcx.alicdn2.hexiaoxiang.com/activity/hexx_xiezi/img/voice.png",
          voiceSec: 20,
          picList: [
            {
              img:
                "https://xcx.alicdn2.hexiaoxiang.com/activity/hexx_xiezi/img/stu1_evaluate_before.jpg",
              title: "练习前",
            },
            {
              img:
                "https://xcx.alicdn2.hexiaoxiang.com/activity/hexx_xiezi/img/stu1_evaluate_practice.jpg",
              title: "第20天",
            },
            {
              img:
                "https://xcx.alicdn2.hexiaoxiang.com/activity/hexx_xiezi/img/stu1_evaluate_now.jpg",
              title: "第40天",
            },
          ],
        },
        {
          headImageUrl:
            "https://xcx.alicdn2.hexiaoxiang.com/activity/hexx_xiezi/img/stu_avatar_2.png",
          name: "刘青怡",
          grade: "大班",
          days: "坚持练字134天",
          icon:
            "https://xcx.alicdn2.hexiaoxiang.com/activity/hexx_xiezi/img/voice.png",
          voiceSec: 18,
          picList: [
            {
              img:
                "https://xcx.alicdn2.hexiaoxiang.com/activity/hexx_xiezi/img/stu2_evaluate_before.jpg",
              title: "练习前",
            },
            {
              img:
                "https://xcx.alicdn2.hexiaoxiang.com/activity/hexx_xiezi/img/stu2_evaluate_practice.jpg",
              title: "第36天",
            },
            {
              img:
                "https://xcx.alicdn2.hexiaoxiang.com/activity/hexx_xiezi/img/stu2_evaluate_now.jpg",
              title: "第62天",
            },
          ],
        },
        {
          headImageUrl:
            "https://xcx.alicdn2.hexiaoxiang.com/activity/hexx_xiezi/img/stu_avatar_3.png",
          name: "闫龙斌",
          grade: "一年级",
          days: "坚持练字220天",
          icon:
            "https://xcx.alicdn2.hexiaoxiang.com/activity/hexx_xiezi/img/voice.png",
          voiceSec: 18,
          picList: [
            {
              img:
                "https://xcx.alicdn2.hexiaoxiang.com/activity/hexx_xiezi/img/stu3_evaluate_before.jpg",
              title: "练习前",
            },
            {
              img:
                "https://xcx.alicdn2.hexiaoxiang.com/activity/hexx_xiezi/img/stu3_evaluate_practice.jpg",
              title: "第20天",
            },
            {
              img:
                "https://xcx.alicdn2.hexiaoxiang.com/activity/hexx_xiezi/img/stu3_evaluate_now.jpg",
              title: "第30天",
            },
          ],
        },
      ],
    };
  },
  mounted() {
    document.title = "课程介绍";
    this.$common.setCookie("info_data", "");
    this.productId = this.$route.query.productId;
    this.entranceId = this.$route.query.entranceId;
    this.isWeiXin = this.v_WeiXin;
    this.getProductView({ productId: this.productId });
    this.$refs.writeVideo.addEventListener("play", this.handlePlay);
    this.$refs.writeVideo.addEventListener("pause", this.handlePause);
  },
  methods: {
    // 查询课程信息
    getProductView(data) {
      api.productView(data).then((res) => {
        if (res.result === 200) {
          let skuInfoList = res.data.skuInfoList;
          skuInfoList.forEach((item) => {
            item.isActive = false;
          });
          this.sheet_list = skuInfoList;
        }
      });
    },
    // 点击播放视频
    playVideo() {
      this.handlePlay();
    },
    // 点击播放视频
    handlePlay() {
      this.$refs.writeVideo.play();
      this.videoImgShow = false;
    },
    // 点击播放视频
    handlePause() {
      this.$refs.writeVideo.pause();
      this.videoImgShow = true;
    },
    // 点击课程
    gradeClick(val) {
      this.sheet_list.forEach((item) => {
        item.isActive = false;
        if (item.skuId === val.skuId) {
          item.isActive = true;
        }
      });
      this.itemData = val;
    },
    // 跳转课程
    gradeClickTo() {
      if (!this.itemData.skuId) {
        this.$toast("请选择年级");
        return;
      }
      if (this.itemData.disabeld) {
        return;
      }
      let currentTime = Date.now(),
        protectTime = 1000;
      if (this.lastRunTime) {
        if (currentTime - this.lastRunTime < protectTime) {
          this.lastRunTime = currentTime;
          return; //两次执行太过频繁，直接退出
        }
      }
      this.lastRunTime = currentTime;
      // 
      let appId = location.href.split("&");
      let newAppId = "";
      for (var i = 0; i < appId.length; i++) {
        if (appId[i].indexOf("appid") != -1) {
          newAppId = appId[i].split("=")[1];
        }
      }
      // console.log(newAppId)
      this.$router.push({
        path: "/cartPage",
        query: {
          productId: this.productId,
          skuId: this.itemData.skuId,
          entranceId: this.entranceId,
          appId:newAppId
        },
      });
    },
    // 点击立即购买
    buyNow() {
      this.sheet_show = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.btn_login_style {
  font-size: 0.95rem;
  width: 100%;
  .btn_style {
    padding: 0.5rem 1rem;
    .button_a {
      display: block;
      border-radius: 0.4rem;
      line-height: 2.6rem;
      background-color: #ffd01e;
      color: #303133;
    }
  }
  &::before {
    content: "";
    display: block;
    height: 5rem;
  }
}
.writingClass {
  width: 100%;
  font-size: 0rem;
  background: #ffffff;
  img {
    width: 100%;
    height: auto;
  }
  .content {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 3.5rem;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    background-color: #fff;
    .write {
      width: 100%;
      .info-box {
        position: relative;
        padding-bottom: 1.8rem;
        .video {
          position: absolute;
          left: 5%;
          top: 22%;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          width: 90%;
        }
        .my-swipe {
          position: absolute;
          top: 18%;
          left: 50%;
          transform: translateX(-50%);
          /deep/.van-swipe-item {
            color: #fff;
            font-size: 1rem;
            text-align: center;
            .m-swipe-item {
              left: 50%;
              transform: translateX(-50%);
              position: absolute;
              width: 78%;
              margin: 0 auto;
            }
            .pro_detail {
              position: absolute;
              top: 0;
              width: 100%;
              padding: 1rem 0.9rem;
              box-sizing: border-box;
              .stu {
                display: flex;
                justify-content: space-between;
                .name {
                  font-size: 1.2rem;
                  margin-right: 0.8rem;
                  font-family: PingFang-SC-Medium;
                  font-weight: bold;
                  color: rgba(68, 61, 55, 1);
                  line-height: 1.4rem;
                }
                .grade {
                  position: relative;
                  bottom: -0.05rem;
                  font-size: 0.95rem;
                  font-family: PingFang-SC-Medium;
                  font-weight: 500;
                  color: rgba(68, 61, 55, 1);
                  line-height: 1.4rem;
                }
              }
              .pro_pic {
                display: flex;
                padding-top: 0.8rem;
                justify-content: space-between;
                img {
                  width: 7.8rem;
                  height: 10.5rem;
                  background: rgba(238, 238, 238, 1);
                }
              }
            }
            .proverb {
              position: absolute;
              bottom: 0.8rem;
              width: 100%;
              font-family: KaiTi;
              font-weight: 400;
              color: rgba(95, 88, 82, 1);
            }
            .s_box {
              background: #fff;
              width: 85%;
              height: 85%;
              padding: 1rem 1rem 0;
              margin: 0 auto;
              -webkit-box-sizing: border-box;
              box-sizing: border-box;
              box-shadow: 0.08rem 0.16rem 0.3rem 0rem rgba(200, 198, 191, 0.3);
              border-radius: 1rem;
              .detail {
                display: flex;
                align-items: center;
                padding-bottom: 1rem;
                img {
                  width: 3.125rem;
                  height: 3.125rem;
                  margin-right: 1rem;
                  border-radius: 50%;
                }
                .stu {
                  flex: 1;
                  text-align: left;
                  .stu_detail {
                    text-align: left;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: flex-end;
                    -ms-flex-align: flex-end;
                    align-items: flex-end;
                    .name {
                      font-size: 1rem;
                      margin-right: 1rem;
                      font-family: PingFang-SC-Medium;
                      font-weight: bold;
                      color: rgba(68, 61, 55, 1);
                      line-height: 1.5rem;
                    }
                    .grade {
                      position: relative;
                      bottom: -0.1rem;
                      flex: 1;
                      font-size: 0.8rem;
                      font-family: PingFang-SC-Medium;
                      font-weight: 500;
                      color: rgba(68, 61, 55, 1);
                      line-height: 1.5rem;
                    }
                    .days {
                      position: relative;
                      bottom: -0.1rem;
                      font-size: 0.8rem;
                      font-family: PingFang-SC-Medium;
                      font-weight: 500;
                      color: rgba(136, 136, 136, 1);
                      line-height: 1.5rem;
                    }
                  }
                  .voice {
                    width: 5rem;
                    height: 1.3rem;
                    margin-top: 0.1rem;
                    padding-left: 0.5rem;
                    background: rgba(254, 150, 15, 1);
                    box-shadow: 0.03rem 0.1rem 0.2rem 0 rgba(210, 148, 49, 0.3);
                    border-radius: 0.3rem;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    img {
                      display: inline-block;
                      width: 0.6rem;
                      height: 0.7rem;
                      margin-right: 0.1rem;
                    }
                    span {
                      display: inline-block;
                      height: 1.3rem;
                      font-size: 0.8rem;
                      font-family: SourceHanSansCN-Light;
                      color: rgba(255, 255, 255, 1);
                      line-height: 1.3rem;
                    }
                  }
                }
              }
              .pic {
                text-align: center;
                display: -webkit-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                -webkit-align-items: center;
                align-items: center;
                -ms-flex-pack: justify;
                justify-content: space-between;
                .bef {
                  width: 32%;
                }
                img {
                  width: 100%;
                  // height: 7.5rem;
                  background: rgba(238, 238, 238, 1);
                }
                p {
                  font-size: 0.95rem;
                  font-family: PingFang-SC-Medium;
                  font-weight: 500;
                  color: rgba(68, 61, 55, 1);
                  line-height: 1rem;
                  padding: 0;
                  margin: 0;
                }
              }
            }
          }
          /deep/.van-swipe__indicators {
            bottom: 0.8rem;
            .van-swipe__indicator {
              width: 0.6rem;
              height: 0.6rem;
              background-color: rgba(255, 156, 49, 1);
            }
            .van-swipe__indicator--active {
              background-color: #ff9c31 !important;
            }
          }
        }
      }
      .teacherList {
        position: relative;
        padding-bottom: 4rem;
        .teacherTab {
          position: absolute;
          width: 100%;
          padding: 0 0.95rem;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          top: 9rem;
          /deep/.van-tabs__wrap {
            height: 1.7rem;
            .van-tabs__nav--card {
              margin: 0;
              border: 0;
              height: 1.8rem;
              .van-tab {
                border: 0;
                border-radius: 1rem 1rem 0 0;
                text-align: center;
                line-height: 1.8rem;
                cursor: pointer;
                width: 25%;
                height: 1.8rem;
                background: rgba(255, 247, 244, 1);
                color: rgba(68, 61, 55, 1);
                box-shadow: 2px 0px 2px 0px rgba(231, 215, 207, 0.3);
                font-size: 0.85rem;
                font-family: PingFang-SC-Bold;
              }
              .van-tab--active {
                background: rgba(255, 115, 51, 1);
                color: rgba(255, 255, 255, 1);
              }
            }
          }
        }
      }
      .company {
        .referId {
          width: 100%;
          text-align: center;
          color: rgba(136, 136, 136, 0);
          font-size: 0.2667rem;
          height: 2rem;
          line-height: 0.5rem;
          background: rgb(253, 247, 225);
        }
      }
    }
  }
  .chooseClass_detail {
    font-size: 0.95rem;
    font-family: PingFangSC-Regular, PingFangSC;
    font-weight: 400;
    text-align: center;
    color: rgba(45, 46, 63, 1);
    line-height: 1rem;
    padding: 0 0.8rem;
    margin: 0;
  }
  .sheet_content {
    padding: 0.8rem;
    padding-bottom: 3.5rem;
    max-height: 22rem;
    min-height: 10rem;
    overflow-y: auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    box-sizing: border-box;

    .sheet_content_item {
      display: inline-block;
      text-align: left;
      width: 23%;
      font-size: 0.9rem;
      text-align: center;
      padding: 0.5rem;
      margin-bottom: 1rem;
      border-radius: 0.5rem;
      background-color: #fff6f3;
      color: #ff4c50;
    }
    .sheet_content_item_active {
      // border: 1px solid #FF4C50;
      color: #fff6f3;
      background-color: #ff4c50;
    }
  }
  .bottom-btn-wrap-one {
    position: fixed;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
    z-index: 999;
    bottom: 0;
    background: transparent;
    font-size: 0.95rem;
    width: 100%;
    .bottom-btn {
      padding: 0.5rem 1rem;
      background: rgba(255, 255, 255, 0.98);
      box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.08),
        0 -4px 10px 0 rgba(132, 132, 132, 0.05);
      display: flex;
      justify-content: space-between;
      .button-img-icon {
        font-size: 2.4rem;
        color: #ffd01e;
        width: 12%;
        padding-right: 0.8rem;
      }
      .button-img-wrap {
        border-radius: 2rem;
        line-height: 2.6rem;
        background-color: #fa4031;
        color: #fff;
        width: 100%;
      }
    }
  }
}
</style>

