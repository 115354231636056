<template>
  <div class="groupClockIn" ref="wrapper">
    <div class="head_img">
      <img :src="imgUrl" alt="" />
      <van-progress
        class="progress"
        :pivot-text="progress"
        color="#f2826a"
        :percentage="progressNum"
        stroke-width="6"
      />
      <van-tag type="success" round class="tag" v-if="hasClockInToday === true"
        >今日已打卡</van-tag
      >
      <van-tag type="warning" round class="tag" v-if="hasClockInToday === false"
        >今日未打卡</van-tag
      >
      <van-icon class="vanIco" name="question-o" />
      <span class="clockInRule" @click="clickInRule">打卡规则</span>
    </div>
    <van-dialog v-model="clockSuccess" title="">
      <img src="../assets/clockSuccess.png" />
    </van-dialog>
    <van-tabs @click="tabsClick" type="card" sticky v-model="activeName">
      <!-- <van-tabs @click="tabsClick"> -->
      <van-tab
        v-for="item in groupInfoList"
        :key="item.groupId"
        :title="item.groupTitle"
        :name="item.groupTitle"
      >
        <div class="clock_in_date">
          <van-list
            v-model="loading"
            :finished="finished"
            :immediate-check="false"
            finished-text="没有更多了"
            @load="onLoad"
            :offset="10"
          >
            <div
              @click="getPlay(item)"
              v-for="item in courseList"
              :key="item.courseItemId"
            >
              <h4 v-if="item.itemTitle">{{ item.itemTitle }}</h4>
              <van-tag
                type="success"
                class="tag1"
                v-if="item.itemStatus === 'PUNCHED'"
                >已打卡</van-tag
              >
              <van-tag
                type="success"
                class="tag1"
                v-if="item.itemStatus === 'NOT_PUNCH' && item.watched === true"
                >已观看</van-tag
              >
              <van-tag
                type="warning"
                class="tag1"
                v-if="item.itemStatus === 'NOT_PUNCH' && item.watched === false"
                >未观看</van-tag
              >
              <div class="video_box">
                <img :src="item.coverUrl" alt="" />
                <div
                  class="z_z_c"
                  v-if="item.itemStatus == 'NOT_PUNCH' || 'PUNCHED'"
                >
                  <img
                    :src="require('../assets/bofang.png')"
                    alt=""
                    class="z_img"
                  />
                </div>
                <div class="videoBox" v-if="item.canPlay == true">
                  <video-player
                    v-if="item.itemStatus == 'NOT_PUNCH' || 'PUNCHED'"
                    class="video-player vjs-custom-skin"
                    ref="videoPlayer"
                    :playsinline="true"
                    :options="playerOptions"
                    @play="onPlayerPlay($event)"
                    @pause="onPlayerPause($event)"
                    @ended="onPlayerEnded($event)"
                    @timeupdate="onPlayerTimeupdate($event)"
                    @ready="playerReadied"
                  >
                  </video-player>
                </div>
              </div>
            </div>
          </van-list>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
import * as api from "../lib/groupClockIn.js";
import { Dialog } from "vant";
import { Notify } from "vant";
import { Toast } from "vant";
import { videoPlayer } from "vue-video-player";
import "video.js/dist/video-js.css";
import "vue-video-player/src/custom-theme.css";
import { weChantAuth, weChatLogin, userCheckLogin } from "../lib/myClockIn.js";

export default {
  name: "groupClockIn",
  props: ["v_WeiXin"],
  components: {
    videoPlayer,
  },
  data() {
    return {
      gklog: "", //视频播放时长
      historyId: "", //记录播放时长位置
      scrollY: 0,
      consoleTime: "",
      startTime: "",
      endTime: "",
      // tab页位置
      activeName: "",
      // -----------------
      clockSuccess: false,
      loading: false,
      finished: false,
      pageNo: 1, //请求第几页
      pageSize: 10, //每页请求的数量
      total: 0, //总共的数据条数
      // ----------打卡进度
      completeCount: 0,
      clockInDays: 0,
      hasClockInToday: false,
      progress: "",
      progressNum: 0,
      clockInRule: "",
      // ----------打卡进度end
      groupInfoList: [],
      groupId: "",
      unLogin: false,
      codeL: "",
      code: "",
      courseList: [],
      imgUrl: "",
      playVideoId: "",
      itemStatus: "",
      // yc视频插件数据
      playerOptions: {
        // playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: true, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4",
            src: "", //你的视频地址（必填）
          },
        ],
        poster: "", //你的封面地址
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: true, // 是否显示全屏按钮
        },
      },
      playtimes: 0, //视频观看起点
      url: "",
      coverUrl: "",
      // yc视频插件数据end
      // requestLogSummaryAsyn: null,
      // session定时器
      getSession: null,
      //
      historyTime: null,
      // 跳转定时器
      jump: null,
      stopStatus: false,
      appId: "",
      uuid: "",
      realTime: null,
      timer1: 0,
    };
  },
  mounted() {
    let appId1 = location.href.split("&");
    let newAppId = "";
    for (var i = 0; i < appId1.length; i++) {
      if (appId1[i].indexOf("appid") != -1) {
        newAppId = appId1[i].split("=")[1];
      }
    }
    this.appId = newAppId;
    this.code = this.$route.query.code;
    if (this.code) {
      this.weChatLogin();
    } else {
      this.userCheckLogin();
    }
    window.addEventListener("scroll", this.windowScroll);
    // this.tabsClick();
  },
  methods: {
    windowScroll() {
      // 滚动条距离页面顶部的距离
      // 以下写法原生兼容
      this.scrollY =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // console.log("Y", this.scrollY);
    },
    // 微信授权
    weChantAuth() {
      let data = {
        redirectUri: window.location.href,
        appId: this.appId,
      };
      weChantAuth(data).then((res) => {
        if (res.result === 200) {
          window.location.href = res.data;
        }
      });
    },
    // 微信用户登陆
    weChatLogin() {
      let data = {
        code: this.code,
        appId: this.appId,
      };
      weChatLogin(data).then((res) => {
        if (res.result === 200) {
          // this.userCheckLogin();
          this.getCourseGroupView();
        }
      });
    },
    // 检查用户是否登陆
    userCheckLogin() {
      userCheckLogin({
        appId: this.appId,
      }).then((res) => {
        if (res.result === 200) {
          // this.unLogin = res.data.userSessionInfo ? false : true;
          if (res.data.userSessionInfo !== null) {
            this.getCourseGroupView();
          } else {
            if (this.v_WeiXin) {
              // 微信授权
              this.weChantAuth();
            }
          }
        }
      });
    },
    // 定时获取session
    getprolong() {
      api.prolong().then((res) => {
        if (res.result === 200) {
        }
      });
    },
    onLoad() {
      this.pageNo++;
      this.getCourseView();
    },
    // 点击打卡规则
    clickInRule() {
      Dialog.alert({
        title: "打卡规则",
        message: this.clockInRule,
        messageAlign: "left",
      });
    },
    // 获取课程显示视图(分组信息)
    getCourseGroupView() {
      // clearInterval(this.requestLogSummaryAsyn); //关闭定时器
      let data = {
        courseId: window.location.href.split("=")[1].split("&")[0],
      };
      api.courseGroupView(data).then((res) => {
        if (res.result === 200) {
          this.groupInfoList = res.data.groupInfoList;
          // if(res.recentVisitGroupId)
          // this.groupId = res.data.groupInfoList[0].groupId;
          // this.groupId = res.data.recentVisitGroupId;
          this.imgUrl = res.data.banner;
          this.completeCount = res.data.completeCount;
          this.clockInDays = res.data.clockInDays;
          this.hasClockInToday = res.data.hasClockInToday;
          this.progress =
            "打卡天数" + this.completeCount + "/" + this.clockInDays;
          this.progressNum = this.completeCount / this.clockInDays;
          this.clockInRule = res.data.clockInRule;
          if (res.data.recentVisitGroupId === 0) {
            this.activeName = this.groupInfoList[0].groupTitle;
            this.groupId = res.data.groupInfoList[0].groupId;
          } else {
            this.activeName = this.groupInfoList.filter(
              (item) => item.groupId === res.data.recentVisitGroupId
            )[0].groupTitle;
            this.groupId = res.data.recentVisitGroupId;
          }
          // 分享------------------------------------------------
          let thisUrl = window.location.href;
          let params = {
            title: res.data.shareTitle,
            imgUrl: res.data.shareIcon,
            desc: res.data.shareContent,
          };
          this.$common.shareClick(thisUrl, params);
          // 分享end------------------------------------------------
          // console.log("进度", this.progressNum);
          // 分享链接
          // this.getGroupSharePosterInfo();
          // console.log("分组");
          this.getCourseView();
          this.getSession = setInterval(() => {
            this.getprolong();
          }, 900000);
        }
      });
    },
    // tabs切换
    tabsClick(name, title) {
      this.pageNo = 1;
      this.pageSize = 10;
      this.total = 0;
      this.loading = false;
      this.finished = false;
      this.courseList = [];
      this.groupId = this.groupInfoList.filter(
        (item) => item.groupTitle === name
      )[0].groupId;
      // console.log("tab");
      this.getCourseView();
      // clearInterval(this.requestLogSummaryAsyn); //关闭定时器
    },
    // yc点击视频
    getPlay(item) {
      clearInterval(this.realTime);
      this.uuid = "";

      // yc点击可播放视频
      for (var i = 0; i < this.courseList.length; i++) {
        if (this.courseList[i].courseItemId !== item.courseItemId) {
          this.courseList[i].canPlay = false;
        }
      }
      this.startTime = window.performance.now();
      clearInterval(this.requestLogSummaryAsyn); //关闭
      let data = {
        courseItemId: item.courseItemId,
      };
      // if (item.itemStatus == "NOT_PUNCH" || item.itemStatus == "PUNCHED") {
      api.getPlayInfo(data).then((res) => {
        if (res.result === 200) {
          item.canPlay = true;
          this.playerOptions.sources[0].src = res.data.playUrl;
          this.playerOptions.poster = res.data.coverUrl;
          this.playtimes = res.data.recentSecondOffset;
          this.playVideoId = item.courseItemId;
          this.itemStatus = item.itemStatus;
          this.gklog = res.data.recentSecondOffset;
          // let data = {
          //   courseItemId: this.playVideoId,
          //   frontPos: this.scrollY,
          // };
          // api.starVideo(data).then((res) => {
          //   if (res.result === 200) {
          //     this.historyId = res.data.historyId;
          //     this.videoPlayEnd();
          //     this.requestLogSummaryAsyn = setInterval(() => {
          //       if (this.stopStatus === true) {
          //         this.videoPlayEnd();
          //       }
          //     }, 10000);
          //   }
          // });
        }
      });
      // }
    },

    // 轮询视频是否播放完成
    // videoPlayEnd() {
    //   let data = {
    //     courseItemId: this.playVideoId,
    //     historyId: this.historyId,
    //     secondOffset: this.gklog,
    //   };
    //   if (this.itemStatus == "NOT_PUNCH" && this.hasClockInToday === false) {
    //     data.clockInCheck = true;
    //   } else {
    //     data.clockInCheck = false;
    //   }
    //   api.finishVideo(data).then((res) => {
    //     if (res.result === 200) {
    //       if (res.data.punchSuccess == true) {
    //         if (data.clockInCheck === true) {
    //           this.clockSuccess = true;
    //           this.getCourseGroupView();
    //         }
    //       } else {
    //         // Toast.fail("请认真观看完视频");
    //         // this.getCourseView();
    //       }
    //     }
    //   });
    // },
    // 获取课程显示视图
    getCourseView() {
      let data = {
        groupId: this.groupId,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      };
      api.courseView(data).then((res) => {
        if (res.result === 200) {
          if (this.scrollY < res.data.recentVisitVideoPosition) {
            this.getSession = setTimeout(() => {
              document.documentElement.scrollTop =
                res.data.recentVisitVideoPosition;
              document.body.scrollTop = res.data.recentVisitVideoPosition;
            }, 500);
          }
          // 数据处理
          for (var i = 0; i < res.data.records.length; i++) {
            res.data.records[i].canUnLock = false;
            res.data.records[i].canPlay = false;
          }
          if (res.data.unlockableItemId != null) {
            let nArr = res.data.records.filter(
              (el) => el.courseItemId == res.data.unlockableItemId
            );
            nArr[0].canUnLock = true;
          }
          let rows = res.data.records;
          //
          this.loading = false;
          this.total = res.data.total;
          if (rows == null || rows.length === 0) {
            // 加载结束
            this.finished = true;
            return;
          }
          // 将新数据与老数据进行合并
          this.courseList = this.courseList.concat(rows);

          //如果列表数据条数>=总条数，不再触发滚动加载
          if (this.courseList.length >= this.total) {
            this.finished = true;
          }
        }
      });
    },
    // 分享链接处理
    getGroupSharePosterInfo() {
      let data = {
        // courseId: window.location.href.split("=")[1].split("&")[0],
        groupId: this.groupId,
      };
      api.courseGroupView(data).then((res) => {
        if (res.result === 200) {
          // 分享
          let thisUrl = window.location.href;
          this.imgUrl = res.data.shareIcon;
          let params = {
            title: res.data.shareTitle,
            imgUrl: res.data.shareIcon,
            desc: res.data.shareContent,
          };
          this.$common.shareClick(thisUrl, params);
        }
      });
    },
    // video标签事件
    videoChange() {
      let videos = document.getElementsByTagName("video");
    },
    // 真实时间定时器
    getRealTime() {
      let data = {
        courseItemId: this.playVideoId,
        secondOffset: this.gklog,
        frontPos: this.scrollY,
        action: "run",
        uuid: this.uuid,
        clockInCheck: true,
      };
      if (this.itemStatus == "NOT_PUNCH" && this.hasClockInToday === false) {
        data.clockInCheck = true;
      } else {
        data.clockInCheck = false;
      }
      api.reportCourseWatchInfo(data).then((res) => {
        if (res.result === 200) {
          if (res.data.punchSuccess == true) {
            if (data.clockInCheck === true) {
              this.clockSuccess = true;
              this.getCourseGroupView();
            }
          }
        }
      });
    },
    // yc视频插件功能
    onPlayerPlay(player) {
      this.timer1 = setTimeout(() => {
        // clearInterval(this.realTime);
        // this.realTime = null;
        this.endTime = window.performance.now();
        this.stopStatus = true;
        this.consoleTime = this.endTime - this.startTime;

        let data = {
          courseItemId: this.playVideoId,
          secondOffset: this.gklog,
          frontPos: this.scrollY,
          action: "start",
          uuid: this.uuid,
          clockInCheck: false,
        };
        api.reportCourseWatchInfo(data).then((res) => {
          if (res.result === 200) {
            this.uuid = res.data.uuid;
            if (this.realTime) {
              clearInterval(this.realTime);
              this.realTime = null;
            }
            this.realTime = setInterval(() => {
              this.getRealTime();
            }, 5000);
          }
        });
      }, 100);
    },
    // 暂停回调
    onPlayerPause(player) {
      this.stopStatus = false;
      // console.log('暂停')
      let data = {
        courseItemId: this.playVideoId,
        secondOffset: this.gklog,
        frontPos: this.scrollY,
        action: "stop",
        uuid: this.uuid,
        clockInCheck: false,
      };
      api.reportCourseWatchInfo(data).then((res) => {
        if (res.result === 200) {
          if (this.realTime) {
            clearInterval(this.realTime);
            this.realTime = null;
          }
          this.uuid = res.data.uuid;
        }
      });
    },
    // 视频播完回调
    onPlayerEnded(player) {
      let data = {
        courseItemId: this.playVideoId,
        secondOffset: this.gklog,
        frontPos: this.scrollY,
        action: "stop",
        uuid: this.uuid,
        clockInCheck: false,
      };
      api.reportCourseWatchInfo(data).then((res) => {
        if (res.result === 200) {
          // setTimeout(() => {
          this.uuid = "";
          if (this.realTime) {
            clearInterval(this.realTime);
            this.realTime = null;
          }
          // }, 100);
        }
      });
    },
    // 当前播放位置发生变化时触发。
    onPlayerTimeupdate(player) {
      this.gklog = player.cache_.currentTime;
    },
    //将侦听器绑定到组件的就绪状态。与事件监听器的不同之处在于，如果ready事件已经发生，它将立即触发该函数。。
    playerReadied(player) {
      this.historyTime = setTimeout(() => {
        // console.log("开始", this.playtimes);
        player.currentTime(this.playtimes);
        // console.log("111", this.consoleTime);
      }, this.consoleTime + 500);
    },
  },
  // },
};
</script>
<style lang="scss" scoped>
.groupClockIn {
  /deep/.van-tabs__nav--card {
    border: 0;
  }
  /deep/.van-tabs__nav--card .van-tab {
    border-right: 0;
  }
  .tab-class {
    flex: none !important;
  }
  text-align: left;
  .head_img {
    position: relative;
    .progress {
      position: absolute;
      left: 1rem;
      bottom: 0.8rem;
      width: 18rem;
    }
    .tag {
      position: absolute;
      // left: 12rem;
      right: 0.3rem;
      bottom: 0.4rem;
    }
    .vanIco {
      position: absolute;
      right: 4.2rem;
      // bottom: 0.5rem;
      top: 0.3rem;
      font-weight: 900;
      color: #0066cc;
      font-size: 0.9rem;
    }
    .clockInRule {
      position: absolute;
      right: 0.5rem;
      top: 0.2rem;
      font-size: 0.9rem;
      color: #0066cc;
      font-weight: 900;

      // top: 0;
      // left: 0;
      // background-color: green;
      // width: 10rem;
    }
  }

  img {
    width: 100%;
  }
  .clock_in_date {
    padding: 0 1rem 1.5rem;
    h4 {
      margin-top: 0.8rem;
      margin-bottom: 0.2rem;
      display: inline-block;
      width: 15rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .tag1 {
      position: absolute;
      margin-top: 1.1rem;
      right: 1rem;
    }
    .video_box {
      position: relative;
      width: 100%;
      height: 12.2rem;
      .z_z_c {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        .z_img {
          position: absolute;
          width: 5rem;
          height: 5rem;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .z_color {
        background: rgba($color: #c8c9cc, $alpha: 0.6);
      }
      // yc视频样式
      .videoBox {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 97.5%;
      }
      .video_class {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      /deep/ .video-js {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      /deep/ .video-js .vjs-big-play-button {
        // position: absolute;
        // width: 5rem;
        // height: 3.5rem;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
        height: 2em;
        width: 2em;
        line-height: 2em;
        border-radius: 1em;
        margin-left: -1em;
        font-size: 0;
      }
    }
  }
}
</style>