<template>
  <div class="home_page_style">
    <div class="home_img_style">
      <!-- <img
        v-for="(item, index) in gradeList"
        :key="index"
        v-lazy="item"
        style="width: 100%; height: auto"
      /> -->
      <div v-for="(item, index) in gradeList" :key="index">
        <img
          v-if="item.viewType === 'IMAGE_VIEW'"
          v-lazy="item.imageUrl"
          style="width: 100%; height: auto"
        />
        <div
          class="video_box"
          v-if="item.viewType == 'VIDEO_VIEW'"
          @click="getPlay(item)"
        >
          <img :src="item.videoCoverUrl" alt="" />
          <div class="z_z_c">
            <img :src="require('../assets/bofang.png')" alt="" class="z_img" />
          </div>
          <div class="videoBox" v-if="item.canPlay == true">
            <video-player
              class="video-player vjs-custom-skin"
              ref="videoPlayer"
              :playsinline="true"
              :options="playerOptions"
            >
              <!-- @play="onPlayerPlay($event)"
              @pause="onPlayerPause($event)"
              @ended="onPlayerEnded($event)" -->
            </video-player>
          </div>
        </div>
        <!-- <video
          v-if="item.viewType === 'VIDEO_VIEW'"
          :src="item.videoPlayUrl"
          controls
          preload
          :poster="item.videoCoverUrl"
          webkit-playsinline
          x-webkit-airplay
          playsinline
          x5-playsinline
          class="video_class"
        >
          您的浏览器不支持 video 标签。
        </video> -->
      </div>
    </div>
    <div class="bottom-btn-wrap-one">
      <div class="bottom-btn">
        <van-icon
          name="wap-home"
          class="button-img-icon"
          v-if="homeBtnShow"
          @click="homeBack"
        />
        <a href="javascript:void(0)" class="button-img-wrap" @click="buyNow"
          >立即报名</a
        >
      </div>
    </div>
    <!-- 课程选择 -->
    <van-action-sheet v-model="sheet_show" title="请选择孩子年级">
      <p class="chooseClass_detail">
        请根据孩子当前实际年级填写，我们将为孩子定制最合适的课程
      </p>
      <ul class="sheet_content">
        <li
          v-for="(item, index) in sheet_list"
          :key="index"
          class="sheet_content_item"
          :class="{ sheet_content_item_active: item.isActive }"
        >
          <div class="content_title" @click="gradeClick(item)">
            {{ item.skuName }}
          </div>
        </li>
      </ul>
      <div class="bottom-btn-wrap-one">
        <div class="bottom-btn">
          <a
            href="javascript:void(0)"
            class="button-img-wrap"
            @click="gradeClickTo"
            >立即报名</a
          >
        </div>
      </div>
    </van-action-sheet>
    <!-- 优惠卷弹窗 -->
    <overlay
      :url="overlayImageUrl"
      :visibleImg="overlayShow"
      @close="overlayClose"
    ></overlay>
  </div>
</template>
<script>
import * as api from "../lib/homePage.js";
import { getPlay } from "../lib/groupClockIn.js";
import { getAuthCode, servicePolicy } from "../lib/cartPage.js";
import overlay from "../pages/overlay.vue";
import { videoPlayer } from "vue-video-player";
import "video.js/dist/video-js.css";
export default {
  name: "homePage",
  props: ["v_WeiXin"],
  components: {
    overlay,
    videoPlayer,
  },
  data() {
    return {
      overlayImageUrl: "",
      overlayShow: false,
      productId: "",
      entranceId: "",
      policyUrl: "",
      policyContent: "",
      gradeList: [],
      sheet_show: false,
      sheet_list: [],
      lastRunTime: null,
      isWeiXin: false,
      verificationCountDown: 0,
      isLogin: false,
      homeBtnShow: false,
      itemData: {},
      groupOrderId: "",
      // yc视频插件数据
      playerOptions: {
        // playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: true, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4",
            src: "", //你的视频地址（必填）
          },
        ],
        poster: "", //你的封面地址
        // width: document.documentElement.clientWidth,
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true, //全屏按钮
        },
      },
    };
  },
  mounted() {
    document.title = "课程介绍";
    this.$common.setCookie("info_data", "");
    this.productId = this.$route.query.productId;
    this.entranceId = this.$route.query.entranceId;
    this.groupOrderId = this.$route.query.groupOrderId;
    if (this.groupOrderId) {
      this.$common.setCookie("groupOrderId_loaction", this.groupOrderId, 1);
    } else {
      this.$common.setCookie("groupOrderId_loaction", "", -1);
    }
    let homeBtn = this.$route.query.homeBtn;
    this.homeBtnShow = false;
    if (homeBtn) {
      this.homeBtnShow = true;
    }
    this.isWeiXin = this.v_WeiXin;
    this.getProductView({ productId: this.productId });
    this.getProductSubView();
  },
  methods: {
    // 点击播放视频
    getPlay(item) {
      for (var i = 0; i < this.gradeList.length; i++) {
        if (this.gradeList[i].viewId !== item.viewId) {
          this.gradeList[i].canPlay = false;
        }
      }
      let data = {
        videoId: item.videoId,
      };
      getPlay(data).then((res) => {
        if (res.result === 200) {
          item.canPlay = true;
          this.playerOptions.sources[0].src = res.data.playUrl;
          this.playerOptions.poster = res.data.coverUrl;
          // this.playVideoId = item.courseItemId;
          // this.itemStatus = item.itemStatus;
          // let data = {
          //   courseItemId: this.playVideoId,
          // };
          // if (this.hasClockInToday == false) {
          //   api.starVideo(data).then((res) => {
          //     if (res.result === 200) {
          //     }
          //   });
          // } else {
          //   api.notifyVideoWatched(data).then((res) => {
          //     if (res.result === 200) {
          //     }
          //   });
          // }
        }
      });
    },
    // 关闭优惠卷弹窗
    overlayClose() {
      this.overlayShow = false;
    },
    // 点击返回主页面
    homeBack() {
      let url = this.$common.getCookie("home_url");
      window.location.href = url;
    },
    // 查看商品图片
    getProductSubView() {
      let data = {
        productId: this.productId,
        type: "INDEX_POPOUT",
      };
      api.productSubView(data).then((res) => {
        if (res.result === 200) {
          let list = res.data.viewList;
          list.map((item) => {
            item.imageUrl = item.imageUrl.replace("https", "https");
          });
          if (list && list.length > 0) {
            this.overlayImageUrl = list[0].imageUrl;
            this.overlayShow = true;
          }
        }
      });
    },
    // 查询课程信息
    getProductView(data) {
      this.gradeList = [];
      api.productView(data).then((res) => {
        if (res.result === 200) {
          let skuInfoList = res.data.skuInfoList;
          // 数据处理
          for (var i = 0; i < res.data.unitViewList.length; i++) {
            res.data.unitViewList[i].canPlay = false;
          }
          let unitViewList = res.data.unitViewList;
          unitViewList.map((item) => {
            item.imageUrl = item.imageUrl.replace("https", "https");
            item.videoCoverUrl = item.videoCoverUrl.replace("https", "https");
            this.gradeList.push(item);
          });
          skuInfoList.forEach((item) => {
            item.isActive = false;
          });

          this.sheet_list = skuInfoList;
          // this.gradeList = unitViewList;
        }
      });
    },
    // 点击课程
    gradeClick(val) {
      this.sheet_list.forEach((item) => {
        item.isActive = false;
        if (item.skuId === val.skuId) {
          item.isActive = true;
        }
      });
      this.itemData = val;
    },
    // 跳转课程
    gradeClickTo() {
      if (!this.itemData.skuId) {
        this.$toast("请选择年级");
        return;
      }
      if (this.itemData.disabeld) {
        return;
      }
      let currentTime = Date.now(),
        protectTime = 1000;
      if (this.lastRunTime) {
        if (currentTime - this.lastRunTime < protectTime) {
          this.lastRunTime = currentTime;
          return; //两次执行太过频繁，直接退出
        }
      }
      this.lastRunTime = currentTime;
      //
      let appId = location.href.split("&");
      let newAppId = "";
      for (var i = 0; i < appId.length; i++) {
        if (appId[i].indexOf("appid") != -1) {
          newAppId = appId[i].split("=")[1];
        }
      }
      // console.log(newAppId)
      this.$router.push({
        path: "/cartPage",
        query: {
          productId: this.productId,
          skuId: this.itemData.skuId,
          entranceId: this.entranceId,
          appId: newAppId,
        },
      });
    },
    // 点击立即购买
    buyNow() {
      this.sheet_show = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.home_page_style {
  width: 100%;
  // height: 100%;
  font-size: 0rem;
  background-color: #fff;
  // padding-bottom: 2.3rem;
  .home_img_style {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 3.5rem;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #fff4d4;
  }
  .video_box {
    width: 100%;
    height: auto;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    .z_z_c {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      .z_img {
        position: absolute;
        width: 5rem;
        height: 5rem;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .z_color {
      background: rgba($color: #c8c9cc, $alpha: 0.6);
    }
    // yc视频样式
    .videoBox {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 97.5%;
    }
    .video_class {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    /deep/ .video-js {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    /deep/ .video-js .vjs-big-play-button {
      // position: absolute;
      // width: 5rem;
      // height: 3.5rem;
      // top: 50%;
      // left: 50%;
      // transform: translate(-50%, -50%);
      height: 2em;
      width: 2em;
      line-height: 2em;
      border-radius: 1em;
      margin-left: -1em;
      font-size: 0;
    }
  }
  .video_class {
    display: block;
    width: 100%;
  }
  .home_head {
    width: 100%;
  }
  .home_grade {
    img {
      width: 100%;
      height: auto;
    }
    .home_grade_head {
      text-align: left;
      border-left: 2px solid #ff0000;
      padding-left: 0.6rem;
    }
    .home_grade_count {
      padding: 0.8rem 0;
      text-align: left;
      .home_grade_item {
        display: inline-block;
        width: 28.5%;
        margin: 2% 0.5rem 2%;
        border: 0.04rem solid #ff4700;
        font-size: 0.8rem;
        color: #ff4700;
        background: #fff;
        height: 2.5rem;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-border-radius: 0.25rem;
        border-radius: 0.25rem;
        text-align: center;
        line-height: 2.5rem;
        font-weight: 700;
      }
      .home_grade_item_disabeld {
        border-color: #b3b3b3;
        color: #b3b3b3;
      }
    }
  }
  .home_grade_img {
    position: relative;
    img {
      width: 100%;
      height: auto;
    }
    .home_grade_img_a {
      position: absolute;
      background-size: 100%;
      background-repeat: no-repeat;
      cursor: pointer;
    }
  }
  .home_curriculum {
    height: 1.5rem;
    background: #fff;
    padding: 1rem;
    .home_curriculum_head {
      text-align: left;
      border-left: 2px solid #ff0000;
      padding-left: 0.6rem;
    }
  }
  .home_curriculum_img {
    height: 18.75rem;
    padding-bottom: 2.5rem;
  }
  .bottom-btn-wrap-one {
    position: fixed;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
    z-index: 999;
    bottom: 0;
    background: transparent;
    font-size: 0.95rem;
    width: 100%;
    .bottom-btn {
      padding: 0.5rem 1rem;
      background: rgba(255, 255, 255, 0.98);
      box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.08),
        0 -4px 10px 0 rgba(132, 132, 132, 0.05);
      display: flex;
      justify-content: space-between;
      .button-img-icon {
        font-size: 2.4rem;
        color: #ffd01e;
        width: 12%;
        padding-right: 0.8rem;
      }
      .button-img-wrap {
        border-radius: 2rem;
        line-height: 2.6rem;
        background-color: #fa4031;
        color: #fff;
        width: 100%;
      }
    }
  }
  .chooseClass_detail {
    font-size: 0.95rem;
    font-family: PingFangSC-Regular, PingFangSC;
    font-weight: 400;
    text-align: center;
    color: rgba(45, 46, 63, 1);
    line-height: 1rem;
    padding: 0 0.8rem;
    margin: 0;
  }
  .sheet_content {
    padding: 0.8rem;
    padding-bottom: 3.5rem;
    max-height: 22rem;
    min-height: 10rem;
    overflow-y: auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    box-sizing: border-box;

    .sheet_content_item {
      display: inline-block;
      text-align: left;
      width: 23%;
      font-size: 0.9rem;
      text-align: center;
      padding: 0.5rem;
      margin-bottom: 1rem;
      border-radius: 0.5rem;
      background-color: #fff6f3;
      color: #ff4c50;
    }
    .sheet_content_item_active {
      // border: 1px solid #FF4C50;
      color: #fff6f3;
      background-color: #ff4c50;
    }
  }
  .btn_login_style {
    font-size: 0.95rem;
    width: 100%;
    .btn_style {
      padding: 0.5rem 1rem;
      .button_a {
        display: block;
        border-radius: 0.4rem;
        line-height: 2.6rem;
        background-color: #ffd01e;
        color: #303133;
      }
    }
    &::before {
      content: "";
      display: block;
      height: 5rem;
    }
  }
}
</style>
