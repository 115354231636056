import http from "./axios";

// 获取课程显示视图----
export const courseView = ({ courseId }) => {
  return http.get("/course/getCourseView", {
    courseId: courseId,
  });
};

// 解锁视频
export const unLock = ({ courseItemId }) => {
  return http.json_post("/course/unlockCourseItem", {
    courseItemId: courseItemId,
  });
};

// 获取视频播放地址----
export const getPlay = ({ videoId }) => {
  return http.get("/manager/getVodVideoLocation", {
    videoId: videoId,
  });
};

// 视频开始
export const starVideo = ({ courseItemId }) => {
  return http.json_post("/course/reportCourseItemStart", {
    courseItemId: courseItemId,
  });
};

// 视频完成
export const finishVideo = ({
  courseItemId,
  historyId,
  secondOffset,
  clockInCheck,
}) => {
  return http.json_post("/course/reportCourseItemFinish", {
    courseItemId: courseItemId,
    historyId: historyId,
    secondOffset: secondOffset,
    clockInCheck: clockInCheck,
  });
};

// 获取下一个可解锁视频----
export const getNextUnlockableItem = ({ courseId }) => {
  return http.get("/course/getNextUnlockableItem", {
    courseId: courseId,
  });
};
