<template>
  <div class="alimony">
    <div class="head">
      <div class="headText">生活费余额（元）</div>
      <div class="headNum">{{ balance }}</div>
      <!-- <div class="headText1">
        1：{{ userObj.userID }}{{ userObj.userName }}{{ userObj.userAvatar
        }}{{ userObj.userIntegral }}{{ userObj.ext }}
      </div> -->
    </div>
    <div class="content-list-cc">
      <van-cell title="个人信息" icon="user-o" is-link @click="infoClick" />
    </div>
    <div class="money">
      <div class="moneyText">提现金额</div>
      <div class="moneyIn">
        <input
          type="number"
          onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
          onafterpaste="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
          placeholder="1元"
          :max="balance"
          v-on:input="oninput"
          v-model="money"
        />
      </div>
    </div>
    <!-- <div class="sdk">
      <van-button type="info" round block @click="videoClick"
        >加载激励视频</van-button
      >
      <van-button type="info" round block @click="advertClick"
        >加载插屏广告</van-button
      >
      <van-button type="info" round block @click="showClick"
        >展示广告</van-button
      >
    </div>
    <div class="content">点击了{{ conClick }}:{{ content1 }}-{{ content }}</div> -->
    <div class="btn">
      <van-button
        type="primary"
        round
        block
        @click="btnClick"
        :disabled="btnDisabled"
        >立即提现</van-button
      >
    </div>
    <!-- <div class="txt">剩余可提现次数：1</div> -->
    <van-dialog v-model="show">
      <div class="img">
        <img src="../assets/gzhtx.jpg" alt="" />
      </div>
    </van-dialog>
  </div>
</template>


<script>
import * as api from "../lib/alimony.js";
// import md5 from "js-md5";
import { Notify } from "vant";

export default {
  name: "alimony",
  data() {
    return {
      show: false,
      balance: 0,
      btnDisabled: false,
      money: "",
      content: "",
      content1: "",
      conClick: "",
      // userID: "",
      // userName: "",
      // userAvatar: "",
      // userIntegral: "",
      // ext: "",
      userObj: {},
      ymd: "",
    };
  },
  mounted() {
    document.title = "生活费提现";
    var date = new Date();
    let Y = date.getFullYear();
    let M =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1;
    let D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();

    this.ymd = Y + "" + M + D;
    // window.SjmJSAdSdk = this.SjmJSAdSdk;
    window.sjmJSAdSdkCallBack = this.sjmJSAdSdkCallBack;
    this.userObj = JSON.parse(SjmJSSdk.getUser());
    this.query();
  },
  methods: {
    query() {
      // let data = {
      //   userNo: this.userObj.userID,
      //   timestamp: Date.parse(new Date()),
      //   sign: md5(
      //     this.userObj.userID +
      //       Date.parse(new Date()) +
      //       this.ymd +
      //       "HUI_YU_LOGIN_IDENTIFICATION"
      //   ),
      // };
      api
        // .userLogin(data)
        .getUserInfo()
        .then((res) => {
          if (res.result === 200) {
            this.balance = res.data.amount;
            console.log(this.balance, res.data);
          }
        })
        .catch((res) => {
          // if (res.result !== 200) {
          Notify(res.message);
          // }
        });
    },
    btnClick() {
      this.show = true;
    },
    oninput() {
      if (this.money > this.balance) {
        this.money = parseInt(this.balance);
      }
    },
    videoClick() {
      this.conClick = "加载激励视频";
      SjmJSAdSdk.loadAd("sjmad_test001", "rewardVideo");
    },
    advertClick() {
      this.conClick = "加载插屏广告";
      SjmJSAdSdk.loadAd("sjmad_test004", "InterstitialAd");
    },
    showClick() {
      this.conClick = "展示广告";
      SjmJSAdSdk.showAd();
    },
    sjmJSAdSdkCallBack(type, msg) {
      let data = {
        type: type,
        msg: msg,
      };
      api
        .postData(data)
        .then((res) => {
          if (res.reslut === 200) {
          }
        })
        .catch((res) => {
          // if (res.result !== 200) {
          Notify(res.message);
          // }
        });
      this.content1 = "回调" + msg;
      switch (type) {
        case "onSjmAdLoaded":
          this.content = "onSjmAdLoaded";
          SjmJSAdSdk.showAd();
          break;
        case "onSjmAdShow":
          this.content = "onSjmAdShow";
          break;
        case "onSjmAdClick":
          this.content = "onSjmAdClick";
          break;
        case "onSjmAdClose":
          this.content = "onSjmAdClose";
          break;
        case "onSjmAdError":
          this.content = "onSjmAdError";
          break;
        case "onSjmAdVideoCached":
          this.content = "onSjmAdVideoCached";
          SjmJSAdSdk.showAd();
          break;
        case "onSjmAdShowError":
          this.content = "onSjmAdShowError";
          break;
        case "onSjmAdVideoComplete":
          this.content = "onSjmAdVideoComplete";
          break;
        case "onSjmAdExpose":
          this.content = "onSjmAdExpose";
          break;
        case "onSjmAdReward":
          this.content = "onSjmAdReward";
          break;
      }
    },
    // 点击个人信息
    infoClick() {
      this.$router.push("/useInfo");
    },
  },
};
</script>

<style lang='scss' scoped >
.alimony {
  position: relative;
  width: 100%;
  background-color: #f7f8fa;
  height: 100%;
  .head {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    height: 9rem;
    background-image: linear-gradient(to right, #f75000, #ff8000);
    color: #fff;
    padding-left: 1rem;
    .headText {
      position: absolute;
      top: 2rem;
      font-size: 0.9rem;
    }
    .headText1 {
      position: absolute;
      top: 7rem;
      font-size: 0.9rem;
    }
    .headNum {
      position: absolute;
      top: 4rem;
      font-size: 2.5rem;
      font-weight: 600;
    }
  }
  .money {
    position: relative;
    top: 3rem;
    padding-left: 1rem;
    .moneyText {
      position: absolute;
    }
    .moneyIn {
      height: 3rem;
      width: 11rem;
      position: absolute;
      top: 2rem;
      background-color: #fff;
      input {
        outline-style: none;
        border: 0px;
        padding: 0.8rem 1rem;
        width: 11rem;
        font-size: 1.5rem;
        text-align: center;
      }
      input:focus {
        border-color: #66afe9;
        outline: 0;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
          0 0 8px rgba(102, 175, 233, 0.6);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
          0 0 8px rgba(102, 175, 233, 0.6);
      }
      :-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: rgb(150, 147, 147);
      }

      ::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: rgb(150, 147, 147);
      }

      input:-ms-input-placeholder,
      textarea:-ms-input-placeholder {
        color: rgb(150, 147, 147);
      }

      input::-webkit-input-placeholder,
      textarea::-webkit-input-placeholder {
        color: rgb(150, 147, 147);
      }
    }
  }
  .sdk {
    position: absolute;
    padding: 0 1rem;
    width: 90%;
    top: 20rem;
    .van-button {
      margin-top: 0.8rem;
    }
  }
  .content {
    position: relative;
    top: 22rem;
  }
  .btn {
    // position: relative;
    position: absolute;
    padding: 0 1rem;
    // top: 24rem;
    bottom: 2rem;
    width: 90%;
  }
  .txt {
    position: relative;
    top: 13rem;
    font-size: 0.9rem;
    color: rgb(150, 147, 147);
  }
  .img {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .content-list-cc {
    margin: 0.8rem 0;
    text-align: left;
  }
}
</style>



