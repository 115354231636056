<template>
  <van-list
    v-model="loading"
    :finished="finished"
    finished-text="没有更多了"
    @load="onLoad"
  >
    <div class="myRedInfo">
      <div v-for="(item, index) in myRedList" :key="index" class="card">
        <div
          class="img"
          v-if="item.status == 'SENT'"
          @click="getRedEnvelope(item)"
        >
          <img src="../assets/redEnvelope.png" alt="" />
        </div>
        <div class="img" v-if="item.status == 'RECEIVED'">
          <img src="../assets/redEnvelope.png" alt="" />
        </div>
        <div class="remark">{{ item.remark }}</div>
        <div class="money">¥ {{ (item.money / 100).toFixed(2) }}</div>
        <!-- <div class="money">
          ¥{{
            item.money
              .toString()
              .substring(0, item.money.toString().length - 2)
          }}.
        </div>
        <div class="money1">{{ item.money.toString().substring(1) }}</div> -->
        <div class="tags">
          <!-- <van-tag v-if="item.status == 'RECEIVED'" type="danger"
            >已领取</van-tag
          >
          <van-tag v-if="item.status == 'SENT'" type="success">未领取</van-tag> -->
          <img
            v-if="item.status == 'RECEIVED'"
            src="../assets/ylq.png"
            alt=""
          />
          <img v-if="item.status == 'SENT'" src="../assets/wlq.png" alt="" />
        </div>
      </div>
    </div>
  </van-list>
</template>
<script>
import { Dialog } from "vant";
import { Toast } from "vant";

import * as api from "../lib/myRedInfo.js";
export default {
  name: "myRedInfo",
  props: {
    status: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      finished: false,
      myRedList: [],
    };
  },
  mounted() {
    let data = {
      pageNo: this.pageNo,
      pageSize: 5,
      status: this.status,
    };
    this.getRedList();
  },
  methods: {
    onLoad() {
      // 异步更新数据
      let data = {
        pageNo: this.pageNo,
        pageSize: 5,
        status: this.status,
      };
      this.getRedList();
    },
    getRedList() {
      api.redList({ status: this.status }).then((res) => {
        this.loading = false;
        if (res.result === 200) {
          this.myRedList = res.data;
          this.finished = true;
        }
      });
    },
    // 点击领取红包
    getRedEnvelope(val) {
      Dialog.confirm({
        message: "确定要领取红包吗",
      })
        .then(() => {
          api.getRed({ id: val.id }).then((res) => {
            if (res.result === 200) {
              this.getRedList();
              Toast.success("领取成功");
            }
          });
        })
        .catch(() => {
          // Toast("已取消领取");
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.myRedInfo {
  padding: 0 1rem;
  box-sizing: border-box;
  .card {
    width: 100%;
    height: 8rem;
    margin-top: 1rem;
    border-bottom: 0.1rem dashed grey;
    position: relative;
    .img {
      position: absolute;
      //-----------------------------------
      // width: 7.5rem;
      // height: 6rem;
      // background-color: 	#FF5151;
      // top: 1rem;
      // border-radius: 1rem;
      //-----------------------------------
      width: 8rem;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .remark {
      position: absolute;
      left: 8rem;
      top: 1rem;
      font-size: 1.5rem;
    }
    .money {
      position: absolute;
      left: 8rem;
      top: 4rem;
      font-size: 1rem;

      // left: 1rem;
      // top: 2.3rem;
      // font-size: 2.5rem;
    }
    // .money1 {
    //   position: absolute;
    //   top: 3.3rem;
    //   left: 4.5rem;
    //   font-size: 1.5rem;
    // }
    .tags {
      position: absolute;
      left: 15rem;
      top: 2rem;
      font-size: 1rem;
      width: 5rem;
      height: 5rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>