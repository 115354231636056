<template>
  <div class="info">
    <van-contact-card
      type="edit"
      :name="currentContact.name"
      :tel="currentContact.tel"
      @click="onEdit"
    />
    <van-dialog
      v-model="show"
      title="请输入手机号和验证码"
      show-cancel-button
      @confirm="confirm"
    >
      <van-field
        v-model="currentContact.tel1"
        center
        clearable
        label="手机号"
        placeholder="请输入手机号"
      >
      </van-field>
      <van-field
        v-model="sms"
        center
        clearable
        label="验证码"
        placeholder="请输入短信验证码"
      >
        <template #button>
          <van-button
            size="small"
            type="primary"
            @click="sendCode"
            v-if="!codeFlag"
            >发送验证码</van-button
          >
          <van-button size="small" type="warning" disabled v-if="codeFlag"
            >已发送({{ count }}S)</van-button
          >
        </template>
      </van-field>
    </van-dialog>
  </div>
</template>
<script>
import * as api from "../lib/info.js";
import { Toast } from "vant";

export default {
  name: "info",
  components: {},
  data() {
    return {
      show: false,
      sms: "",
      count: 60, //发送验证码倒计时
      codeFlag: false, // 判断是否发生验证码
      currentContact: {
        name: "",
        tel: "",
        tel1: "",
      },
      timer: null,
    };
  },
  mounted() {
    document.title = "个人信息";
    this.currentContact.name = this.$route.query.userName;
    this.currentContact.tel = this.$route.query.phone;
  },
  methods: {
    onEdit() {
      this.show = true;
      this.sms = "";
      this.codeFlag = false;
      this.currentContact.tel1 = "";
    },
    // 发送验证码
    sendCode() {
      if (this.currentContact.tel1 == "") {
        Toast("请输入手机号");
      } else {
        api.getCode({ phone: this.currentContact.tel1 }).then((res) => {
          if (res.result === 200) {
            this.codeFlag = true;
            this.currentContact.tel = this.currentContact.tel1;
            Toast("发送成功");
            // 验证码发送成功之后处理
            this.timer = setInterval(() => {
              if (this.count == 0) {
                clearInterval(this.timer);
                this.timer = null;
                this.codeFlag = false;
                this.count = 60;
              }
              this.count -= 1;
            }, 1000);
          }
        });
      }
    },
    // 手机号确认
    confirm() {
      api
        .getPhoneBind({ phone: this.currentContact.tel, checkCode: this.sms })
        .then((res) => {
          if (res.result === 200) {
            Toast("绑定成功");
          }
        });
    },
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  },
};
</script>
<style lang="scss" scoped>
.info {
  text-align: left;
  background: #ebedf0;
  padding-top: 1rem;
  box-sizing: border-box;
}
</style>
