import http from "./axios";

// 登录
export const getAuthCode = ({ phone }) => {
  return http.get("/user/getAuthCode", {
    phone: phone,
  });
};

// 获取订单信息----
export const orderInfo = ({ orderId }) => {
  return http.get("/order/info", {
    orderId: orderId,
  });
};

// 获取商品信息
export const extraInfo = ({ skuId }) => {
  return http.get("/product/extraInfo", {
    skuId: skuId,
  });
};

// 获取订单信息
export const servicePolicy = ({ productId }) => {
  return http.get("/product/servicePolicy", {
    productId: productId,
  });
};

// 获取微信支付结果
export const queryWxPayResult = ({ orderId }) => {
  return http.get("/order/queryWxPayResult", {
    orderId: orderId,
  });
};

// 获取用户的默认地址
export const getDefaultAddress = () => {
  return http.get("/address/getDefaultAddress");
};

// 获取微信支付失败通知
export const payFailed = ({ orderId }) => {
  return http.json_post("/order/payFailed", {
    orderId: orderId,
  });
};

// 发起订单支付
export const orderPay = ({
  orderId,
  payType,
  extraInfoList,
  memberCouponId,
  usingCoupon,
  orderType,
  groupOrderId,
}) => {
  return http.json_post("/order/pay", {
    orderId: orderId,
    payType: payType,
    extraInfoList: extraInfoList,
    memberCouponId: memberCouponId,
    usingCoupon: usingCoupon,
    orderType: orderType,
    groupOrderId: groupOrderId,
  });
};

// 手机登录
export const phoneLogin = ({ phone, checkCode, productId }) => {
  return http.json_post("/user/phoneLogin", {
    phone: phone,
    checkCode: checkCode,
    productId: productId,
  });
};

// 创建订单
export const orderCreate = ({ skuId, productId, entranceId }) => {
  return http.json_post("/order/create", {
    skuId: skuId,
    productId: productId,
    entranceId: entranceId,
  });
};

// 微信授权--静默授权
export const weChantAuth = ({ redirectUri, appId }) => {
  return http.get("/wechat/snsapiBase", {
    redirectUri: redirectUri,
    appId: appId,
  });
};

// 微信用户登录--静默登录
export const weChatLogin = ({ code, appId }) => {
  return http.json_post("/user/snsapiBase/wxLogin", {
    code: code,
    appId: appId,
  });
};

// 检查用户是否登陆
export const userCheckLogin = ({ appId }) => {
  return http.get("/user/checkLogin", {
    appId: appId,
  });
};

// 查询商品文案
export const getProductTextInfo = ({ productId, type }) => {
  return http.get("/productText/getProductTextInfo", {
    productId: productId,
    type: type,
  });
};

// 查询优惠券列表
export const userCouponList = ({ skuId }) => {
  return http.get("/user/couponList", {
    skuId: skuId,
  });
};

// 领取优惠券
export const autoDistribute = ({ productId }) => {
  return http.json_post("/user/autoDistribute", {
    productId: productId,
  });
};
