import http from "./axios";

// 修改地址信息
export const addressModity = ({
  addressId,
  receiverName,
  receiverPhone,
  province,
  city,
  area,
  addressDetail,
  areaCode,
  defaultAddress,
}) => {
  return http.json_post("/address/modify", {
    addressId: addressId,
    receiverName: receiverName,
    receiverPhone: receiverPhone,
    province: province,
    city: city,
    area: area,
    addressDetail: addressDetail,
    areaCode: areaCode,
    defaultAddress: defaultAddress,
  });
};
// 添加地址信息
export const addressAdd = ({
  receiverName,
  receiverPhone,
  province,
  city,
  area,
  addressDetail,
  areaCode,
  defaultAddress,
}) => {
  return http.json_post("/address/add", {
    receiverName: receiverName,
    receiverPhone: receiverPhone,
    province: province,
    city: city,
    area: area,
    addressDetail: addressDetail,
    areaCode: areaCode,
    defaultAddress: defaultAddress,
  });
};

// 删除地址
export const addressDelete = ({ addressId }) => {
  return http.json_post("/address/remove", {
    addressId: addressId,
  });
};
