import http from "./axios";
//封装公共代码
const common = {};

// 手机号加密
common.telEncryption = (tel) => {
  let reg = /^(\d{3})\d{4}(\d{4})$/;
  tel = tel.replace(reg, "$1****$2");
  return tel;
};

// 答案拼接
common.answerSplicing = (label) => {
  if (label) {
    // 问卷穿梭框显示内容处理(问题+答案)
    if (label.length > 5) {
      label = label.slice(0, 5);
      if (label.slice(-1) == ",") {
        label = label.slice(0, label.lastIndexOf(","));
      }
      label = "(" + label + "..." + ")";
    } else {
      if (label.slice(-1) == ",") {
        label = label.slice(0, label.lastIndexOf(","));
      }
      label = "(" + label + ")";
    }
  }
  return label;
};

//时间格式：yyyy-MM-dd HH:mm:ss
common.transformTime = function (time) {
  var time = new Date(time);
  var y = time.getFullYear();
  var m = time.getMonth() + 1;
  var d = time.getDate();
  var h = time.getHours();
  var mm = time.getMinutes();
  var s = time.getSeconds();
  function add0(m) {
    return m < 10 ? "0" + m : m;
  }
  return (
    y +
    "-" +
    add0(m) +
    "-" +
    add0(d) +
    " " +
    add0(h) +
    ":" +
    add0(mm) +
    ":" +
    add0(s)
  );
};

//时间格式：yyyy/MM/dd HH:mm
common.transformTime2 = function (time) {
  var time = new Date(time);
  var y = time.getFullYear();
  var m = time.getMonth() + 1;
  var d = time.getDate();
  var h = time.getHours();
  var mm = time.getMinutes();
  var s = time.getSeconds();
  function add0(m) {
    return m < 10 ? "0" + m : m;
  }
  return y + "/" + add0(m) + "/" + add0(d) + " " + add0(h) + ":" + add0(mm);
};

//Es6数组去重
common.unique = function (arr) {
  var x = new Set(arr);
  return [...x];
};

// 创建cookie
common.setCookie = (c_name, value, expiredays) => {
  var exdate = new Date();
  exdate.setDate(exdate.getDate() + expiredays);
  document.cookie =
    c_name +
    "=" +
    escape(value) +
    (expiredays == null ? "" : ";expires=" + exdate.toGMTString());
};

// 读取cookie
common.getCookie = (c_name) => {
  if (document.cookie.length > 0) {
    //先查询cookie是否为空，为空就return ""
    let c_start = document.cookie.indexOf(c_name + "="); //通过String对象的indexOf()来检查这个cookie是否存在，不存在就为 -1
    if (c_start != -1) {
      c_start = c_start + c_name.length + 1; //最后这个+1其实就是表示"="号啦，这样就获取到了cookie值的开始位置
      let c_end = document.cookie.indexOf(";", c_start); //其实我刚看见indexOf()第二个参数的时候猛然有点晕，后来想起来表示指定的开始索引的位置...这句是为了得到值的结束位置。因为需要考虑是否是最后一项，所以通过";"号是否存在来判断
      if (c_end == -1) c_end = document.cookie.length;
      return unescape(document.cookie.substring(c_start, c_end)); //通过substring()得到了值。想了解unescape()得先知道escape()是做什么的，都是很重要的基础，想了解的可以搜索下，在文章结尾处也会进行讲解cookie编码细节
    }
  }
  return "";
};

//input只能输入数字和一个小数点
common.numberCheck = (num) => {
  var str = num;
  var len1 = str.substr(0, 1);
  var len2 = str.substr(1, 1);
  //如果第一位是0，第二位不是点，就用数字把点替换掉
  if (str.length > 1 && len1 == 0 && len2 != ".") {
    str = str.substr(1, 1);
  }
  //第一位不能是.
  if (len1 == ".") {
    str = "";
  }
  //限制只能输入一个小数点
  if (str.indexOf(".") != -1) {
    var str_ = str.substr(str.indexOf(".") + 1);
    if (str_.indexOf(".") != -1) {
      str = str.substr(0, str.indexOf(".") + str_.indexOf(".") + 1);
    }
  }
  //正则替换，保留数字和小数点
  str = str.replace(/[^\d^\.]+/g, "");
  //如果需要保留小数点后两位，则用下面公式
  // str = str.replace(/\.\d\d$/,'')
  return str;
};

// 秒数计算成时分秒
common.secondCompute = (second) => {
  let result = parseInt(second);
  let h =
    Math.floor(result / 3600) < 10
      ? "0" + Math.floor(result / 3600)
      : Math.floor(result / 3600);
  let m =
    Math.floor((result / 60) % 60) < 10
      ? "0" + Math.floor((result / 60) % 60)
      : Math.floor((result / 60) % 60);
  let s =
    Math.floor(result % 60) < 10
      ? "0" + Math.floor(result % 60)
      : Math.floor(result % 60);

  let res = "";
  res += `${h}:`;
  res += `${m}:`;
  res += `${s}`;
  return res;
};

// 秒数计算成--时
common.secondComputeH = (second) => {
  let result = parseInt(second);
  let h =
    Math.floor(result / 3600) < 10
      ? "0" + Math.floor(result / 3600)
      : Math.floor(result / 3600);

  let res = "";
  res += `${h}`;
  return res;
};

// 深度拷贝
common.deepClone = (source) => {
  const targetObj = source.constructor === Array ? [] : {}; // 判断复制的目标是数组还是对象
  for (const keys in source) {
    // 遍历目标
    if (source.hasOwnProperty(keys)) {
      if (source[keys] && typeof source[keys] === "object") {
        // 如果值是对象，就递归一下
        targetObj[keys] = source[keys].constructor === Array ? [] : {};
        targetObj[keys] = common.deepClone(source[keys]);
      } else {
        // 如果不是，就直接赋值
        targetObj[keys] = source[keys];
      }
    }
  }
  return targetObj;
};

//防止多次点击
common.promiseDownload = (href) => {
  let p = new Promise((resolve, rejects) => {
    let a = false;
    window.location.href = href;
    resolve(a);
  });
  return p;
};

// 计算字符长度
common.calculatedLength = (data) => {
  let flexWidth = 0;
  for (const char of data) {
    if ((char >= "A" && char <= "Z") || (char >= "a" && char <= "z")) {
      // 如果是英文字符，为字符分配10个单位宽度
      flexWidth += 10;
    } else if (char >= "\u4e00" && char <= "\u9fa5") {
      // 如果是中文字符，为字符分配15个单位宽度
      flexWidth += 15;
    } else {
      // 其他种类字符，为字符分配10个单位宽度
      flexWidth += 10;
    }
  }
  return flexWidth;
};

// 插入到光标位置
common.insertInputTxt = (insertText, id, textarea) => {
  let text = insertText;
  let areaField = document.getElementById(id); // 拿到目标标签
  // IE浏览器
  if (document.selection) {
    areaField.focus();
    let sel = document.selection.createRange();
    sel.text = text;
  } else if (areaField.selectionStart || areaField.selectionStart === 0) {
    // 谷歌 Firefox 等
    let startPos = areaField.selectionStart;
    let endPos = areaField.selectionEnd;
    let restoreTop = areaField.scrollTop; // 获取滚动条高度
    //  textarea 是v-model的值
    // text 是 选择的要插入的值
    textarea =
      textarea.substring(0, startPos) +
      text +
      textarea.substring(endPos, textarea.length);
    if (restoreTop > 0) {
      areaField.scrollTop = restoreTop;
    }
    areaField.focus();
    areaField.selectionStart = startPos + text.length;
    areaField.selectionEnd = startPos + text.length;
  } else {
    textarea += text;
    areaField.focus();
  }
  return textarea;
};

// 微信分享
common.shareClick = (url, params) => {
  const head = document.head;
  const script = document.createElement("script");
  script.src = "https://res.wx.qq.com/open/js/jweixin-1.6.0.js";
  head.appendChild(script);
  http
    .get("/jsJdk/config", { url: url })
    .then((res) => {
      if (res.result === 200 && res.data && wx) {
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: res.data.appId, // 必填，公众号的唯一标识
          timestamp: res.data.timestamp, // 必填，生成签名的时间戳
          nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
          signature: res.data.signature, // 必填，签名
          jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"], // 必填，需要使用的JS接口列表
        });

        let link = url;
        let param = params;
        wx.ready(() => {
          // 分享到朋友圈 QQ空间
          wx.updateTimelineShareData({
            title: param.title, // 分享标题
            link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: param.imgUrl, // 分享图标
            success: function () {
              // 用户确认分享后执行的回调函数
            },
            cancel: function () {
              // 用户取消分享后执行的回调函数
            },
          });
          // 分享给朋友 QQ
          wx.updateAppMessageShareData({
            title: param.title, // 分享标题
            desc: param.desc, // 分享描述
            link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: param.imgUrl, // 分享图标
            type: "", // 分享类型,music、video或link，不填默认为link
            dataUrl: "", // 如果type是music或video，则要提供数据链接，默认为空
            success: function () {
              // 用户确认分享后执行的回调函数
            },
            cancel: function () {
              // 用户取消分享后执行的回调函数
            },
          });
        });
      }
    })
    .catch((error) => {
      // alert('SIGN_WECHAT_JS_SDK is error')
      // commit(SHOW_ERROR_TOAST, error)
    });
};

// 测试
common.a = function () {
  console.log("a测试测试");
  return 888;
};

export default common;
