import http from "./axios";

// 登录
export const productView = ({ productId }) => {
  return http.get("/product/view", {
    productId: productId,
  });
};

// 查看活动主页视图
export const activityPageView = ({ activityPageId }) => {
  return http.get("/manager/activityPage/view", {
    activityPageId: activityPageId,
  });
};

// 查看商品图片
export const productSubView = ({ productId, type }) => {
  return http.get("/product/subView", {
    productId: productId,
    type: type,
  });
};

// 创建订单
export const orderCreate = ({ skuId, addressId }) => {
  return http.json_post("/order/create", {
    skuId: skuId,
    addressId: addressId,
  });
};

// 微信授权--静默授权
export const weChantAuth = ({ redirectUri, appId }) => {
  return http.get("/wechat/snsapiBase", {
    redirectUri: redirectUri,
    appId: appId,
  });
};

// 微信授权--弹框授权
export const weChantSnsapiUserInfo = ({ redirectUri }) => {
  return http.get("/wechat/snsapiUserInfo", {
    redirectUri: redirectUri,
  });
};

// 微信用户信息
export const userInfo = () => {
  return http.get("/user/info");
};

// 微信用户登录--静默登录
export const weChatLogin = ({ code, appId }) => {
  return http.json_post("/user/snsapiBase/wxLogin", {
    code: code,
    appId: appId,
  });
};

// 微信用户登录--弹框登录
export const snsapiUserInfoWxLogin = ({ code }) => {
  return http.json_post("/user/snsapiUserInfo/wxLogin", {
    code: code,
  });
};

// 检查用户是否登陆
export const userCheckLogin = () => {
  return http.get("/user/checkLogin");
};

// 用户行为埋点
export const usertTrace = ({ content }) => {
  return http.json_post("/user/trace", {
    content: content,
  });
};

// 余额提现
export const getMoney = ({ money }) => {
  return http.get("/account/redEnvelope/withdrawalAccountBalance", {
    money: money,
  });
};

export const regAgreement = () => {
  return http.get("/agreement/regAgreement");
};

export const phoneLogin = (data) => {
  return http.json_post("/user/phoneLogin", data);
};
