import http from "./axios";

// 获取课程显示视图(分组信息)----
export const courseGroupView = ({ courseId }) => {
  return http.get("/course/getCourseGroupView", {
    courseId: courseId,
  });
};

// 获取课程显示视图----
export const courseView = ({ groupId, pageNo, pageSize }) => {
  return http.get("/course/listCourseItemViewInGroup", {
    groupId: groupId,
    pageNo: pageNo,
    pageSize: pageSize,
  });
};

// 获取视频播放地址----
export const getPlay = ({ videoId }) => {
  return http.get("/manager/getVodVideoLocation", {
    videoId: videoId,
  });
};

// 获取打卡视频播放信息----
export const getPlayInfo = ({ courseItemId }) => {
  return http.get("/course/getPlayInfo", {
    courseItemId: courseItemId,
  });
};

// 视频开始
export const starVideo = ({ courseItemId, frontPos }) => {
  return http.json_post("/course/reportCourseItemStart", {
    courseItemId: courseItemId,
    frontPos: frontPos,
  });
};

// 视频完成
export const finishVideo = ({
  courseItemId,
  historyId,
  secondOffset,
  clockInCheck,
}) => {
  return http.json_post("/course/reportCourseItemFinish", {
    courseItemId: courseItemId,
    historyId: historyId,
    secondOffset: secondOffset,
    clockInCheck: clockInCheck,
  });
};

// 今日打卡完成后观看视频
export const notifyVideoWatched = ({ courseItemId }) => {
  return http.json_post("/course/notifyVideoWatched", {
    courseItemId: courseItemId,
  });
};

// 延长微信用户session时间
export const prolong = () => {
  return http.json_post("/user/prolong");
};

// 打卡真实播放时间
export const reportCourseWatchInfo = ({
  courseItemId,
  secondOffset,
  frontPos,
  action,
  uuid,
  clockInCheck,
}) => {
  return http.json_post("/course/reportCourseWatchInfo", {
    courseItemId: courseItemId,
    secondOffset: secondOffset,
    frontPos: frontPos,
    action: action,
    uuid: uuid,
    clockInCheck: clockInCheck,
  });
};
