<template>
  <div class="personalCenter">
    <van-dialog
      v-model="show"
      title="请输入提现金额"
      show-cancel-button
      @confirm="confirm"
    >
      <van-field v-model="number" type="number" label="金额" />
    </van-dialog>

    <van-dialog
      v-model="phoneShow"
      title="请输入手机号和验证码"
      @confirm="loginConfirm"
      :beforeClose="loginClose"
    >
      <van-field
        v-model="updateData.phone"
        center
        label="手机号"
        placeholder="请输入手机号"
      >
      </van-field>
      <van-field
        v-model="updateData.checkCode"
        center
        label="验证码"
        placeholder="请输入验证码"
      >
        <template #button>
          <van-button
            size="small"
            type="primary"
            @click="sendCode"
            v-if="!updateData.codeFlag"
            >发送验证码</van-button
          >
          <van-button
            size="small"
            type="warning"
            disabled
            v-if="updateData.codeFlag"
            >已发送({{ updateData.count }}S)</van-button
          >
        </template>
      </van-field>
      <van-field name="checkbox">
        <template #input>
          <van-checkbox
            v-model="updateData.checkbox"
            shape="square"
            style="margin: auto"
          >
            请阅读并同意
            <span style="color: #1890ff" @click.stop="getAgreement">
              《用户协议》
            </span>
          </van-checkbox>
        </template>
      </van-field>
    </van-dialog>

    <van-dialog
      v-model="agreementShow"
      title="用户协议"
      show-cancel-button
      :show-confirm-button="false"
      cancel-button-text="关闭"
    >
      <div
        v-html="agreement"
        style="
          height: 70vh;
          overflow-y: scroll;
          padding: 0 10px;
          font-size: 14px;
        "
      ></div>
    </van-dialog>

    <div class="head-cc">
      <div class="title-cc">
        <div class="name-cc">{{ userName }}</div>
        <div class="id-cc">用户ID：{{ userId }}</div>
        <div class="id-cc">
          余额：¥ {{ balance
          }}<van-tag
            style="margin-left: 1rem"
            plain
            type="warning"
            @click="getMoney"
            >提现</van-tag
          >
        </div>
      </div>
      <div class="img-cc">
        <img :src="headImageUrl" alt="" />
      </div>
      <div class="btn-cc" @click="weChantSnsapiUserInfo" v-if="empowerBtnShow">
        点击授权
      </div>
    </div>
    <div class="content-list-cc">
      <van-cell title="我的订单" icon="shop-o" is-link @click="orderClick" />
      <van-cell
        title="我的地址"
        icon="location-o"
        is-link
        @click="addressClick"
      />
      <van-cell title="我的打卡课程" icon="coupon-o" is-link @click="clockIn" />
      <van-cell title="我的红包" icon="bill-o" is-link @click="goRed" />
      <van-cell title="提现记录" icon="balance-o" is-link @click="withDraw" />
      <van-cell title="个人信息" icon="user-o" is-link @click="infoClick" />
    </div>
  </div>
</template>
<script>
import * as api from "../lib/homePage.js";
import { Toast } from "vant";
import { getCode } from "../lib/info.js";

export default {
  name: "personalCenter",
  props: ["v_WeiXin"],
  data() {
    return {
      headImageUrl: "",
      userName: "",
      userId: "",
      code: "",
      balance: "",
      empowerBtnShow: false,
      show: false,
      number: "",
      phone: "",
      phoneShow: false,
      updateData: {
        phone: "",
        checkCode: "",
        checkbox: false,
        codeFlag: false,
        count: 60,
      },
      timer: null,
      agreementShow: false,
      agreement: "",
    };
  },
  mounted() {
    document.title = "个人中心";
    this.code = this.$route.query.code;
    let snsapiBase = this.$route.query.snsapiBase;
    if (this.v_WeiXin) {
      if (this.code) {
        if (snsapiBase == "true") {
          this.weChatLogin();
        } else {
          this.snsapiUserInfoWxLogin();
        }
      } else {
        this.weChantAuth();
      }
    }
  },
  methods: {
    getAgreement() {
      api.regAgreement().then((res) => {
        if (res.result === 200) {
          this.agreementShow = true;
          this.agreement = res.data.preview;
        }
      });
    },
    loginConfirm() {
      if (!this.updateData.checkbox) {
        Toast("请勾选用户协议");
        return;
      } else if (!this.updateData.phone || !this.updateData.phone) {
        Toast("请填写手机号和验证码");
        return;
      }
      api
        .phoneLogin({
          phone: this.updateData.phone,
          checkCode: this.updateData.checkCode,
        })
        .then((res) => {
          if (res.result === 200) {
            this.phoneShow = false;
            this.getUserInfo();
            Toast("登录成功");
          }
        });
    },
    loginClose(action, done) {
      done(false);
    },
    // 发送验证码
    sendCode() {
      if (this.updateData.phone == "") {
        Toast("请输入手机号");
      } else {
        getCode({ phone: this.updateData.phone }).then((res) => {
          if (res.result === 200) {
            this.updateData.codeFlag = true;
            Toast("发送成功");
            // 验证码发送成功之后处理
            this.timer = setInterval(() => {
              if (this.updateData.count == 0) {
                clearInterval(this.timer);
                this.updateData.codeFlag = false;
                this.updateData.count = 60;
              }
              this.updateData.count -= 1;
            }, 1000);
          }
        });
      }
    },
    // 点击授权
    weChantSnsapiUserInfo() {
      let url = window.location.href;
      url = url.split("?")[0];
      let data = {
        redirectUri: url,
      };
      api.weChantSnsapiUserInfo(data).then((res) => {
        if (res.result === 200) {
          window.location.href = res.data;
        }
      });
    },
    // 微信授权
    weChantAuth() {
      let appId1 = location.href.split("?");
      let newAppId = "";
      for (var i = 0; i < appId1.length; i++) {
        if (appId1[i].indexOf("appid") != -1) {
          newAppId = appId1[i].split("=")[1];
        }
      }
      let data = {
        redirectUri: window.location.href,
        appId: newAppId,
      };
      api.weChantAuth(data).then((res) => {
        if (res.result === 200) {
          window.location.href = res.data;
        }
      });
    },
    // 微信用户--静默登陆
    weChatLogin() {
      let appId1 = location.href.split("&");
      let newAppId = "";
      for (var i = 0; i < appId1.length; i++) {
        if (appId1[i].indexOf("appid") != -1) {
          newAppId = appId1[i].split("=")[1];
        }
      }
      let data = {
        code: this.code,
        appId: newAppId,
      };
      api.weChatLogin(data).then((res) => {
        if (res.result === 200) {
          this.getUserInfo();
        }
      });
    },
    // 微信用户--弹框登陆
    snsapiUserInfoWxLogin() {
      api.snsapiUserInfoWxLogin({ code: this.code }).then((res) => {
        if (res.result === 200) {
          this.getUserInfo();
        }
      });
    },
    // 查询用户信息
    getUserInfo() {
      api
        .userInfo()
        .then((res) => {
          if (res.result === 200) {
            this.userId = res.data.id;
            this.balance = res.data.balance / 100;
            this.empowerBtnShow = false;
            this.phone = res.data.phone;
            if (!this.userId) {
              this.empowerBtnShow = true;
            }
            if (!this.phone) {
              this.phoneShow = true;
            }
          } else {
            this.phoneShow = true;
          }
        })
        .catch(() => {
          this.phoneShow = true;
        });
    },
    // 点击我的订单
    orderClick() {
      this.$router.push({ path: "/orderList" });
    },
    // 点击我的地址
    addressClick() {
      this.$router.push({
        path: "/addressList",
        query: {
          modify: true,
        },
      });
    },
    // 点击我的打卡课程
    clockIn() {
      this.$router.push({ path: "/myClockIn" });
    },
    // 点击我的红包
    goRed() {
      this.$router.push({ path: "/myRed" });
    },
    // 点击提现记录
    withDraw() {
      this.$router.push({ path: "/withDraw" });
    },
    // 点击个人信息
    infoClick() {
      this.$router.push({
        path: "/info",
        query: {
          userName: this.userName,
          phone: this.phone,
        },
      });
    },
    // 提现
    getMoney() {
      this.show = true;
      this.number = "";
    },
    // 确定提现
    confirm() {
      if (this.number < 0 || this.number == "") {
        Toast("请输入提现金额");
      } else {
        let num = this.number * 100;
        api.getMoney({ money: num }).then((res) => {
          if (res.result === 200) {
            this.getUserInfo();
            Toast.success("提交成功");
          }
        });
      }
    },
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  },
};
</script>
<style lang="scss" scoped>
.personalCenter {
  text-align: left;
  background: #ebedf0;
  .head-cc {
    position: relative;
    min-height: 8.5rem;
    display: flex;
    justify-content: space-between;
    text-align: left;
    padding: 0 1rem;
    padding-top: 1.5rem;
    background: linear-gradient(to right, #e57558, #e58738);
    box-shadow: 0 -0.3rem 0.9rem 0.3rem #ebedf0;
    .title-cc {
      font-size: 1.3rem;
      color: #fff;
      padding-top: 1rem;
      .name-cc {
        font-weight: 500;
      }
      .id-cc {
        font-size: 0.9rem;
      }
    }
    .img-cc {
      background: #ebedf0;
      border-radius: 50%;
      width: 5rem;
      height: 5rem;
      img {
        width: 100%;
        border-radius: 50%;
      }
    }
    .btn-cc {
      position: absolute;
      bottom: 20px;
      left: 50%;
      margin-left: -50%;
      background: linear-gradient(to right, #ff976a, #e78e4b);
      box-shadow: 0 -0.3rem 0.9rem 0.3rem #e67250;
      color: #fff;
      height: 2rem;
      font-size: 0.9rem;
      line-height: 2rem;
      width: 6rem;
      text-align: center;
    }
  }
  .content-list-cc {
    margin: 0.8rem 0;
  }
}
</style>
